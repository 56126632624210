<template>
  <div>
    <div class="bg-secondary py-4">
      <div class="flex justify-center gap-3">
        <svg
          @click="newWindow('https://www.youtube.com/c/JuditAntalsChannel')"
          version="1.1"
          class="fill-current text-primary w-12 cursor-pointer"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="max-width: 120px"
          xml:space="preserve"
        >
          <g id="XMLID_1_">
            <polygon
              id="XMLID_3_"
              class="st0"
              points="147.1,277.4 164.8,277.4 164.8,369.6 183.4,369.6 183.4,277.4 202,277.4 202,261.6 
		147.1,261.6 	"
            />
            <path
              id="XMLID_4_"
              class="st0"
              d="M256.9,201.1c2.8,0,4.7-0.9,5.6-1.9c1.9-0.9,1.9-2.8,1.9-5.6v-47.5c0-1.9-0.9-3.7-1.9-4.7
		c-1.9-0.9-3.7-1.9-5.6-1.9c-1.9,0-3.7,0.9-5.6,1.9c-0.9,0.9-1.9,2.8-1.9,4.7v47.5c0,2.8,0.9,4.7,1.9,5.6
		C252.3,200.1,254.1,201.1,256.9,201.1z"
            />
            <path
              id="XMLID_7_"
              class="st0"
              d="M296,289.5c-2.8,0-4.7,0.9-7.4,1.9s-4.7,2.8-6.5,5.6v-34.4h-15.8v108h15.8V364
		c1.9,2.8,4.7,4.7,6.5,5.6c2.8,0.9,5.6,1.9,8.4,1.9c4.7,0,8.4-1.9,11.2-4.7c2.8-2.8,3.7-7.4,3.7-13V310c0-6.5-0.9-11.2-3.7-14.9
		C305.3,290.4,301.6,289.5,296,289.5z M295.1,351c0,2.8-0.9,4.7-0.9,5.6c-0.9,0.9-2.8,1.9-4.7,1.9c-0.9,0-2.8,0-3.7-0.9
		c-0.9-0.9-2.8-1.9-3.7-2.8v-49.3c0.9-0.9,1.9-1.9,3.7-2.8c0.9-0.9,1.9-0.9,3.7-0.9c1.9,0,3.7,0.9,4.7,1.9c0.9,1.9,1.9,3.7,1.9,6.5
		v41H295.1z"
            />
            <path
              id="XMLID_8_"
              class="st0"
              d="M238.3,351c-1.9,1.9-2.8,2.8-4.7,4.7c-1.9,0.9-3.7,1.9-4.7,1.9c-1.9,0-2.8,0-3.7-0.9
		c-0.9-0.9-0.9-1.9-0.9-3.7v-61.4h-15.8v66.1c0,4.7,0.9,8.4,2.8,10.2c1.9,2.8,4.7,3.7,8.4,3.7c2.8,0,6.5-0.9,9.3-2.8
		c2.8-1.9,6.5-3.7,9.3-7.4v8.4h15.8v-79.1h-15.8V351L238.3,351z"
            />
            <path
              id="XMLID_14_"
              class="st0"
              d="M256,0C114.5,0,0,114.5,0,256s114.5,256,256,256s256-114.5,256-256S397.5,0,256,0z M297,126.6
		h17.7v67c0,1.9,0,3.7,0.9,4.7c0.9,0.9,1.9,1.9,3.7,1.9c0.9,0,2.8-0.9,4.7-1.9c1.9-0.9,3.7-2.8,5.6-4.7v-66.1h17.7V215h-16.8v-9.3
		c-3.7,3.7-6.5,6.5-10.2,8.4c-3.7,1.9-7.4,2.8-10.2,2.8c-3.7,0-7.4-0.9-9.3-3.7c-1.9-2.8-2.8-6.5-2.8-12.1v-74.5H297z M230.9,146.2
		c0-6.5,2.8-12.1,7.4-15.8c4.7-3.7,11.2-5.6,19.5-5.6c7.4,0,13,1.9,18.6,6.5c4.7,4.7,7.4,9.3,7.4,16.8v45.6c0,7.4-1.9,13-6.5,17.7
		c-4.7,4.7-11.2,6.5-19.5,6.5c-7.4,0-14-1.9-18.6-6.5c-4.7-4.7-7.4-10.2-7.4-17.7L230.9,146.2L230.9,146.2z M182.5,95l13,47.5h0.9
		L208.5,95H229l-23.3,69.8v49.3h-20.5v-47.5L161,95H182.5z M416.1,345.4c0,31.7-26.1,57.7-57.7,57.7H160.1
		c-31.7,0-57.7-26.1-57.7-57.7v-46.5c0-31.7,26.1-57.7,57.7-57.7h199.2c31.7,0,57.7,26.1,57.7,57.7v46.5H416.1z"
            />
            <path
              id="XMLID_17_"
              class="st0"
              d="M344.4,288.6c-7.4,0-13,1.9-17.7,6.5c-4.7,4.7-6.5,10.2-6.5,16.8v35.4c0,7.4,1.9,14,6.5,17.7
		c3.7,4.7,9.3,6.5,16.8,6.5c8.4,0,14-1.9,17.7-6.5c3.7-3.7,5.6-10.2,5.6-18.6v-3.7H350v3.7c0,4.7-0.9,7.4-1.9,9.3
		c-0.9,0.9-2.8,1.9-5.6,1.9c-2.8,0-4.7-0.9-5.6-2.8c-0.9-1.9-1.9-4.7-1.9-8.4v-14.9h30.7v-19.5c0-7.4-1.9-13-5.6-17.7
		C357.5,290.4,351.9,288.6,344.4,288.6z M350,319.3h-14v-7.4c0-3.7,0.9-5.6,1.9-7.4c0.9-1.9,2.8-1.9,5.6-1.9c2.8,0,4.7,0.9,5.6,1.9
		c0.9,0.9,1.9,3.7,1.9,7.4L350,319.3L350,319.3z"
            />
          </g>
        </svg>
        <svg
          @click="newWindow('https://www.facebook.com/AntalJudit11')"
          version="1.1"
          id="Réteg_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          style="max-width: 120px"
          class="fill-current text-primary w-12 cursor-pointer"
          xml:space="preserve"
        >
          <path
            d="M12,0.1c-6.6,0-12,5.4-12,12c0,6,4.4,11,10.2,11.9v-8.7h-3v-3.2h3V10c0-3.5,1.7-5,4.6-5c1.4,0,2.1,0.1,2.5,0.1v2.8h-2
	c-1.2,0-1.7,1.2-1.7,2.5v1.7h3.6l-0.5,3.2h-3.1v8.7C19.5,23.1,24,18.1,24,12.1C24,5.4,18.6,0.1,12,0.1z"
          />
        </svg>
      </div>
      <div class="text-primary font-bold text-sm mt-2">
        judit.rightquestion@gmail.com
      </div>
    </div>
    <div class="bg-primary py-2 text-sm text-white">
      created by <a href="https://serumcode.com" class="font-bold">serumcode</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import CardCorner from "../components/CardCorner.vue";
//import Icons from "../components/Icons.vue";
export default {
  name: "FooterComponent",
  methods: {
    newWindow(url) {
      window.open(url, "_blank");
    },
  },
  //components: { CardCorner, Icons },
};
</script>
