<template>
  <div
    class="relative pt-10 overflow-hidden pb-10 h-full flex-grow -mt-10"
    style="background: #543687"
  >
    <div
      class="absolute w-full h-full overflow-hidden top-0"
      style="background-size: cover"
      :style="{
        backgroundImage: `url(${require(`@/assets/111.svg`)})`,
      }"
    >
      <svg
        class="w-full h-full"
        preserveAspectRatio="none"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 634.9 364.5"
        style="enable-background: new 0 0 634.9 364.5"
        xml:space="preserve"
      >
        <path
          fill="#391a6f"
          class="st0"
          d="M634.9,136.9c0-0.5,0-0.9,0-1.4c-3-0.7-5.7-2.2-8.6-3.4c-10.7-4.4-21.2-9.4-31.4-14.7
	c-8.2-4.2-16.3-8.5-24-13.7c-6-4-11.7-8.4-17.2-13c-4.6-3.8-9-7.8-13.4-11.9c-5.8-5.5-11.3-11.2-16.7-17.2
	c-3.3-3.6-6.6-7.2-9.9-10.8c-3.5-3.9-6.8-8-10.2-12c-4.6-5.4-9.2-10.9-13.8-16.4c-3.4-4.1-6.9-8.2-10.4-12.2
	c-2.7-3.1-5.5-6.1-8.2-9.2c-0.3-0.3-0.6-0.6-0.6-1c-0.2,0-0.3,0-0.5,0C412,0,354,0,295.9,0c-0.9,0-1.4,0.4-1.9,1
	c-2.8,4.4-5.7,8.8-8.1,13.4c-6,11.3-10.7,23.1-13.6,35.6c-1.8,7.6-2.9,15.3-3.5,23.1c-0.6,9.3-0.2,18.5,0.8,27.7
	c0.9,7.6,2.3,15.1,4.2,22.4c3.8,14.5,9.3,28.5,16.2,41.8c6,11.7,12.9,22.9,20.5,33.6c5.2,7.3,10.7,14.3,16.4,21.1
	c4.6,5.5,9.3,10.8,14.2,16c8.4,8.7,17,17.1,26.1,25.1c7.6,6.6,15.3,13,23.3,19.1c9.5,7.2,19.3,14,29.3,20.5
	c10,6.4,20.2,12.5,30.7,18.2c8.9,4.8,17.9,9.3,27.1,13.5c9.6,4.4,19.3,8.4,29.1,12c12.4,4.6,25,8.6,37.8,11.8
	c9.8,2.5,19.7,4.7,29.8,6.1c5.7,0.8,11.5,1.4,17.3,1.8c2.5,0.2,5.1,0,7.6,0.6c11.9,0,23.8,0,35.7,0c0-0.5,0-1,0-1.5
	C634.9,287.6,634.9,212.2,634.9,136.9z"
        />
        <path
          fill="#2c1456"
          class="st1"
          d="M468.4,0.1c0,0,55.3,100.9,166.5,136.9l0-136.9L468.4,0.1z"
        />
      </svg>
    </div>
    <div
      class="text-secondary text-center uppercase font-bold text-xl custom-font my-10 titleclass relative"
    >
      Gyakran ismételt kérdések
    </div>
    <div
      class="relative container m-auto rounded-lg px-3 lg:px-5"
      style="max-width: 900px"
    >
      <div v-for="(item, i) in items" :key="i" class="mb-3">
        <div
          :class="
            item.collapse
              ? 'bg-primary text-white'
              : 'bg-secondary text-primary'
          "
          class="font-bold flex justify-between px-4 py-2 rounded-lg items-center text-sm lg:text-base"
        >
          <div class="" style="max-width: calc(100% - 38px)">
            {{ item.question }}
          </div>
          <div
            :class="
              item.collapse
                ? 'bg-primary-darker text-white'
                : ' bg-primary text-white'
            "
            @click="item.collapse = !item.collapse"
            class="rounded-full w-8 h-8 flex items-center justify-center font-bold cursor-pointer"
          >
            <span v-if="item.collapse">+</span>
            <span v-else>-</span>
          </div>
        </div>
        <div
          v-if="!item.collapse"
          class="bg-primary-darkest overflow-hidden relative text-secondary container m-auto rounded-lg mt-3 md:px-3 md:py-4"
          style="max-width: 900px"
        >
          <div
            :style="{
              backgroundImage: `url(${require(`@/assets/triange_.svg`)})`,
            }"
            style="
              background-size: 100%;
              opacity: 0.05;
              width: calc(100% - 20px);
              left: 10px;
              height: calc(100% - 20px);
              top: 10px;
            "
            class="absolute"
          ></div>

          <div
            v-html="item.answer"
            class="p-2 relative h-full py-4 lg:py-5 px-3 lg:px-6 text-justify text-white opacity-90 text-sm answer"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import CardCorner from "../components/CardCorner.vue";

//import Icons from "../components/Icons.vue";
export default {
  name: "ContactView",
  components: {},
  data() {
    return {
      items: [
        {
          collapse: true,
          question: "Kiknek szól, kiknek nem szól? ",
          answer: `FIGYELEM: A Taroszkóp előrejelzések ugyanúgy, ahogy a csatornámon található összes többi videó, valamint a facebook oldal videói és írásai NEM SZÓLNAK MINDENKINEK. <br>

1. NEM SZÓLNAK a videók azoknak, akik a közepétől és/vagy felületesen nézik, és a videók alatti leírást nem képesek elolvasni.<br>

2. NEM SZÓL azoknak, akik azt képzelik, hogy a Taroszkóp, a napi és heti kártyák stb nekik alanyi jogon járnak! NEM SZÓL azoknak, akik azt képzelik, hogy ez, hogy én ezeket rendszeresen ingyen megcsinálom, az a világ legtermészetesebb dolga, és hogy nekem kötelességem ezt őnekik megcsinálni!<br>

3. NEM SZÓL azoknak, akik hajlamosak másokat hibáztatni a saját életük problémáiért, bajaiért!<br>

4. NEM SZÓL azoknak, akik önmagában egy videótól vagy egy facebook poszttól (főleg annak felületes nézésétől/olvasásától) várják a megváltást és a megoldást bármely problémájukra.<br>

5. NEM SZÓL azoknak, akik nem képesek megérteni, hogy a taroszkóp és a napi és heti kártya NEM JÓSLAT, és nincs semmi garancia arra, hogy a jó dolgok be is teljesednek, ha te magad nem teszel meg mindent magadért, és nem segítesz magadon.<br>

6. NEM SZÓL azoknak, akik az én posztjaim / videóim hatására (vagy azokra ráfogva!!!) felrúgják a házasságukat, felmondanak a munkahelyükön, abbahagyják a gyógyszerük szedését, komolyabb változtatásokat eszközölnek az életmódjukban anélkül, hogy orvossal egyeztetnének stb, stb, stb, stb, aztán rám fogják, ha valami rosszul sül el és engem hibáztatnak a bajaikért, problémáikért!!!<br>

7. NEM SZÓL azoknak, akiknek a facebook és a youtube használatáról a legalapvetőbb ismereteik sincsenek meg és ha nem találnak valamit, vagy bármi gondjuk adódik emiatt, engem hibáztatnak - sajnos az internethasználat megtanítása nem áll módomban, nem fér bele az időmbe és az energiámba, és nem is kötelességem az efféle hiányosságokat pótolni!<br>

8. NEM SZÓL azoknak, akik viccesnek találják; NEM SZÓL azoknak, akik nem képesek tisztelettel beszélni másokkal és nem képesek megérteni és alkalmazni a legalapvetőbb emberi és erkölcsi szabályokat és/vagy a trollok módszereit és kommunikációs eszközeit alkalmazzák.<br>

9. NEM SZÓL AZOKNAK, akik nem tudják rólam feltételezni, hogy értelmes felnőtt ember vagyok, vigyázok magamra, illetve hogy a tanításaimat magamra is alkalmazom.<br>

10. NEM SZÓL azoknak, akik kritizálni és a kákán is csomót keresni jönnek ide. Nem szól azoknak, akik engem kéretlenül kioktatni jönnek ide illetve akik a taníthatnékjukat és az észoszthatnékjukat rajtam és az oldal követőin akarják kiélni. NE GYERE IDE KÉRETLEN TANÁCSOKAT OSZTOGATNI!<br>

11. NEM SZÓL azoknak, akik az én oldalaimra járkálnak ügyfeleket szerezni! Ne abból próbálj megélni, amit más kemény munkával letett az asztalra!!!<br>

***

EZEKBŐL LOGIKUSAN KÖVETKEZIK, hogy csakis egy bizonyos használható IQ és EQ szint felettieknek szól; csakis olyanoknak, akik ép elmeállapotban vannak, és képesek értelmes módon felelősséget vállalni saját magukért és a tetteikért!!!<br>

EZEN BELÜL IS csak egy bizonyos embercsoportnak szól: azon csoportnak, akik érzik és tudják, hogy rezonál, nekik szól.

A TÖBBIEKET - akiknek nem tetszik és/vagy úgy érzik, nem rezonál, vagy akik oktatni jönnének ide - megkérem, ne járkáljanak ide az oldalra és a csatornámra, MERT EGYIK SEM NEKIK SZÓL. Tiszteljük egymás idejét és ne tartsuk fel egymást feleslegesen!<br>

TOVÁBBI FONTOS TUDNIVALÓK / FIGYELMEZTETÉSEK:

A Taroszkóp - ahogy fentebb is említettem - NEM JÓSLAT. LEHETŐSÉGEKET mutat, nem megváltoztathatatlan jövőt mondok! Olyan, hogy "megváltoztathatatlan jövő" nem létezik. Minden (majdnem minden) a választásaidtól és a döntéseidtől függ.<br>

Emlékezz rá: szabad akaratod van és a te döntéseid a te felelősséged. Én nem vállalhatok és nem is vállalok felelősséget a te döntéseidért.<br>

Az elemzések felnőtt embereknek szólnak, és a csatornám (valamint a facebook oldalam és minden munkám) minden követőjét felnőtt embernek tekintem, aki a saját tetteiért felelős és akinek szabad akarata van.<br>

A Taroszkóp videók (és minden egyéb videó a csatornámon, plusz minden facebook poszt az Antal Judit spirituális tanító oldalon) beszámítható, ép elmeállapotú, felnőtt és felelősségteljes embereknek szól. Az elemzésekben elhangzó dolgok semmiképpen sem tekintendők szentírásnak, és soha ne feledd: szabad akaratod van, és a saját döntésed és annak következményei mindig, minden körülmények között kizárólag a te felelősséged.<br>

A videók nézésével, hallgatásával / a facebook posztok olvasásával elismered és elfogadod, hogy ezek bármely részének alkalmazásából és/vagy félreértelmezéséből adódó problémákért semmilyen mértékben nem tekintem felelősnek magam illetve te sem tekintesz engem felelősnek életed bármely problémájáért.`,
        },
        {
          collapse: true,
          question: "Judit, lehet veled személyesen találkozni?",
          answer: `SOKAN A SZEMÉLYES TALÁLKOZÁST PREFERÁLNÁK. Megértem. Annak idején, sok éve nekem is nagy élmény volt, amikor személyesen találkoztam egy jósnővel és ott a szemem előtt rakta ki nekem a kártyákat...<br>

Azt is tudjátok már sokan - aki még nem, annak most mondom - hogy rengetegen keresnek, "kilométer hosszú" a sor nálam mindig. Ezen kívül tanítványaim is vannak, úgyhogy 100%-ig be vagyok táblázva.<br>

A másik aspektusa a dolognak az, hogy amit csinálok, az igen nagy mértékben hatással van sok ember életére, sőt nem túlzás azt mondani, hogy időnként életeket mentek.<br>

Éppen ezért nem vehetem könnyedén ezt a dolgot. Pláne így, hogy nekem feladatom a PÉLDAMUTATÁS is. Főként jós, gyógyító, tanító, spirituális segítő kollégák felé kell példát mutatnom abban, hogy becsüljék nagyra magukat; de nemcsak feléjük, hanem végsősoron mindenki felé, aki rám talál.<br>

Úgyhogy ebben a szellemben cselekszem. Tehát: LEHET VELEM TALÁLKOZNI, DE: ez kizárólag azoknak szól, akik komolyan gondolják, hogy válaszokat akarnak kapni és hajlandók az igazsággal szembenézni, és ha kell, változtatni az életükön. Csak azoknak szól, akik maguk is becsülik saját magukat és engem is tisztelettel közelítenek meg, és HAJLANDÓK ÖNMAGUKBA FEKTETNI - ezt a szolgáltatás ára is tükrözi.<br>

Tehát, ha szeretnél velem egy személyes találkozót, a következő a teendőd:<br>

1. Először is dobj egy emailt a <span class="font-bold">judit.rightquestion@gmail.com</span> címre és kérdezz rá, hogy van-e egyáltalán elérhető időpontom a következő hetekben<br>

2. Ha a válasz igen, és rögzítettünk egy időpontot, akkor küldd el a találkozó díját - <b>£188</b> - a következő linken: <a href="http://PayPal.Me/JuditAntal">PayPal.Me/JuditAntal</a><br>

(Ha PayPal fiók létrehozásához segítségre van szükséged, ide kattints: <a href="http://online-fizetes-magyarul.info/paypal/">http://online-fizetes-magyarul.info/paypal/</a> )`,
        },
      ],
    };
  },
  methods: {},
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
<style>
.answer br {
  content: "";
  margin: 4em;
  display: block;
  font-size: 24%;
}
</style>
