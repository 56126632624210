<template>
  <div
    class="relative pt-10 overflow-hidden pb-10 h-full flex-grow -mt-10"
    style="background: #543687"
  >
    <div
      class="absolute w-full h-full overflow-hidden top-0"
      style="background-size: cover"
      :style="{
        backgroundImage: `url(${require(`@/assets/111.svg`)})`,
      }"
    >
      <svg
        class="w-full h-full"
        preserveAspectRatio="none"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 634.9 364.5"
        style="enable-background: new 0 0 634.9 364.5"
        xml:space="preserve"
      >
        <path
          fill="#391a6f"
          class="st0"
          d="M634.9,136.9c0-0.5,0-0.9,0-1.4c-3-0.7-5.7-2.2-8.6-3.4c-10.7-4.4-21.2-9.4-31.4-14.7
	c-8.2-4.2-16.3-8.5-24-13.7c-6-4-11.7-8.4-17.2-13c-4.6-3.8-9-7.8-13.4-11.9c-5.8-5.5-11.3-11.2-16.7-17.2
	c-3.3-3.6-6.6-7.2-9.9-10.8c-3.5-3.9-6.8-8-10.2-12c-4.6-5.4-9.2-10.9-13.8-16.4c-3.4-4.1-6.9-8.2-10.4-12.2
	c-2.7-3.1-5.5-6.1-8.2-9.2c-0.3-0.3-0.6-0.6-0.6-1c-0.2,0-0.3,0-0.5,0C412,0,354,0,295.9,0c-0.9,0-1.4,0.4-1.9,1
	c-2.8,4.4-5.7,8.8-8.1,13.4c-6,11.3-10.7,23.1-13.6,35.6c-1.8,7.6-2.9,15.3-3.5,23.1c-0.6,9.3-0.2,18.5,0.8,27.7
	c0.9,7.6,2.3,15.1,4.2,22.4c3.8,14.5,9.3,28.5,16.2,41.8c6,11.7,12.9,22.9,20.5,33.6c5.2,7.3,10.7,14.3,16.4,21.1
	c4.6,5.5,9.3,10.8,14.2,16c8.4,8.7,17,17.1,26.1,25.1c7.6,6.6,15.3,13,23.3,19.1c9.5,7.2,19.3,14,29.3,20.5
	c10,6.4,20.2,12.5,30.7,18.2c8.9,4.8,17.9,9.3,27.1,13.5c9.6,4.4,19.3,8.4,29.1,12c12.4,4.6,25,8.6,37.8,11.8
	c9.8,2.5,19.7,4.7,29.8,6.1c5.7,0.8,11.5,1.4,17.3,1.8c2.5,0.2,5.1,0,7.6,0.6c11.9,0,23.8,0,35.7,0c0-0.5,0-1,0-1.5
	C634.9,287.6,634.9,212.2,634.9,136.9z"
        />
        <path
          fill="#2c1456"
          class="st1"
          d="M468.4,0.1c0,0,55.3,100.9,166.5,136.9l0-136.9L468.4,0.1z"
        />
      </svg>
    </div>
    <div
      class="text-secondary text-center uppercase font-bold lg:text-xl custom-font my-10 titleclass relative"
    >
      Feltételek
    </div>
    <div
      class="relative container m-auto rounded-lg lg:px-5 px-0 bg-primary"
      style="max-width: 900px"
    >
      <div class="text-white py-2 lg:py-5 px-3 lg:px-8 text-sm lg:text-base">
        <p>
          Bármilyen spirituális szolgáltatást (kártyavetés, energiamunka)
          rendelsz tőlem, az alábbiak állnak; továbbá az
          <a
            class="text-secondary"
            href="https://www.facebook.com/AntalJudit11/"
            >Antal Judit spirituális tanító facebook oldalon</a
          >, a
          <a
            class="text-secondary"
            href="https://www.facebook.com/HelyesKerdes/"
            >Judit Antal facebook oldalon</a
          >
          és az
          <a class="text-secondary" href="www.antaljudit.com">antaljudit.com</a>
          weboldalon olvasottakra; a
          <a
            class="text-secondary"
            href="https://www.youtube.com/c/JuditAntalsChannel/"
            >https://www.youtube.com/c/JuditAntalsChannel/</a
          >
          csatornán látható / hallható információkra, a személyre szabott
          elemzésekre és minden egyéb szolgáltatásra egyaránt vonatkozik:
        </p>

        <p>
          Szabad akaratod van és a te döntéseid a te felelősséged. Én nem
          vállalhatok és nem is vállalok felelősséget a te döntéseidért.
        </p>

        <p>
          Az elemzések felnőtt embereknek szólnak, és minden ügyfelemet felnőtt
          embernek tekintem, aki a saját tetteiért felelős és akinek szabad
          akarata van.
        </p>
        <p>
          A megrendeléssel elismered, hogy beszámítható és felelősségteljes
          ember vagy. Az elemzésekben, a felvételeken és egyéb szolgáltatások
          alkalmával elhangzó dolgok semmiképpen sem tekintendők szentírásnak,
          és soha ne feledd: szabad akaratod van, és a saját döntésed és annak
          következményei mindig, minden körülmények között kizárólag a te
          felelősséged.
        </p>

        <p>
          A szolgáltatások megrendelésével (és az ingyenes tartalom olvasásával
          / megtekintésével IS) elismered és elfogadod, hogy az elemzések
          bármely részének alkalmazásából és/vagy félreértelmezéséből adódó
          problémákért semmilyen mértékben nem tekintem felelősnek magam illetve
          te sem tekintesz engem felelősnek életed bármely problémájáért.
        </p>

        <p>
          Ami pedig a törvényességet illeti: az Egyesült Királyság törvényei
          szerint minden jóslást, kártyavetést kizárólag csak szórakoztatásnak
          szabad tekinteni. Így nekem is kötelességem tudatni veled, hogy egy
          dolog az én őszinte hitem és hozzáállásom, más dolog a törvény, ez
          utóbbit nekem is tisztelnem kell, tehát az elemzés, amit kaptál, "csak
          szórakoztatás" és ez vonatkozik a fentiek értelmében minden egyéb
          szolgáltatásomra is. Ezenkívül semmilyen módon nem tekinthető
          szakértői tanácsnak, és semmilyen szakértői tanácsot (pszichológus,
          orvos, pénzügyi tanácsadó, vagy bármi egyéb, a problémádhoz kapcsolódó
          területen tevékenykedő szakember) nem helyettesít.
        </p>

        <p>
          Bármely szolgáltatás megrendelésével igazolod, hogy elmúltál 18 éves
          és egyetértesz a Feltételekkel.
        </p>

        <p>
          VÁRAKOZÁSI IDŐ: Jelentkezéskor és bármelyik szolgáltatásom
          megrendelésekor légy tudatában, hogy forgalmasabb időszakokban a
          várakozási idő akár három hét is lehet!
        </p>

        <p>
          PÉNZVISSZAFIZETÉST NEM VÁLLALOK SEMMILYEN KÖRÜLMÉNYEK KÖZÖTT. A munkát
          minden alkalommal elvégzem, a szívemet, a lelkemet, az időmet és az
          energiámat beletéve, és amit a szolgáltatások leírásában plusz itt a
          Feltételekben olvasol, azt minden alkalommal garantáltan megkapod. Ha
          a munkát elvégzem, annak a díja is jár.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import CardCorner from "../components/CardCorner.vue";

//import Icons from "../components/Icons.vue";
export default {
  name: "TermsView",
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
<style>
.answer br {
  content: "";
  margin: 4em;
  display: block;
  font-size: 24%;
}
</style>
