<template>
  <div
    class="relative pt-10 overflow-hidden pb-10 h-full flex-grow -mt-10"
    style="background: #543687"
  >
    <div
      class="absolute w-full h-full overflow-hidden top-0"
      style="background-size: cover"
      :style="{
        backgroundImage: `url(${require(`@/assets/111.svg`)})`,
      }"
    >
      <svg
        class="w-full h-full"
        preserveAspectRatio="none"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 634.9 364.5"
        style="enable-background: new 0 0 634.9 364.5"
        xml:space="preserve"
      >
        <path
          fill="#391a6f"
          class="st0"
          d="M634.9,136.9c0-0.5,0-0.9,0-1.4c-3-0.7-5.7-2.2-8.6-3.4c-10.7-4.4-21.2-9.4-31.4-14.7
	c-8.2-4.2-16.3-8.5-24-13.7c-6-4-11.7-8.4-17.2-13c-4.6-3.8-9-7.8-13.4-11.9c-5.8-5.5-11.3-11.2-16.7-17.2
	c-3.3-3.6-6.6-7.2-9.9-10.8c-3.5-3.9-6.8-8-10.2-12c-4.6-5.4-9.2-10.9-13.8-16.4c-3.4-4.1-6.9-8.2-10.4-12.2
	c-2.7-3.1-5.5-6.1-8.2-9.2c-0.3-0.3-0.6-0.6-0.6-1c-0.2,0-0.3,0-0.5,0C412,0,354,0,295.9,0c-0.9,0-1.4,0.4-1.9,1
	c-2.8,4.4-5.7,8.8-8.1,13.4c-6,11.3-10.7,23.1-13.6,35.6c-1.8,7.6-2.9,15.3-3.5,23.1c-0.6,9.3-0.2,18.5,0.8,27.7
	c0.9,7.6,2.3,15.1,4.2,22.4c3.8,14.5,9.3,28.5,16.2,41.8c6,11.7,12.9,22.9,20.5,33.6c5.2,7.3,10.7,14.3,16.4,21.1
	c4.6,5.5,9.3,10.8,14.2,16c8.4,8.7,17,17.1,26.1,25.1c7.6,6.6,15.3,13,23.3,19.1c9.5,7.2,19.3,14,29.3,20.5
	c10,6.4,20.2,12.5,30.7,18.2c8.9,4.8,17.9,9.3,27.1,13.5c9.6,4.4,19.3,8.4,29.1,12c12.4,4.6,25,8.6,37.8,11.8
	c9.8,2.5,19.7,4.7,29.8,6.1c5.7,0.8,11.5,1.4,17.3,1.8c2.5,0.2,5.1,0,7.6,0.6c11.9,0,23.8,0,35.7,0c0-0.5,0-1,0-1.5
	C634.9,287.6,634.9,212.2,634.9,136.9z"
        />
        <path
          fill="#2c1456"
          class="st1"
          d="M468.4,0.1c0,0,55.3,100.9,166.5,136.9l0-136.9L468.4,0.1z"
        />
      </svg>
    </div>
    <div
      class="text-secondary text-center uppercase font-bold text-xl custom-font my-10 titleclass relative"
    >
      Kapcsolat
    </div>
    <div
      class="bg-primary-darkest overflow-hidden relative text-secondary container m-auto"
      style="max-width: 900px"
    >
      <div
        :style="{
          backgroundImage: `url(${require(`@/assets/triange_.svg`)})`,
        }"
        style="
          background-size: 100%;
          opacity: 0.05;
          width: calc(100% - 20px);
          left: 10px;
          height: calc(100% - 20px);
          top: 10px;
        "
        class="absolute"
      ></div>
      <card-corner borderColor="secondary" :showUp="true" />
      <div class="p-2 relative h-full flex flex-col p-10">
        <div class="flex justify-center">
          <svg
            style="max-width: 400px"
            class="fill-current text-secondary w-2/3"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  class="st0"
                  d="M435.5,509.2c-0.8,0-1.5,0-2.3-0.1c-16.6-1.9-20.5-12-26.3-27.3c-6.1-15.9-15.2-40-44.3-71.5
			c-21.6-23.4-50.5-43.3-61-44.8c-4.9-0.7-19.7-4-36.8-7.8c-22.7-5-50.8-11.3-56.4-11.5c-2.6-0.1-4.8-0.1-6.6-0.1
			c-5.2,0-8.7,0-15.1-1.6c-4-1-10.6-4.4-17.6-8c-6.5-3.3-16.3-8.3-19-8.3c0,0-0.1,0-0.1,0c-1.3,0.1-3.6,0.2-6.5,0.4
			c-14,0.9-28.1,1.8-33.3,1.2c-3.7-0.4-8.1-2.6-13.8-5.3c-6.2-3-14-6.8-23.3-9.5c-10.2-2.9-17.3-6.8-23.5-10.3
			c-4.6-2.5-8.6-4.7-12.8-5.9C26.1,296,10.7,283.3,10,282.7l-1.5-1.2l0.7-1.8c0.1-0.2,2.3-5.7,7.8-10.1c5.1-4.1,14.1-8.1,28-3.9
			c13.5,4.1,19.8,7.7,24,10.1c2.2,1.3,3.6,2.1,4.9,2.2c0.7,0.1,1.6,0.2,2.8,0.3c7.3,0.7,22.6,2.2,34.4,7.8c1.6,0.8,3.1,1.7,4.3,2.4
			c3.7-3.7,13.8-11.2,33.1-10.5c0.1-0.8,0.3-1.5,0.5-2.3c2.2-9.4,13.4-15.7,17.3-16.6c5.5-1.2,15-0.7,24.2-0.2
			c4.2,0.2,8.2,0.5,11.6,0.5c10,0.1,33.6,5.1,38,6c2.2-0.7,9.2-2.9,11.4-3.6c2.7-0.8,9.4-0.4,11.6-0.3l3.6-0.9
			c2.2-0.6,4.6-1,6.9-1.2c5.2-0.5,14.6-1.4,19.8-1.6c7.9-0.3,36,3.7,49.8,8.2c14.4,4.7,30.6,24.8,39.1,42.2
			c2.4,4.9,17.7,16.4,33.9,28.5c36.3,27.2,86,64.5,87.3,94.2c0.5,9.9-5.2,21.8-10,30c-6.4,10.9-15,21.6-24.3,30.3
			C461.8,498.2,448,509.2,435.5,509.2z M203.3,340.9c1.5,0,3.3,0,5.3,0.1c6.1,0.2,32.2,6,57.4,11.6c16.3,3.6,31.7,7.1,36.4,7.7
			c12.9,1.8,43.4,23.9,64.2,46.4c29.7,32.3,39.1,56.9,45.3,73.2c5.9,15.4,8.5,22.4,22,23.9c8.1,0.9,19.6-5,31.7-16.3
			c8.8-8.2,17.3-18.8,23.3-29.1c6.3-10.7,9.6-20.3,9.3-27c-0.5-11.7-10.5-26.9-29.5-45.1c-16.9-16.1-38.4-32.2-55.7-45.2
			c-20.7-15.6-32.6-24.6-35.5-30.4c-7.8-16-23.1-35.3-35.9-39.5c-13.9-4.5-41.4-8.2-48-7.9c-5.1,0.2-14.4,1-19.4,1.5
			c-2,0.2-4.1,0.6-6,1.1l-4.4,1.2l-0.4,0c-3.3-0.2-8.7-0.3-10.2,0.1c-2.5,0.7-11.8,3.7-11.9,3.7l-0.7,0.2l-0.7-0.2
			c-0.3-0.1-27.3-5.9-37.7-6c-3.5,0-7.5-0.3-11.8-0.5c-8.4-0.5-18-1-22.8,0c-2.8,0.6-11.7,5.9-13.2,12.6c-1.5,6.4-0.8,10.8,2.1,13.6
			c2.3,2.2,14.9,5.8,23.1,7.1c3.8,0.6,10.1,2.6,16.2,4.6c5.3,1.7,10.8,3.5,12.9,3.7c1.4,0.1,3.4,0.2,6,0.3
			c15.5,0.4,47.6,1.4,79.3,20.5c1.3,0.8,1.7,2.4,0.9,3.6c-0.8,1.3-2.4,1.7-3.6,0.9c-30.5-18.4-61.8-19.3-76.8-19.8
			c-2.7-0.1-4.8-0.1-6.4-0.3c-2.7-0.3-8-2-14.1-3.9c-5.6-1.8-11.9-3.9-15.3-4.4c-5.2-0.8-21.7-4.4-25.9-8.5c-2.8-2.7-4.3-6.4-4.4-11
			c-21.7-0.7-29.8,9.8-30.2,10.2l-1.6,2.1l-2.1-1.5c0,0-2.7-2-5.4-3.2c-10.9-5.2-25.6-6.6-32.6-7.3c-1.2-0.1-2.2-0.2-2.9-0.3
			c-2.4-0.3-4.2-1.4-6.9-2.9c-4-2.3-10-5.8-22.9-9.6c-18-5.4-26,4.9-28.5,9.2c4.2,3.3,15.4,11.7,23.1,13.8c4.8,1.3,9,3.6,13.9,6.3
			c6,3.3,12.8,7,22.4,9.8c9.7,2.8,17.7,6.7,24.2,9.8c5,2.4,9.3,4.5,12.1,4.9c5.2,0.6,22.8-0.6,32.3-1.2c3-0.2,5.3-0.4,6.6-0.4
			c3.7-0.2,10.7,3.2,21.8,8.9c6.5,3.3,13.1,6.7,16.5,7.6c5.7,1.5,8.8,1.5,13.8,1.5C202.2,340.9,202.7,340.9,203.3,340.9z"
                />
                <path
                  class="st0"
                  d="M141.8,283.4c-0.5,0-1-0.1-1.5-0.4c-11.4-7.5-31.2-3.5-31.4-3.5l-2.9,0.6l-0.2-3c0-0.1-0.9-10.1-7.8-18.4
			c-8.2-10-20-16.7-20.1-16.7l-1.5-0.8l0.1-1.7c0-0.3,1.9-25.4-7.6-41.8c-4.3-7.4-14.5-8.9-19.4-9.2c0.7,9.9,3,42.6,4.5,51.3
			c1.7,9.9,15,36.4,15.1,36.6c0.7,1.3,0.1,2.9-1.2,3.6c-1.3,0.7-2.9,0.1-3.6-1.2c-0.6-1.1-13.8-27.4-15.6-38.1
			c-1.7-10.5-4.6-52.8-4.7-54.6l-0.2-2.7l2.7-0.1c0.8,0,19.6-0.9,26.9,11.8c9,15.4,8.7,37.1,8.4,43.2c3.6,2.2,12.9,8.4,20.1,17.1
			c5.7,6.9,7.8,14.5,8.6,18.5c6.3-0.9,22-2.3,32.7,4.7c1.2,0.8,1.6,2.4,0.8,3.7C143.5,283,142.6,283.4,141.8,283.4z"
                />
                <path
                  class="st0"
                  d="M120.8,279.6c-0.1,0-0.1,0-0.2,0c-1.5-0.1-2.5-1.4-2.4-2.9c0-0.1,0.8-10.1,0.3-14.5
			c-2.4-18.9-11.9-26.1-12-26.2l-2.2-1.6l1.7-2.2c0-0.1,5.8-7.8,7.1-26c1-13.3-10.4-19.8-15.6-22.1c-2.4,9.9-10,41.9-11,48
			c-1,6.4-0.4,8.8-0.4,8.8c0-0.1,0-0.1,0-0.1l-5,1.6c-0.2-0.5-1-3.6,0.3-11.2c1.2-7.4,11.3-49.6,11.7-51.3l0.6-2.7l2.7,0.8
			c0.2,0.1,5.9,1.8,11.5,6c7.6,5.8,11.2,13.6,10.6,22.5c-1.1,14.7-4.8,23.1-6.9,26.8c3.4,3.3,10.2,11.9,12.2,28.2
			c0.6,4.9-0.2,15.1-0.3,15.6C123.3,278.5,122.1,279.6,120.8,279.6z"
                />
                <path
                  class="st0"
                  d="M153.8,274.2c-0.1,0-0.3,0-0.4,0c-1.4-0.2-2.4-1.6-2.2-3c2.7-17-6.5-25.3-6.9-25.6l-2.2-1.9l2.1-2
			c0.1-0.1,6.1-5.9,9.7-18.1c2.6-8.8,2.5-15.9-0.2-20.6c-1.5-2.5-3.5-3.9-4.9-4.6c-4.4,8-20.6,37.1-22.9,42.3
			c-2.1,4.6-2.2,16.9-2.1,21.3c0,1.5-1.1,2.7-2.6,2.7c-1.5,0-2.7-1.1-2.7-2.6c0-1.7-0.4-17.2,2.6-23.7c2.8-6.2,23.4-43.1,24.2-44.7
			l1-1.7l1.9,0.4c0.3,0.1,6.3,1.5,10,7.8c3.6,6.1,3.8,14.5,0.8,24.8c-2.8,9.5-7,15.6-9.4,18.5c3.2,3.8,9.3,13.3,6.8,28.5
			C156.2,273.3,155,274.2,153.8,274.2z"
                />
                <path
                  class="st1"
                  d="M183.2,270.7c-0.4,0-9.5-2.6-13.3-1.1c-3.8,1.5-7.4,4.2-8.3,7.9c-0.8,3.1-1,7.8,1.8,9.4c2.8,1.6,9,4.4,14.1,5
			c5,0.6,9.1,0.8,12.2-2c2.1-1.8,3.4-5.8,3.2-9.2c-0.2-3.3-1.8-6.7-4.8-8.3C186.9,271.9,183.2,270.7,183.2,270.7z"
                />
                <path
                  class="st1"
                  d="M145.9,197.9c0,0,5.8,7.4,2.7,14c-3.6,7.6-7.4,8.9-7.4,8.9l-7.1-4.1L145.9,197.9z"
                />
                <path
                  class="st1"
                  d="M94.3,184.5l-5.9,24.1l7.4,1.5c0,0,4.3-2.5,5.4-11.8C102.1,190.1,94.3,184.5,94.3,184.5z"
                />
                <path
                  class="st1"
                  d="M46.4,189l2,26.6l7.3-1c0,0,3.3-7,1.5-14.3C55.2,191.7,46.4,189,46.4,189z"
                />
                <path
                  class="st1"
                  d="M11.7,283.1L34.5,297l2.5-5.4c0,0-3.7-6.7-10.7-9.6C18.1,278.7,11.7,283.1,11.7,283.1z"
                />
              </g>
              <g>
                <path
                  class="st1"
                  d="M167.7,142.1c13.2,9,30.5,14.5,49.4,14.5c4.9,0,9.8-0.4,14.5-1.1c10.9,10.3,26.7,15.6,45.9,17.2
			c1.5,0.1,2.4-1.7,1.3-2.8c-8.4-8.3-13.1-17.2-14-26.7c16.5-10.7,27.1-26.8,27.1-44.8c0-18.1-10.6-34.3-27.3-44.9
			c1.2,4.5,1.8,9.1,2,13.7c10,8.8,15.5,19.7,15.5,31.2c0,14-8.2,27.3-22.6,36.5l-5,3.2l0.6,5.9c0.5,5.3,1.9,10.5,4.3,15.5
			c-8.7-2.5-15.7-6.2-21-11.3l-3.5-3.3l-4.7,0.7c-4.3,0.6-8.6,1-13,1c-10.1,0-19.7-1.7-28.5-4.9c-3.9,0.4-7.9,0.6-11.9,0.6
			C173.7,142.5,170.7,142.3,167.7,142.1z"
                />
                <path
                  class="st1"
                  d="M176.7,2.9c-45.6,0-82.6,29.6-82.6,66.1c0,20.5,11.6,38.8,29.9,50.9c-1,10.8-6.1,20.9-15.4,30.4
			c-1.2,1.2-0.2,3.3,1.5,3.1c21.2-1.8,38.6-7.8,50.7-19.6c5.2,0.8,10.5,1.2,15.9,1.2c45.6,0,82.6-29.6,82.6-66.1
			C259.3,32.5,222.3,2.9,176.7,2.9z M142.5,76.1c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8c4.9,0,8.8,4,8.8,8.8
			C151.4,72.2,147.4,76.1,142.5,76.1z M176.7,76.1c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8
			C185.5,72.2,181.6,76.1,176.7,76.1z M210.6,76.1c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8c4.9,0,8.8,4,8.8,8.8
			C219.5,72.2,215.5,76.1,210.6,76.1z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div class="grid grid-cols-3 mt-10">
          <div
            class="cursor-pointer"
            @click="newWindow('https://www.youtube.com/c/JuditAntalsChannel')"
          >
            <div class="flex justify-center">
              <svg
                version="1.1"
                class="fill-current text-secondary"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="max-width: 120px"
                xml:space="preserve"
              >
                <g id="XMLID_1_">
                  <polygon
                    id="XMLID_3_"
                    class="st0"
                    points="147.1,277.4 164.8,277.4 164.8,369.6 183.4,369.6 183.4,277.4 202,277.4 202,261.6 
		147.1,261.6 	"
                  />
                  <path
                    id="XMLID_4_"
                    class="st0"
                    d="M256.9,201.1c2.8,0,4.7-0.9,5.6-1.9c1.9-0.9,1.9-2.8,1.9-5.6v-47.5c0-1.9-0.9-3.7-1.9-4.7
		c-1.9-0.9-3.7-1.9-5.6-1.9c-1.9,0-3.7,0.9-5.6,1.9c-0.9,0.9-1.9,2.8-1.9,4.7v47.5c0,2.8,0.9,4.7,1.9,5.6
		C252.3,200.1,254.1,201.1,256.9,201.1z"
                  />
                  <path
                    id="XMLID_7_"
                    class="st0"
                    d="M296,289.5c-2.8,0-4.7,0.9-7.4,1.9s-4.7,2.8-6.5,5.6v-34.4h-15.8v108h15.8V364
		c1.9,2.8,4.7,4.7,6.5,5.6c2.8,0.9,5.6,1.9,8.4,1.9c4.7,0,8.4-1.9,11.2-4.7c2.8-2.8,3.7-7.4,3.7-13V310c0-6.5-0.9-11.2-3.7-14.9
		C305.3,290.4,301.6,289.5,296,289.5z M295.1,351c0,2.8-0.9,4.7-0.9,5.6c-0.9,0.9-2.8,1.9-4.7,1.9c-0.9,0-2.8,0-3.7-0.9
		c-0.9-0.9-2.8-1.9-3.7-2.8v-49.3c0.9-0.9,1.9-1.9,3.7-2.8c0.9-0.9,1.9-0.9,3.7-0.9c1.9,0,3.7,0.9,4.7,1.9c0.9,1.9,1.9,3.7,1.9,6.5
		v41H295.1z"
                  />
                  <path
                    id="XMLID_8_"
                    class="st0"
                    d="M238.3,351c-1.9,1.9-2.8,2.8-4.7,4.7c-1.9,0.9-3.7,1.9-4.7,1.9c-1.9,0-2.8,0-3.7-0.9
		c-0.9-0.9-0.9-1.9-0.9-3.7v-61.4h-15.8v66.1c0,4.7,0.9,8.4,2.8,10.2c1.9,2.8,4.7,3.7,8.4,3.7c2.8,0,6.5-0.9,9.3-2.8
		c2.8-1.9,6.5-3.7,9.3-7.4v8.4h15.8v-79.1h-15.8V351L238.3,351z"
                  />
                  <path
                    id="XMLID_14_"
                    class="st0"
                    d="M256,0C114.5,0,0,114.5,0,256s114.5,256,256,256s256-114.5,256-256S397.5,0,256,0z M297,126.6
		h17.7v67c0,1.9,0,3.7,0.9,4.7c0.9,0.9,1.9,1.9,3.7,1.9c0.9,0,2.8-0.9,4.7-1.9c1.9-0.9,3.7-2.8,5.6-4.7v-66.1h17.7V215h-16.8v-9.3
		c-3.7,3.7-6.5,6.5-10.2,8.4c-3.7,1.9-7.4,2.8-10.2,2.8c-3.7,0-7.4-0.9-9.3-3.7c-1.9-2.8-2.8-6.5-2.8-12.1v-74.5H297z M230.9,146.2
		c0-6.5,2.8-12.1,7.4-15.8c4.7-3.7,11.2-5.6,19.5-5.6c7.4,0,13,1.9,18.6,6.5c4.7,4.7,7.4,9.3,7.4,16.8v45.6c0,7.4-1.9,13-6.5,17.7
		c-4.7,4.7-11.2,6.5-19.5,6.5c-7.4,0-14-1.9-18.6-6.5c-4.7-4.7-7.4-10.2-7.4-17.7L230.9,146.2L230.9,146.2z M182.5,95l13,47.5h0.9
		L208.5,95H229l-23.3,69.8v49.3h-20.5v-47.5L161,95H182.5z M416.1,345.4c0,31.7-26.1,57.7-57.7,57.7H160.1
		c-31.7,0-57.7-26.1-57.7-57.7v-46.5c0-31.7,26.1-57.7,57.7-57.7h199.2c31.7,0,57.7,26.1,57.7,57.7v46.5H416.1z"
                  />
                  <path
                    id="XMLID_17_"
                    class="st0"
                    d="M344.4,288.6c-7.4,0-13,1.9-17.7,6.5c-4.7,4.7-6.5,10.2-6.5,16.8v35.4c0,7.4,1.9,14,6.5,17.7
		c3.7,4.7,9.3,6.5,16.8,6.5c8.4,0,14-1.9,17.7-6.5c3.7-3.7,5.6-10.2,5.6-18.6v-3.7H350v3.7c0,4.7-0.9,7.4-1.9,9.3
		c-0.9,0.9-2.8,1.9-5.6,1.9c-2.8,0-4.7-0.9-5.6-2.8c-0.9-1.9-1.9-4.7-1.9-8.4v-14.9h30.7v-19.5c0-7.4-1.9-13-5.6-17.7
		C357.5,290.4,351.9,288.6,344.4,288.6z M350,319.3h-14v-7.4c0-3.7,0.9-5.6,1.9-7.4c0.9-1.9,2.8-1.9,5.6-1.9c2.8,0,4.7,0.9,5.6,1.9
		c0.9,0.9,1.9,3.7,1.9,7.4L350,319.3L350,319.3z"
                  />
                </g>
              </svg>
            </div>
            <div class="flex flex-col justify-center text-secondary mt-5">
              <div class="uppercase font-bold text-lg mb-2">YouTube</div>
              <div class="text-white opacity-90">
                Taroszkóp, meditációs videók
              </div>
            </div>
          </div>
          <div
            @click="newWindow('https://www.facebook.com/AntalJudit11')"
            class="cursor-pointer"
          >
            <div class="flex justify-center">
              <svg
                version="1.1"
                id="Réteg_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                style="max-width: 120px"
                class="fill-current text-secondary w-full"
                xml:space="preserve"
              >
                <path
                  d="M12,0.1c-6.6,0-12,5.4-12,12c0,6,4.4,11,10.2,11.9v-8.7h-3v-3.2h3V10c0-3.5,1.7-5,4.6-5c1.4,0,2.1,0.1,2.5,0.1v2.8h-2
	c-1.2,0-1.7,1.2-1.7,2.5v1.7h3.6l-0.5,3.2h-3.1v8.7C19.5,23.1,24,18.1,24,12.1C24,5.4,18.6,0.1,12,0.1z"
                />
              </svg>
            </div>
            <div class="flex flex-col justify-center text-secondary mt-5">
              <div class="uppercase font-bold text-lg mb-2">Facebook</div>
              <div class="text-white opacity-90">Heti / napi üzenetek</div>
            </div>
          </div>
          <div>
            <div class="flex justify-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="max-width: 120px"
                class="fill-current text-secondary w-full"
                xml:space="preserve"
              >
                <path
                  class="st0"
                  d="M255,0C113.6,0-1,114.6-1,256s114.6,256,256,256s256-114.6,256-256S396.4,0,255,0z M427.5,355.1
	c0,8.1-2.9,15-8.6,20.8c-5.8,5.8-12.7,8.6-20.8,8.6H127.4c-8.1,0-15-2.9-20.8-8.6c-5.8-5.8-8.6-12.7-8.6-20.8v-146
	c5.4,6,11.6,11.3,18.6,16c44.4,30.2,74.8,51.3,91.4,63.4c7,5.1,12.7,9.2,17,12c4.4,2.9,10.1,5.8,17.4,8.8c7.2,3,14,4.5,20.2,4.5h0.4
	c6.3,0,13-1.5,20.2-4.5c7.2-3,13-5.9,17.4-8.8c4.4-2.9,10-6.9,17-12c20.8-15.1,51.4-36.2,91.6-63.4c7-4.8,13.1-10.1,18.4-16V355.1z M418.4,182.9c-6,8.8-13.5,16.4-22.4,22.6c-46.1,32-74.8,51.9-86.1,59.8c-1.2,0.9-3.8,2.7-7.8,5.6c-4,2.9-7.3,5.2-9.9,7
	c-2.6,1.8-5.8,3.8-9.6,6c-3.7,2.2-7.3,3.9-10.6,5c-3.3,1.1-6.4,1.7-9.2,1.7h-0.4c-2.8,0-5.9-0.6-9.2-1.7c-3.3-1.1-6.8-2.8-10.6-5
	c-3.7-2.2-6.9-4.2-9.6-6c-2.6-1.8-5.9-4.1-9.9-7c-4-2.9-6.6-4.8-7.8-5.6c-11.2-7.8-27.2-19-48.2-33.6c-21-14.5-33.5-23.3-37.7-26.2
	c-7.6-5.1-14.8-12.2-21.5-21.2c-6.7-9-10.1-17.4-10.1-25.1c0-9.6,2.5-17.5,7.6-23.9c5.1-6.4,12.4-9.6,21.8-9.6H398
	c8,0,14.9,2.9,20.7,8.6c5.8,5.8,8.7,12.7,8.7,20.8C427.5,164.8,424.4,174,418.4,182.9z"
                />
              </svg>
            </div>
            <div class="flex flex-col justify-center text-secondary mt-5">
              <div class="uppercase font-bold text-lg mb-2">E-mail</div>
              <div class="text-white opacity-90">
                judit.rightquestion@gmail.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CardCorner from "../components/CardCorner.vue";

//import Icons from "../components/Icons.vue";
export default {
  name: "ContactView",
  components: { CardCorner },

  methods: {
    newWindow(url) {
      window.open(url, "_blank");
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
