<template>
  <div class="bg-primary-darker min-h-screen flex flex-col">
    <nav-component />
    <div class="flex-grow relative flex flex-col justify-start">
      <router-view />
    </div>
    <footer-component class="relative" />
  </div>
  <div
    v-if="loading"
    class="w-screen h-screen fixed top-0 left-0 z-50 flex items-center justify-center bg-black bg-opacity-60"
  >
    <div class="lds-hourglass"></div>
  </div>
  <div
    v-if="devPopup"
    style="z-index: 500"
    class="w-screen h-screen flex items-center justify-center fixed top-0 left-0 bg-black bg-opacity-20"
  >
    <div
      class="w-full bg-white rounded-lg px-5 py-5 font-bold"
      style="max-width: 400px"
    >
      Az oldal fejlesztés alatt áll! <br />Kérlek látogass vissza később!
      <div>
        <button
          @click="devPopup = false"
          class="bg-primary text-white rounded-full px-5 py-2 mt-5"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
  <div
    class="w-screen px-4 fixed top-20 left-0"
    style="z-index: 1000"
    v-if="alert.show"
  >
    <div
      :class="[alert.type == 'success' ? 'bg-green' : 'bg-red']"
      class="rounded-lg m-auto px-5 py-4 flex items-center"
      style="max-width: 600px"
    >
      <div class="flex-grow font-bold">{{ alert.text }}</div>
      <button
        @click="alert.show = false"
        class="bg-black text-white w-8 h-8 rounded-full"
      >
        X
      </button>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import FooterComponent from "./components/FooterComponent.vue";
import NavComponent from "./components/NavComponent.vue";

import { axiosInstance } from "@/main";

export default {
  name: "AppView",
  components: { FooterComponent, NavComponent },
  data() {
    return {
      sidebarShow: false,
      isAdmin: false,
      loading: false,
      devPopup: true,
      alert: {
        type: "success",
        show: false,
        text: "Sikeres mentés",
      },
      siteRoot: "http://antaljudit.com",
      siteRoot0: "http://localhost:8080/antaljudit_vue",
    };
  },
  methods: {
    logOut() {
      this.setCookie("jwt", null, 365);

      location.reload();
    },
    showAlert(type = "success", text = null) {
      this.alert.show = true;
      this.alert.type = type;
      let this_ = this;
      if (text) {
        this.alert.text = text;
      }
      setTimeout(() => {
        this_.alert.show = false;
      }, 5000);
    },
    setCookie(cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    checkLogin() {
      let this_ = this,
        r = {},
        o = {
          jwt: this.getCookie("jwt"),
        };
      axiosInstance
        .post(
          //"http://localhost:8080/antaljudit_vue/backend/api/controller/user/check_login.php",
          `${this.$root.siteRoot}/backend/api/controller/user/check_login.php`,
          o
        )
        .then(function (response) {
          r = response.data;
          //console.log('r status', r, o)
          if (r.status === "OK") {
            //this_.$root.set_alert("success", "Sikeres belépés");
            //                            this_.$root.setCookie('jwt', r.jwt, 365);

            if (r.jwt) {
              this_.$root.setCookie("jwt", r.jwt, 365);
              // this_.$root.setCookie('user_obj', JSON.stringify(user_obj), 365);
              //this_.set_user_obj();
            }
            this_.$root.isAdmin = r.isAdmin.Admin === "1";
          } else {
            //                            this_.$root.set_alert('error', (r.message || "Sikertelen bejelentkezés."));
          }
        })
        .catch(function () {
          this_.$root.isAdmin = false;
          //console.log('nemoki login', error);
          //                        this_.$root.set_alert('error', 'Sikertelen belépés');
          //                        currentObj.output = error;
        });
    },
  },
  mounted() {
    this.checkLogin();
  },
  beforeRouteEnter() {
    window.scrollTo(0, 0);
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* font-family: "Bubblegum Sans", cursive;
  font-family: "Handlee", cursive;
  font-family: "Oldenburg", cursive;
  font-family: "Righteous", cursive;*/
}
#app .custom-font {
  font-family: "Oldenburg", cursive;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
