<template>
  <div
    class="container m-auto bg-white px-7 rounded-lg flex justify-center flex-col items-center mt-16"
    style="max-width: 500px"
  >
    <div
      class="text-primary text-center uppercase font-bold text-xl custom-font mt-10"
    >
      Bejelentkezés
    </div>
    <div class="inline-block text-left my-10">
      <div class="text-sm text-primary">Felhasználónév</div>
      <input
        class="border border-primary rounded-full py-2 px-3"
        v-model="u_name"
      />
      <div class="text-sm text-primary mt-4">Jelszó</div>
      <input
        type="password"
        class="border border-primary rounded-full py-2 px-3"
        v-model="u_pass"
      />
      <div class="flex justify-center">
        <button
          @click="save()"
          class="bg-primary rounded-full py-3 px-3 text-white uppercase text-sm font-bold mt-7 w-full"
        >
          Belépés
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { axiosInstance } from "@/main";

//import Icons from "../components/Icons.vue";
export default {
  name: "AdminView",
  components: {},
  data() {
    return {
      u_name: "",
      u_pass: "",
    };
  },
  methods: {
    testReg() {
      let o = {
        loginName: "judit",
        fullName: "Antal Judit",
        password: "9f4AfwjBqNv@X4S",
        email: "judit.rightquestion@gmail.com",
        country: 1,
        city_lat: 1,
        city_long: 1,
      };
      //this.$root.loading=true
      axiosInstance
        .post(
          //"http://localhost:8080/antaljudit_vue/backend/api/controller/user/create.php",
          `${this.$root.siteRoot}/backend/api/controller/user/create.php`,
          // "/create.php",
          o,
          { withCredentials: false }
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function () {
          //this_.$root.set_alert("error", "Sikertelen mentés." + error);
        });
    },
    save() {
      const this_ = this;
      let o = {
        loginName: this.u_name,
        password: this.u_pass,
      };
      this.$root.loading = true;
      axiosInstance
        .post(
          `${this.$root.siteRoot}/backend/api/controller/user/login.php`,
          // "/create.php",
          o,
          { withCredentials: false }
        )
        .then(function (response) {
          if (response.data.isAdmin.Admin === "1") {
            this_.$root.isAdmin = true;
            this_.$root.setCookie("jwt", response.data.jwt, 365);
            this_.$router.push("/blogs");
            this_.$root.loading = false;
            //  this_.$root.setCookie('user_obj', JSON.stringify(user_obj), 365);
            console.log("admin");
          }
          console.log(response);
        })
        .catch(function () {
          this_.$root.loading = false;
          //this_.$root.set_alert("error", "Sikertelen mentés." + error);
        });
    },
  },
};
</script>
