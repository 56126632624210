<template>
  <div
    class="flex relative min-h-screen"
    style="background-size: cover; background: #543687"
  >
    <div
      class="absolute w-full h-full overflow-hidden"
      style="background-size: cover"
      :style="{
        backgroundImage: `url(${require(`@/assets/111.svg`)})`,
      }"
    >
      <svg
        class="w-full"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 634.9 364.5"
        style="enable-background: new 0 0 634.9 364.5"
        xml:space="preserve"
      >
        <path
          fill="#391a6f"
          class="st0"
          d="M634.9,136.9c0-0.5,0-0.9,0-1.4c-3-0.7-5.7-2.2-8.6-3.4c-10.7-4.4-21.2-9.4-31.4-14.7
	c-8.2-4.2-16.3-8.5-24-13.7c-6-4-11.7-8.4-17.2-13c-4.6-3.8-9-7.8-13.4-11.9c-5.8-5.5-11.3-11.2-16.7-17.2
	c-3.3-3.6-6.6-7.2-9.9-10.8c-3.5-3.9-6.8-8-10.2-12c-4.6-5.4-9.2-10.9-13.8-16.4c-3.4-4.1-6.9-8.2-10.4-12.2
	c-2.7-3.1-5.5-6.1-8.2-9.2c-0.3-0.3-0.6-0.6-0.6-1c-0.2,0-0.3,0-0.5,0C412,0,354,0,295.9,0c-0.9,0-1.4,0.4-1.9,1
	c-2.8,4.4-5.7,8.8-8.1,13.4c-6,11.3-10.7,23.1-13.6,35.6c-1.8,7.6-2.9,15.3-3.5,23.1c-0.6,9.3-0.2,18.5,0.8,27.7
	c0.9,7.6,2.3,15.1,4.2,22.4c3.8,14.5,9.3,28.5,16.2,41.8c6,11.7,12.9,22.9,20.5,33.6c5.2,7.3,10.7,14.3,16.4,21.1
	c4.6,5.5,9.3,10.8,14.2,16c8.4,8.7,17,17.1,26.1,25.1c7.6,6.6,15.3,13,23.3,19.1c9.5,7.2,19.3,14,29.3,20.5
	c10,6.4,20.2,12.5,30.7,18.2c8.9,4.8,17.9,9.3,27.1,13.5c9.6,4.4,19.3,8.4,29.1,12c12.4,4.6,25,8.6,37.8,11.8
	c9.8,2.5,19.7,4.7,29.8,6.1c5.7,0.8,11.5,1.4,17.3,1.8c2.5,0.2,5.1,0,7.6,0.6c11.9,0,23.8,0,35.7,0c0-0.5,0-1,0-1.5
	C634.9,287.6,634.9,212.2,634.9,136.9z"
        />
        <path
          fill="#2c1456"
          class="st1"
          d="M468.4,0.1c0,0,55.3,100.9,166.5,136.9l0-136.9L468.4,0.1z"
        />
      </svg>
    </div>
    <div
      class="container m-auto overflow-hidden relative my-16 rounded-lg"
      style="max-width: 1100px"
    >
      <div class="w-full relative" style="padding-top: 60%">
        <img
          :src="
            item.FileName
              ? `${$root.siteRoot}/backend/api/images/${item.FileName}`
              : require('@/assets/zodiac3.jpg')
          "
          class="w-full object-cover absolute h-full left-0 top-0"
        />
      </div>
      <div
        class="w-full text-secondary flex items-end flex-col justify-center text-right px-10 bg-primary-darkest py-10 pt-5 rounded-b-lg"
      >
        <div class="text-left w-full">
          <div class="uppercase font-bold custom-font text-xl mb-2 mt-5">
            {{ item ? item.Title : "" }}
          </div>
          <div class="flex gap-2 mb-6 opacity-90">
            <svg
              class="fill-current text-secondary w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"
              />
            </svg>
            <div class="text-sm">
              {{ item ? item.DateNum.split(" ")[0] : "" }}
            </div>
          </div>
          <div
            class="text-md text-white text-opacity-80 mt-2 mb-4 text-justify"
          >
            <div
              v-html="item ? item.Text : ''"
              class="text-white fulltext opacity-90"
            ></div>
          </div>
        </div>
      </div>
      <router-link to="/blogs">
        <div
          class="text-sm uppercase text-secondary text-right mt-6 font-bold cursor-pointer"
        >
          Összes bejegyzés
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/*import CardCorner from "../components/CardCorner.vue";*/
//import Icons from "../components/Icons.vue";
import { axiosInstance } from "@/main";

export default {
  name: "BlogView",
  // components: { Icons },
  data() {
    return {
      date: "2022-12-12 12:12",
      item: null,
      items: [],
    };
  },
  methods: {
    get() {
      let this_ = this;
      axiosInstance
        .post(
          "http://localhost:8080/antaljudit_vue/backend/api/controller/talk/get_talk.php",
          // "/create.php",
          {},
          { withCredentials: false }
        )
        .then(function (response) {
          this_.items = response.data.msgList;
          this_.item = this_.items.find(
            (f) => parseInt(f.Id) === parseInt(this_.$route.params.id)
          );
          console.log(response);
        })
        .catch(function () {
          //this_.$root.set_alert("error", "Sikertelen mentés." + error);
        });
    },
  },
  created() {
    this.get();
    window.scrollTo(0, 0);
  },
};
</script>
