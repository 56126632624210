<template>
  <div class="home bg-primary-darker">
    <div class="relative flex w-full flex-col">
      <img src="@/assets/zodiac4.jpg" class="w-full hidden sm:block" />
      <div
        style="box-shadow: 8px 8px 5px #00000030"
        class="hidden sm:flex shadow-lg drop-shadow-md text-xl uppercase bg-primary-darker font-bold rounded-xl absolute text-primary-light p-3 top-1/2 transform -translate-y-1/2 -translate-x-1/2 left-1/2"
      >
        <div
          class="rounded-lg border-primary p-5"
          style="border: 1px solid #a496a1"
        >
          <div class="leading-none custom-font" style="font-size: 72px">
            Antal
          </div>
          <div class="mt-3 custom-font" style="font-size: 72px">judit</div>
          <div class="text-sml mt-8 mb-2">Spirituális tanító</div>
          <div class="text-xs text-secondary">
            Személyre szabott kártyavetés | Coaching
          </div>
        </div>
      </div>
      <div
        style="box-shadow: 8px 8px 5px #00000030"
        class="w-full sm:hidden flex shadow-lg drop-shadow-md text-xl uppercase bg-primary-darker font-bold rounded-xl relative text-primary-light p-3"
      >
        <div
          class="rounded-lg border-primary p-5 w-full"
          style="border: 1px solid #a496a1"
        >
          <div class="leading-none custom-font" style="font-size: 72px">
            Antal
          </div>
          <div class="mt-3 custom-font" style="font-size: 72px">judit</div>
          <div class="text-sml mt-8 mb-2">Spirituális tanító</div>
          <div class="text-xs text-secondary">
            Személyre szabott kártyavetés | Coaching
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white">
      <services :count="3" />
      <div class="flex justify-center bg-primary-darkest px-4 py-2 pb-4">
        <button
          @click="$router.push('/services')"
          class="bg-primary-darkest rounded-full px-5 py-2 text-sm uppercase text-secondary font-bold border-secondary border-2 hover:bg-secondary hover:text-primary transition"
        >
          Összes szolgáltatás
        </button>
      </div>
    </div>
    <div
      class="flex relative"
      style="background-size: cover; background: #543687"
    >
      <div
        class="absolute w-full h-full overflow-hidden"
        style="background-size: cover"
        :style="{
          backgroundImage: `url(${require(`@/assets/111.svg`)})`,
        }"
      >
        <svg
          class="w-full"
          version="1.1"
          id="Réteg_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 634.9 364.5"
          style="enable-background: new 0 0 634.9 364.5"
          xml:space="preserve"
        >
          <path
            fill="#391a6f"
            class="st0"
            d="M634.9,136.9c0-0.5,0-0.9,0-1.4c-3-0.7-5.7-2.2-8.6-3.4c-10.7-4.4-21.2-9.4-31.4-14.7
	c-8.2-4.2-16.3-8.5-24-13.7c-6-4-11.7-8.4-17.2-13c-4.6-3.8-9-7.8-13.4-11.9c-5.8-5.5-11.3-11.2-16.7-17.2
	c-3.3-3.6-6.6-7.2-9.9-10.8c-3.5-3.9-6.8-8-10.2-12c-4.6-5.4-9.2-10.9-13.8-16.4c-3.4-4.1-6.9-8.2-10.4-12.2
	c-2.7-3.1-5.5-6.1-8.2-9.2c-0.3-0.3-0.6-0.6-0.6-1c-0.2,0-0.3,0-0.5,0C412,0,354,0,295.9,0c-0.9,0-1.4,0.4-1.9,1
	c-2.8,4.4-5.7,8.8-8.1,13.4c-6,11.3-10.7,23.1-13.6,35.6c-1.8,7.6-2.9,15.3-3.5,23.1c-0.6,9.3-0.2,18.5,0.8,27.7
	c0.9,7.6,2.3,15.1,4.2,22.4c3.8,14.5,9.3,28.5,16.2,41.8c6,11.7,12.9,22.9,20.5,33.6c5.2,7.3,10.7,14.3,16.4,21.1
	c4.6,5.5,9.3,10.8,14.2,16c8.4,8.7,17,17.1,26.1,25.1c7.6,6.6,15.3,13,23.3,19.1c9.5,7.2,19.3,14,29.3,20.5
	c10,6.4,20.2,12.5,30.7,18.2c8.9,4.8,17.9,9.3,27.1,13.5c9.6,4.4,19.3,8.4,29.1,12c12.4,4.6,25,8.6,37.8,11.8
	c9.8,2.5,19.7,4.7,29.8,6.1c5.7,0.8,11.5,1.4,17.3,1.8c2.5,0.2,5.1,0,7.6,0.6c11.9,0,23.8,0,35.7,0c0-0.5,0-1,0-1.5
	C634.9,287.6,634.9,212.2,634.9,136.9z"
          />
          <path
            fill="#2c1456"
            class="st1"
            d="M468.4,0.1c0,0,55.3,100.9,166.5,136.9l0-136.9L468.4,0.1z"
          />
        </svg>
      </div>
      <div
        class="flex container m-auto overflow-hidden relative my-16 flex-col lg:flex-row"
        style="max-width: 1200px"
      >
        <div class="w-full lg:w-1/2 relative">
          <img
            src="@/assets/judit.jpg"
            class="w-full relative py-8"
            style="filter: hue-rotate(350deg) contrast(1.1)"
          />
          <svg
            preserveAspectRatio="none"
            class="absolute top-0 w-full h-full"
            version="1.1"
            id="Réteg_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 538.3 591.4"
            style="enable-background: new 0 0 538.3 591.4"
            xml:space="preserve"
          >
            <path
              fill="#2c1456"
              class="st0"
              d="M538.3,591.4L0,592.7V0h538.3V591.4z M107.8,544.3l314.1-0.4l-0.5-353.7c-0.1-42-16.4-81.4-46.2-111
	s-69.2-45.9-111.1-45.8c-86.5,0.1-157,70.7-156.8,157.3L107.8,544.3z"
            />
          </svg>
          <div
            class="absolute rounded-full bg-secondary w-2 h-2 top-1/2 left-16"
          ></div>
          <div
            class="absolute rounded-full bg-secondary w-2 h-2 top-1/2 right-16"
          ></div>
        </div>
        <div
          class="w-full lg:w-1/2 my-0 lg:my-12 text-secondary flex items-center lg:items-end flex-col justify-center text-right px-10 bg-primary"
        >
          <div style="max-width: 500px" class="text-center my-10">
            <svg
              class="fill-current text-secondary mb-5 mx-auto w-20"
              version="1.1"
              id="Réteg_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 171.8 166.8"
              style="enable-background: new 0 0 171.8 166.8"
              xml:space="preserve"
            >
              <g>
                <polygon
                  class="st0"
                  points="85.9,12.8 80.5,16.3 82.3,10.2 77.2,6.2 83.7,6 85.9,0 88.2,6 94.6,6.2 89.5,10.2 91.3,16.3 	"
                />
                <g>
                  <path
                    class="st0"
                    d="M85.8,132.8l-0.8-1c-8.5-10.5-13.5-27.4-13.5-45.4c0-18,5.1-35,13.5-45.4l0.8-1l0.8,1
			c8.5,10.5,13.5,27.4,13.5,45.4c0,18-5.1,35-13.5,45.4L85.8,132.8z M85.8,43.3c-7.7,10.2-12.3,26.2-12.3,43.1
			c0,17,4.6,32.9,12.3,43.1c7.7-10.2,12.3-26.2,12.3-43.1C98.1,69.4,93.5,53.4,85.8,43.3z"
                  />
                  <path
                    class="st0"
                    d="M85.7,132.7l0.1-1.3c1.4-13.4,9.8-29,22.5-41.7C121,77.1,136.6,68.7,150,67.2l1.3-0.1l-0.1,1.3
			c-1.4,13.4-9.8,29-22.5,41.7c-12.7,12.7-28.3,21.1-41.7,22.5L85.7,132.7z M148.9,69.4c-12.6,1.7-27.2,9.8-39.2,21.8
			c-12,12-20.1,26.5-21.8,39.2c12.6-1.7,27.2-9.8,39.2-21.8C139.1,96.6,147.2,82.1,148.9,69.4z"
                  />
                  <path
                    class="st0"
                    d="M85.6,132.7l-0.3-1.2c-3.6-13-1.5-30.6,5.6-47.1l0,0c7.1-16.5,18.5-30.1,30.4-36.3l1.1-0.6l0.3,1.2
			c3.6,13,1.5,30.6-5.6,47.1c-7.1,16.5-18.5,30.1-30.4,36.3L85.6,132.7z M92.8,85.2c-6.7,15.6-8.9,32.1-5.9,44.5
			c11.1-6.3,21.7-19.1,28.4-34.7c6.7-15.6,8.9-32.1,5.8-44.5C110.1,56.8,99.5,69.6,92.8,85.2L92.8,85.2z"
                  />
                  <path
                    class="st0"
                    d="M102.4,135.2c-5.6,0-10.9-0.7-15.5-2.1l-1.2-0.4l0.6-1.1c6.6-11.8,20.4-22.8,37.1-29.4l0,0
			c16.7-6.7,34.3-8.3,47.2-4.3l1.2,0.4l-0.6,1.1c-6.6,11.8-20.4,22.8-37.1,29.4C123.4,133,112.4,135.2,102.4,135.2z M88.7,131.5
			c12.3,3.4,28.9,1.7,44.6-4.6c15.7-6.3,28.9-16.5,35.5-27.4c-12.3-3.4-28.9-1.7-44.6,4.6C108.4,110.3,95.3,120.5,88.7,131.5z"
                  />
                  <path
                    class="st0"
                    d="M86.2,132.7l-1.3-0.1c-13.4-1.4-29-9.8-41.7-22.5C30.5,97.3,22.1,81.8,20.7,68.4l-0.1-1.3l1.3,0.1
			c13.4,1.4,29,9.8,41.7,22.6l0,0c0,0,0,0,0,0c12.7,12.7,21.1,28.3,22.5,41.7L86.2,132.7z M22.9,69.4c1.7,12.6,9.8,27.2,21.8,39.2
			c12,12,26.5,20.1,39.2,21.8C82.1,117.8,74,103.2,62,91.2l0,0C50.1,79.2,35.5,71.2,22.9,69.4z"
                  />
                  <path
                    class="st0"
                    d="M86.2,132.7l-1.1-0.6c-11.9-6.2-23.3-19.8-30.4-36.3c-7.1-16.5-9.2-34.1-5.6-47.1l0.3-1.2l1.1,0.6
			c11.9,6.2,23.3,19.8,30.4,36.3l0,0c7.1,16.5,9.2,34.1,5.6,47.1L86.2,132.7z M50.6,50.5c-3,12.4-0.9,28.9,5.8,44.5
			c6.7,15.6,17.3,28.4,28.4,34.7c3-12.4,0.9-28.9-5.9-44.5C72.3,69.6,61.8,56.8,50.6,50.5z"
                  />
                  <path
                    class="st0"
                    d="M69.4,135.2c-9.9,0-21-2.2-31.6-6.5C21.1,122,7.2,111,0.6,99.3L0,98.2l1.2-0.4c12.9-4,30.5-2.4,47.2,4.3
			c16.7,6.7,30.6,17.7,37.1,29.4l0.6,1.1l-1.2,0.4C80.3,134.5,75,135.2,69.4,135.2z M3,99.4c6.6,10.9,19.7,21.1,35.5,27.4
			c15.7,6.3,32.3,8,44.6,4.6c-6.6-10.9-19.7-21.1-35.5-27.4l0,0C31.9,97.7,15.3,96,3,99.4z"
                  />
                </g>
                <polygon
                  class="st0"
                  points="126.9,33.2 121.5,36.7 123.3,30.6 118.2,26.7 124.6,26.4 126.9,20.4 129.1,26.4 135.6,26.7 
		130.5,30.6 132.3,36.7 	"
                />
                <polygon
                  class="st0"
                  points="44.9,33.2 39.6,36.7 41.3,30.6 36.2,26.7 42.7,26.4 44.9,20.4 47.2,26.4 53.6,26.7 48.6,30.6 
		50.3,36.7 	"
                />
                <polygon
                  class="st0"
                  points="85.9,160.4 80.5,163.9 82.3,157.8 77.2,153.8 83.7,153.6 85.9,147.6 88.2,153.6 94.6,153.8 
		89.5,157.8 91.3,163.9 	"
                />
                <path
                  class="st0"
                  d="M146.5,157H99.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h46.9c0.6,0,1,0.5,1,1C147.5,156.6,147,157,146.5,157z"
                />
                <path
                  class="st0"
                  d="M72.2,157H25.3c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h46.9c0.6,0,1,0.5,1,1C73.2,156.6,72.8,157,72.2,157z"
                />
              </g>
            </svg>
            <div class="uppercase font-bold custom-font text-xl mb-6">
              Magamról
            </div>
            <div class="text-md text-white text-opacity-80 mt-2 mb-4">
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-primary-darker"
      style="background: linear-gradient(0deg, #673ab7, aquamarine)"
    >
      <blogs :num="3" />
      <div class="flex justify-center bg-primary-darkest px-4 py-3">
        <button
          @click="$router.push('/blogs')"
          class="bg-primary-darkest rounded-full px-5 py-2 text-sm uppercase text-secondary font-bold border-secondary border-2 hover:bg-secondary hover:text-primary transition"
        >
          Összes bejegyzés
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/*import CardCorner from "../components/CardCorner.vue";
import Icons from "../components/Icons.vue";*/
import Services from "../views/Services.vue";
import Blogs from "../views/Blogs.vue";
export default {
  name: "HomeView",
  components: {
    Blogs,
    Services,
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
