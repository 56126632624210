import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Service from '../views/Service.vue'
import Services from '../views/Services.vue'
import BlogView from '../views/BlogView.vue'
import BlogsView from '../views/Blogs.vue'
import AdminView from '../views/Admin.vue'
import ContactView from '../views/ContactView.vue'
import GyikView from '../views/GyikView.vue'
import TermsView from '../views/Terms.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/service/:id',
    name: 'ServiceView',
    component: Service
  },
  {
    path: '/blog/:id',
    name: 'BlogView',
    component: BlogView
  },
  {
    path: '/blogs',
    name: 'BlogsView',
    component: BlogsView
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: ContactView
  },
  {
    path: '/gyik',
    name: 'GyikView',
    component: GyikView
  },
  {
    path: '/adminpage',
    name: 'AdminView',
    component: AdminView
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/terms',
    name: 'TermsView',
    component: TermsView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
