import { createApp } from 'vue'
import App from './App.vue'
import "./main.css";
import router from './router'
import axios from 'axios';
/*
window.axios = axios
    window.axios.defaults.withCredentials = true
   
    window.axios.defaults.baseURL= 'http://localhost/antaljudit/antaljudit_vue/backend/api/controller/'
    //:'http://localhost/antaljudit/antaljudit_vue/backend/api/controller/' 
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
*/
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    const axiosInstance = axios.create({
        baseURL:  process.env.VUE_APP_LOCALHOST_API_URL, 
       // baseURL:  "api", 
        withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
          }
        })
        
 /*       Vue.prototype.$axios = axiosInstance;
        export {axiosInstance};

const axiosInstance = axios.create({
  withCredentials: true,
})

const app = createApp()
                                        👇
app.config.globalProperties.$axios = { ...axiosInstance }
*/
const app= createApp(App)
app.config.globalProperties.$axios = { ...axiosInstance }
export {axiosInstance};
//createApp(App).use(router).use(axios).mount('#app')
app.use(router).mount('#app')
