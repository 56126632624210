<template>
  <div
    class="relative"
    :class="[
      $route.name === 'BlogsView'
        ? 'relative pt-10 overflow-hidden pb-10 h-full flex-grow -mt-10'
        : '',
    ]"
    :style="{
      background: $route.name === 'BlogsView' ? '#543687' : 'transparent',
    }"
  >
    <div
      v-if="$route.name === 'BlogsView'"
      class="absolute w-full h-full overflow-hidden"
      style="background-size: cover"
      :style="{
        backgroundImage: `url(${require(`@/assets/111.svg`)})`,
      }"
    >
      <svg
        class="w-full h-full"
        preserveAspectRatio="none"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 634.9 364.5"
        style="enable-background: new 0 0 634.9 364.5"
        xml:space="preserve"
      >
        <path
          fill="#391a6f"
          class="st0"
          d="M634.9,136.9c0-0.5,0-0.9,0-1.4c-3-0.7-5.7-2.2-8.6-3.4c-10.7-4.4-21.2-9.4-31.4-14.7
	c-8.2-4.2-16.3-8.5-24-13.7c-6-4-11.7-8.4-17.2-13c-4.6-3.8-9-7.8-13.4-11.9c-5.8-5.5-11.3-11.2-16.7-17.2
	c-3.3-3.6-6.6-7.2-9.9-10.8c-3.5-3.9-6.8-8-10.2-12c-4.6-5.4-9.2-10.9-13.8-16.4c-3.4-4.1-6.9-8.2-10.4-12.2
	c-2.7-3.1-5.5-6.1-8.2-9.2c-0.3-0.3-0.6-0.6-0.6-1c-0.2,0-0.3,0-0.5,0C412,0,354,0,295.9,0c-0.9,0-1.4,0.4-1.9,1
	c-2.8,4.4-5.7,8.8-8.1,13.4c-6,11.3-10.7,23.1-13.6,35.6c-1.8,7.6-2.9,15.3-3.5,23.1c-0.6,9.3-0.2,18.5,0.8,27.7
	c0.9,7.6,2.3,15.1,4.2,22.4c3.8,14.5,9.3,28.5,16.2,41.8c6,11.7,12.9,22.9,20.5,33.6c5.2,7.3,10.7,14.3,16.4,21.1
	c4.6,5.5,9.3,10.8,14.2,16c8.4,8.7,17,17.1,26.1,25.1c7.6,6.6,15.3,13,23.3,19.1c9.5,7.2,19.3,14,29.3,20.5
	c10,6.4,20.2,12.5,30.7,18.2c8.9,4.8,17.9,9.3,27.1,13.5c9.6,4.4,19.3,8.4,29.1,12c12.4,4.6,25,8.6,37.8,11.8
	c9.8,2.5,19.7,4.7,29.8,6.1c5.7,0.8,11.5,1.4,17.3,1.8c2.5,0.2,5.1,0,7.6,0.6c11.9,0,23.8,0,35.7,0c0-0.5,0-1,0-1.5
	C634.9,287.6,634.9,212.2,634.9,136.9z"
        />
        <path
          fill="#2c1456"
          class="st1"
          d="M468.4,0.1c0,0,55.3,100.9,166.5,136.9l0-136.9L468.4,0.1z"
        />
      </svg>
    </div>
    <div
      v-if="$route.name === 'BlogsView'"
      class="text-secondary text-center uppercase font-bold text-xl custom-font my-10 titleclass relative"
    >
      Bejegyzések
    </div>
    <div
      class="px-2 mb-5 mt-12 relative"
      v-if="$root.isAdmin && $route.name === 'BlogsView'"
    >
      <div class="mb-5">
        <label
          for="file-upload"
          class="cursor-pointer bg-secondary rounded-full uppercase px-5 py-2.5 text-lg text-primary mb-5 font-semibold"
        >
          <span v-if="!edit">{{
            file && file[0] ? file[0].name : "Kép feltöltése"
          }}</span>
          <span v-else>{{
            selectedItem && selectedItem.FileName
              ? selectedItem.FileName
              : "Kép feltöltése"
          }}</span>
        </label>
        <input
          v-on:change="onFileChange"
          ref="upload_file"
          id="file-upload"
          type="file"
          class="hidden"
          accept="image/*"
        />
      </div>
      <div class="mb-5 mt-7">
        <input
          v-model="editorTitle"
          class="rounded-lg w-full py-2 px-3"
          placeholder="Bejegyzés címe"
        />
      </div>
      <quill-editor :options="editorOption" v-model:value="editorContent" />
      <div class="mt-7">
        <button
          @click="addBlog()"
          class="cursor-pointer bg-secondary rounded-full uppercase px-5 py-2.5 text-lg text-primary mb-1 mt-2 font-semibold"
        >
          Bejegyzés feltöltése
        </button>
      </div>
      <div class="mt-7">
        <button
          @click="emptyBlog()"
          class="cursor-pointer bg-white rounded-full uppercase px-5 py-2 text-sm text-primary mb-5 font-semibold"
        >
          Üres sablon
        </button>
      </div>
    </div>
    <div class="grid gid-cols-1 lg:grid-cols-3 m-auto relative">
      <template v-if="$route.name === 'BlogsView'">
        <blog
          @update="get()"
          @editBlog="editItem"
          v-for="(item, i) in items"
          :key="i"
          :item="item"
        />
      </template>
      <template v-else>
        <blog v-for="(item, i) in items.slice(0, 3)" :key="i" :item="item" />
      </template>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Blog from "../views/Blog.vue";
import { axiosInstance } from "@/main";

import { quillEditor } from "vue3-quill";
//import customQuillModule from "customQuillModule";
//Quill.register("modules/customQuillModule", customQuillModule);
//import Icons from "../components/Icons.vue";
export default {
  name: "BlogsView",
  components: { Blog, quillEditor },
  data() {
    return {
      items: [],
      edit: false,
      editorContent: "",
      editorTitle: "",
      file: null,
      selectedItem: null,
      editorOption: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ color: [] }, { background: ["white"] }],
            ["link"],
          ],
        },
      },
    };
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files || e.dataTransfer.files;
    },
    editItem(item) {
      this.edit = true;
      this.selectedItem = item;
      this.editorContent = item.Text;
      this.editorTitle = item.Title;
      this.file = null;
    },
    emptyBlog() {
      this.edit = false;
      this.file = null;
      this.selectedItem = null;
      this.editorContent = "";
      this.editorTitle = "";
    },
    addBlog() {
      let this_ = this;

      let strippedString = this.editorContent.replace(/(<([^>]+)>)/gi, "");

      if (strippedString.length < 10) {
        alert("Írj tartalmat a bejegyzéshez! Minimum 10 karakter.");
      } else if (this.editorTitle.length < 10) {
        alert("Írj egy címet a bejegyzéshez! Minimum 10 karakter.");
      } else {
        //              datum a pickerből
        let o = {
          jwt: this_.$root.getCookie("jwt"),
          date: new Date(),
          text: this.editorContent,
          title: this.editorTitle,
        };
        this.dialog = false;
        if (this.edit && this.selectedItem && this.selectedItem.Id)
          o["id"] = this.selectedItem.Id;
        let form = new FormData();

        if (this.file !== null && this.file.length > 0) {
          form.append("talkImage", this.file[0]);
        }

        form.append("talkData", JSON.stringify(o));
        this.$root.loading = true;
        axiosInstance
          .post(
            `${this.$root.siteRoot}/backend/api/controller/talk/add.php`,
            form,
            {
              withCredentials: false,
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then(function (response) {
            console.log(response, this_);
            this_.get();
            this_.$root.loading = false;
            this_.edit = false;
            this_.selectedItem = null;
            this_.editorContent = "";
            this_.editorTitle = "";
            this_.file = null;
            this_.$root.showAlert();
            this.get();
            console.log(response);
          })
          .catch(function () {
            this_.$root.showAlert("error", "Feltöltés sikertelen");
            this_.$root.loading = false;
            //this_.$root.set_alert("error", "Sikertelen mentés." + error);
          });
      }
    },
    get() {
      let this_ = this;
      this.$root.loading = true;
      axiosInstance
        .post(
          `${this.$root.siteRoot}/backend/api/controller/talk/get_talk.php`,
          // "/create.php",
          {},
          { withCredentials: false }
        )
        .then(function (response) {
          console.log(response, this_);
          this_.items = response.data.msgList;
          this_.sortByDate();
          console.log(response);
          this_.$root.loading = false;
        })
        .catch(function () {
          this_.$root.loading = false;
          //this_.$root.set_alert("error", "Sikertelen mentés." + error);
        });
    },
    sortByDate() {
      this.items.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.DateNum) - new Date(a.DateNum);
      });
    },
  },
  created() {
    console.log("created");
    this.get();
    if (this.$route.name === "BlogsView") {
      window.scrollTo(0, 0);
    }
  },
};
</script>
