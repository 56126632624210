<template>
  <div class="absolute w-full h-full fill-current top-0">
    <div
      class="relative p-2 border-2 top-2 left-2"
      :class="['border-' + borderColor]"
      style="width: calc(100% - 1rem); height: calc(100% - 1rem)"
    >
      <svg
        v-if="showUp"
        class="w-9 absolute"
        style="top: -2px; left: -2px"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 56.5 56.3"
        xml:space="preserve"
      >
        <g>
          <g>
            <g>
              <rect
                x="16.4"
                y="-3.9"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.0293 18.3168)"
                class="st0"
                width="3.4"
                height="45.4"
              />
            </g>
            <g>
              <rect
                x="9.1"
                y="2.1"
                transform="matrix(0.9348 -0.3553 0.3553 0.9348 -8.0251 5.4297)"
                class="st0"
                width="3.4"
                height="44.9"
              />
            </g>
            <g>
              <rect
                x="22"
                y="-10.4"
                transform="matrix(0.4098 -0.9122 0.9122 0.4098 3.1102 28.6571)"
                class="st0"
                width="3.4"
                height="44.6"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            class="st0"
            d="M3.5,56.3c28.8-0.9,52.1-24.1,52.9-52.9l0,0L56.4,0h-3.3v1.7C53.1,30,30.1,53,1.8,53H0.1l0,3.4L3.5,56.3
		L3.5,56.3z"
          />
        </g>
      </svg>
      <svg
        v-if="showUp"
        class="w-9 right-0 top-0 absolute transform rotate-90"
        style="top: -2px; right: -2px"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 56.5 56.3"
        xml:space="preserve"
      >
        <g>
          <g>
            <g>
              <rect
                x="16.4"
                y="-3.9"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.0293 18.3168)"
                class="st0"
                width="3.4"
                height="45.4"
              />
            </g>
            <g>
              <rect
                x="9.1"
                y="2.1"
                transform="matrix(0.9348 -0.3553 0.3553 0.9348 -8.0251 5.4297)"
                class="st0"
                width="3.4"
                height="44.9"
              />
            </g>
            <g>
              <rect
                x="22"
                y="-10.4"
                transform="matrix(0.4098 -0.9122 0.9122 0.4098 3.1102 28.6571)"
                class="st0"
                width="3.4"
                height="44.6"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            class="st0"
            d="M3.5,56.3c28.8-0.9,52.1-24.1,52.9-52.9l0,0L56.4,0h-3.3v1.7C53.1,30,30.1,53,1.8,53H0.1l0,3.4L3.5,56.3
		L3.5,56.3z"
          />
        </g>
      </svg>
      <svg
        class="w-9 absolute transform -rotate-90"
        style="bottom: -2px; left: -2px"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 56.5 56.3"
        xml:space="preserve"
      >
        <g>
          <g>
            <g>
              <rect
                x="16.4"
                y="-3.9"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.0293 18.3168)"
                class="st0"
                width="3.4"
                height="45.4"
              />
            </g>
            <g>
              <rect
                x="9.1"
                y="2.1"
                transform="matrix(0.9348 -0.3553 0.3553 0.9348 -8.0251 5.4297)"
                class="st0"
                width="3.4"
                height="44.9"
              />
            </g>
            <g>
              <rect
                x="22"
                y="-10.4"
                transform="matrix(0.4098 -0.9122 0.9122 0.4098 3.1102 28.6571)"
                class="st0"
                width="3.4"
                height="44.6"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            class="st0"
            d="M3.5,56.3c28.8-0.9,52.1-24.1,52.9-52.9l0,0L56.4,0h-3.3v1.7C53.1,30,30.1,53,1.8,53H0.1l0,3.4L3.5,56.3
		L3.5,56.3z"
          />
        </g>
      </svg>
      <svg
        class="w-9 absolute transform rotate-180"
        style="bottom: -2px; right: -2px"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 56.5 56.3"
        xml:space="preserve"
      >
        <g>
          <g>
            <g>
              <rect
                x="16.4"
                y="-3.9"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.0293 18.3168)"
                class="st0"
                width="3.4"
                height="45.4"
              />
            </g>
            <g>
              <rect
                x="9.1"
                y="2.1"
                transform="matrix(0.9348 -0.3553 0.3553 0.9348 -8.0251 5.4297)"
                class="st0"
                width="3.4"
                height="44.9"
              />
            </g>
            <g>
              <rect
                x="22"
                y="-10.4"
                transform="matrix(0.4098 -0.9122 0.9122 0.4098 3.1102 28.6571)"
                class="st0"
                width="3.4"
                height="44.6"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            class="st0"
            d="M3.5,56.3c28.8-0.9,52.1-24.1,52.9-52.9l0,0L56.4,0h-3.3v1.7C53.1,30,30.1,53,1.8,53H0.1l0,3.4L3.5,56.3
		L3.5,56.3z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "CardCorner",
  components: {},
  props: {
    borderColor: {
      default: "primary",
    },
    showUp: {
      default: true,
    },
  },
};
</script>
