export default {
    1: {
        id:1,
      title: "3 alkalmas videóbeszélgetés / Energiafeltöltés",
      icon: "sun_point",
      price: "244$",
      description:"A csomag tartalma: három videóhívás, kártyavetés plusz energiafeltöltés.",
      feeds: [
        "Sok mindent megértettem a tanításaid által, s őszintén bevallom, nem gondoltam volna, hogy ennyire hatásosak az oldások, meditációk.",
        "Akkora erőt, hitet adtál, hogy el sem hiszed!",
      ],
      feltetelek:``
    },
    2: {
        id:2,
      title: "Éves előrejelzés videó",
      icon: "eye_play",
      price: "111$",
      description:'Kiváncsi vagy mi vár rád a követketkező 1 évben? Milyen helyzetekre számíthatsz és hogyan változtass az életeden, hogy elkerüld a nehézségeket? Ha igen, ez az ajánlat neked szól. Az év bármely szakaszában megrendelhető',
      feeds: [
        "Kedves Judit! Még év elején készítettél nekem elemzést.. Akkor megnéztem, azóta viszont nem, csak most, mielőtt írtam neked ezt a levelet. Döbbenten néztem újra az elemzést és szinte beleremegtem. Ez az év hihetetlenül gyorsan eltelt, és a velem történt események pontosan beeillenek az elemzésbe.. Köszönöm :-)",
        "Akkora erőt, hitet adtál, hogy el sem hiszed!",
      ],
      feltetelek:`  
   
      <p>Tarot és Angyal plusz Numerológia előrejelzés, Ahogy a személyre szabott elemzés videóim, úgy ez is egy kimondottan neked, személyedre és a te helyzetedre szóló előrejelzés az egész évre, hónapra lebontva. Minden területre kitér (párkapcsolat, család, karrier, egészség stb attól függően, hogy mit szeretnél tudni) úgy, amennyire egy 1-1,5 órás elemzés keretébe belefér.</p> `
    },
    3: {
        id:3,
      title: "Kártyavetés videó",
      icon: "card_play",
      price: "Rövid: £55, hosszú: £99",
      description:'Kártyavetés videó formájában rövid vagy hosszú elemzéssel',
      feeds: [
        "Kedves Judit! Még év elején készítettél nekem elemzést.. Akkor megnéztem, azóta viszont nem, csak most, mielőtt írtam neked ezt a levelet. Döbbenten néztem újra az elemzést és szinte beleremegtem. Ez az év hihetetlenül gyorsan eltelt, és a velem történt események pontosan beeillenek az elemzésbe.. Köszönöm :-)",
        "Akkora erőt, hitet adtál, hogy el sem hiszed!",
      ],
      feltetelek:`  
   
      <p><b>KÉRLEK, AZ ALÁBBIAKAT FIGYELMESEN OLVASD ÉS ANNAK ÉRDEKÉBEN, HOGY EGYMÁS DOLGÁT MEGKÖNNYÍTSÜK, TARTSD IS BE!</b></p> 
      
      <p><b>Az árakat azért fontban adtam meg</b>, mert Angliában élek. Akár Magyarországon, akár bárhol máshol élsz, nem gond, a Paypal átváltja, tehát nyugodtan küldheted forintban vagy bármi másban, ami neked a helyi pénz.
       A Paypallal gyorsan, ingyen és problémamentesen tudsz utalni! </p>
      
       <p>Ha megtörtént az utalás, akkor írj egy emailt <b>judit.rightquestion@gmail.com</b> címre nekem, DE:   FONTOS: <b>freemail, hotmail, citromail</b> fiókból NE írjatok! Gmail fiókotokból írjatok, ha nincs, akkor hozzatok létre egyet – a többi szolgálatóval csak a baj van sajnos! Gyakran előfordul, hogy emberek nem kapnak választ tőlem, mert egyszerűen nem jut el hozzájuk, hiszen a rendszer visszadobja! Ha gmailes fiókból írtok, azzal nem szokott gond lenni, legalábbis eddig soha nem volt. (Reméljük, ezután se lesz :-) )   
       <b>Az email tárgya legyen:</b> Személyre szabott videó </n>
       <b>A levélben pedig írd meg</b> nekem a teljes születési nevedet és a születési dátumodat, valamint a kérdésedet illetve problémádat röviden.  </p>   
      
      <p><b>EGY KÉRDÉS!!!</b> Kérlek, hogy egyszerre egy kérdést tegyél fel és <b>egyszerre egy területre kérdezz rá,</b> hiszen csak így lehet teljes választ adni, és így elkerülhetjük, hogy csak felületesen kapunk bele több témába is. Ezt az "ide is belekapunk, oda is belekapunk" -féle dolgot én nem csinálom, mert rendesen szeretek kifejteni egy-egy kérdést, hogy amit kapsz, az valóban segítsen! 
      Ha több életterületre is rá szeretnél kérdezni, akkor az már kettő vagy három elemzés!</p> 
       
      <p>(Ettől függetlenül nagyon sokszor van, hogy a kártyák érintenek több területet is, mivel minden mindennel összefügg, és ha a kártyák szólni akarnak, én nem korlátozom őket; de én ezt - hogy több területet is fogok érinteni - biztosra ígérni senkinek sem tudom.)</p> 
      
      <p><b>LEGALÁBB KÉT HÉT, DE ELŐFORDUL, HOGY KÉT HÉT IS MEGVAN,</b> MIRE VÁLASZOLNI TUDOK!!! Kérlek, vedd figyelembe, hogy ha meg is rendelted az elemzésedet, számítanod kell arra, hogy nem fogsz azonnal visszajelzést kapni tőlem. Nálam mindig rengetegen vannak a sorban, sokan várnak elemzésre, energiamunkára, plusz tanítványaim is vannak, így szinte soha nem tudok azonnal válaszolni. Hiába szeretnék sokan, hogy azonnal válaszoljak, ez – a sor hosszúsága miatt – fizikai képtelenség. De igyekszem, amennyire lehet  Senkit sem szeretek megvárakoztatni, és ha tehetném, nem is várakoztatnék soha senkit. Dehát ez sajnos lehetetlen. Úgyhogy előre is köszönöm a türelmedet! :-)</p>    
      
      <p><b>A “KÉTNAPONTA ÍROGATOK JUDITNAK,</b> HOGY ODAÉRT-E A MEGRENDELÉSEM” -féle emberek is kezdenek problémát jelenteni, egyrészt úgyse tudok nekik a fent említett időkeretnél hamarabb visszajelezni, másrészt csak telik az email fiókom ettől is (pláne így, hogy többnyire nem a már elküldött levél alatt írnak, hanem külön leveleket, így egy fokkal még zavaróbb). Ezzel azt érik el, hogy még tovább lassítják az egészet, megnehezítik a dolgomat és a többi ügyfélnek is egy kicsit többet kell várnia, mivel az érdemi munka helyett ez köti le a figyelmemet: a sok felesleges, türelmetlenkedő levél. </p>    
      
      <p><b>VISZONT HA KÉT HÉTEN BELÜL NEM VÁLASZOLNÉK,</b> akkor igenis írj rám – ne légy passzív, légy asszertív és ne hagyd, hogy esetleges technikai malőrök miatt elússzon az elemzésed! Mert bizony technikai hibák bárhol, bárkivel előfordulhatnak. Ha esetleg ilyen előfordul, hogy két hét után sem kapnál választ, akkor ha még egyszer rám írsz, és szólsz, az nekem is segítség, hiszen tudat alatt biztosan ott lesz bennem, hogy valami elmaradt, és nekem is jobb érzés, ha senki sem marad ki :-) </p>   
      <div class="flex justify-center mt-3"><form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top"><input name="cmd" type="hidden" value="_s-xclick">
<input name="hosted_button_id" type="hidden" value="DAYKP63ME4PSU">
<table >
<tbody>
<tr>
<td class="font-bold"><input class="font-bold" name="on0" type="hidden" value="RÖVID Elemzés Videó £55">RÖVID Elemzés Videó £55</td>
</tr>
<tr>
<td><input class="hidden" maxlength="200" name="os0" type="text"></td>
</tr>
</tbody>
</table>
<input alt="PayPal – The safer, easier way to pay online!" name="submit" src="https://www.paypalobjects.com/en_GB/i/btn/btn_paynowCC_LG.gif" type="image">
<img alt="" border="0" height="1" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1">

</form></div>
<div class="flex justify-center mt-5">
<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top"><input name="cmd" type="hidden" value="_s-xclick">
<input name="hosted_button_id" type="hidden" value="JPTVMXP7Q3PM6">
<table>
<tbody>
<tr>
<td class="font-bold"><input name="on0" type="hidden" value="HOSSZÚ Elemzés Videó £99">HOSSZÚ Elemzés Videó £99</td>
</tr>
<tr>
<td><input class="hidden" maxlength="200" name="os0" type="text"></td>
</tr>
</tbody>
</table>
<input alt="PayPal – The safer, easier way to pay online!" name="submit" src="https://www.paypalobjects.com/en_GB/i/btn/btn_paynowCC_LG.gif" type="image">
<img alt="" border="0" height="1" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1">

</form>
</div>
`
    },
    4: {
        id:4,
      title: "Email kártyavetés",
      description:'Kártyavetés emailben, rövid vagy hosszú elemzéssel',
      icon: "eye_envelope",
      price: "55$",
      feeds: [
        "A munkád fantasztikus! Nagyon tetszik az elemzésed, úgy érzem, teljesen fedi a valóságot, minden úgy van bennem és körülöttem, ahogyan megfofalmaztad.",
      ],
    },
   
    5: {
        id:5,
      title: "Személyes találkozó",
      icon: "private",
      price: "£188",
      description:`Személyre szabott elemzés, személyes találkozó keretein belül`,
      feeds: [
        "A munkád fantasztikus! Nagyon tetszik az elemzésed, úgy érzem, teljesen fedi a valóságot, minden úgy van bennem és körülöttem, ahogyan megfofalmaztad.",
      ],
      img:'private_talk.jpg',
      feltetelek:`<p>Mivel rengetegen kérdezik, újra meg újra, ezért úgy döntöttem, adok erre lehetőséget - ha te is ezt szeretnéd, olvass tovább figyelmesen.</p>

      <p>Azt már sokan tudjátok, hogy aki nem ott - vagy a környéken - él, ahol én, az is kaphat személyre szabott kártyavetést és egyéb segítséget, mivel a munkám 99%-át amúgy is online végzem.</p>

      <p>VSZONT SOKAN A SZEMÉLYES TALÁLKOZÁST PREFERÁLNÁK. Megértem. Annak idején, sok éve nekem is nagy élmény volt, amikor személyesen találkoztam egy jósnővel és ott a szemem előtt rakta ki nekem a kártyákat...</p>
      
      <p>Azt is tudjátok már sokan - aki még nem, annak most mondom - hogy rengetegen keresnek, "kilométer hosszú" a sor nálam mindig. Ezen kívül tanítványaim is vannak, úgyhogy 100%-ig be vagyok táblázva.</p>
      
      <p>A másik aspektusa a dolognak az, hogy amit csinálok, az igen nagy mértékben hatással van sok ember életére, sőt nem túlzás azt mondani, hogy időnként életeket mentek.</p>
      
      <p>Éppen ezért nem vehetem könnyedén ezt a dolgot. Pláne így, hogy nekem feladatom a PÉLDAMUTATÁS is. Főként jós, gyógyító, tanító, spirituális segítő kollégák felé kell példát mutatnom abban, hogy becsüljék nagyra magukat; de nemcsak feléjük, hanem végsősoron mindenki felé, aki rám talál.</p>
      
      Úgyhogy ebben a szellemben cselekszem. Tehát: LEHET VELEM TALÁLKOZNI, DE: ez kizárólag azoknak szól, akik komolyan gondolják, hogy válaszokat akarnak kapni és hajlandók az igazsággal szembenézni, és ha kell, változtatni az életükön. Csak azoknak szól, akik maguk is becsülik saját magukat és engem is tisztelettel közelítenek meg, és HAJLANDÓK ÖNMAGUKBA FEKTETNI - ezt a szolgáltatás ára is tükrözi.</p>
      
      <p class="text-secondary font-bold">Tehát, ha szeretnél velem egy személyes találkozót, a következő a teendőd: </p>
      
      <p>1. Először is dobj egy emailt a <span class="text-secondary">judit.rightquestion@gmail.com</span> címre és kérdezz rá, hogy van-e egyáltalán elérhető időpontom a következő hetekben </p>
      
      <p>2. Ha a válasz igen, és rögzítettünk egy időpontot, akkor küldd el a találkozó díját - £188 - a következő linken: <a href="https://PayPal.Me/JuditAntal" class="text-secondary">PayPal.Me/JuditAntal</a> </p>
      
      <p>(Ha PayPal fiók létrehozásához segítségre van szükséged, ide kattints: <a class="text-secondary" href="http://online-fizetes-magyarul.info/paypal/">http://online-fizetes-magyarul.info/paypal/ </a>)</p>`
    },
    6: {
        id:6,
      title: "Skype",
      icon: "video_talk",
      price: "£244",
      feeds: [
        "A munkád fantasztikus! Nagyon tetszik az elemzésed, úgy érzem, teljesen fedi a valóságot, minden úgy van bennem és körülöttem, ahogyan megfofalmaztad.",
      ],
      feltetelek:`<p>SZEMÉLYRE SZABOTT KÁRTYAVETÉS / TAROT COACHING program VIDEÓBESZÉLGETÉS FORMÁJÁBAN IS elérhető. Sokan kérdezitek, azért írom ki. Ennek feltételeit megtudod, ha tovább olvasol figyelmesen.</p>

      <p>Amint azt tudjátok már sokan - aki még nem, annak most mondom - hogy rengetegen keresnek, "kilométer hosszú" a sor nálam mindig. Ezen kívül tanítványaim is vannak, úgyhogy 100%-ig be vagyok táblázva.</p>
      
      <p>A másik: amit csinálok, az igen nagy mértékben hatással van sok ember életére, sőt nem túlzás azt mondani, hogy időnként életeket mentek.</p>
      
      <p>Éppen ezért nem vehetem könnyedén ezt a dolgot. Pláne így, hogy nekem feladatom a PÉLDAMUTATÁS is. Főként jós, gyógyító, tanító, spirituális segítő kollégák felé kell példát mutatnom abban, hogy becsüljék nagyra magukat; de nemcsak feléjük, hanem végsősoron mindenki felé, aki rám talál.</p>
      
      <p>Úgyhogy ebben a szellemben cselekszem. Tehát: TUDSZ VELEM VIDEÓN BESZÉLNI, DE: ez kizárólag azoknak szól, akik komolyan gondolják, hogy válaszokat akarnak kapni és hajlandók az igazsággal szembenézni, és ha kell, változtatni az életükön; és akik meg is fogadják a tanácsokat! Csak azoknak szól, akik maguk is becsülik saját magukat és engem is tisztelettel közelítenek meg, és HAJLANDÓK ÖNMAGUKBA FEKTETNI - ezt a program ára és időtartama is tükrözi (a program három kártyavetés alkalomból áll, egyenként kb egy-másfél órás alkalmak!).</p>
      
      <p>Tehát, ha szeretnéd ezt az online "találkozó" sorozatot, a következő a teendőd:</p>
      
      <p>1. Először is dobj egy emailt a judit.rightquestion@gmail.com címre és kérdezz rá, hogy vannak-e elérhető időpontjaim a következő hetekben</p>
      
      <p>2. Ha a válasz igen, és rögzítettünk legalább egy időpontot, akkor küldd el a videóbeszélgetés/kártyavetés sorozat díját - £244 - a következő linken: PayPal.Me/JuditAntal (a másik két időpontot ráérünk később is rögzíteni)</p>
      
      <p>(Az utalást PayPal fiók nélkül - vendégként - is meg tudod tenni; de ha szeretnél fiókot létrehozni, azt is megteheted; ha ezzel gondjaid akadnak, segítség itt: http://online-fizetes-magyarul.info/paypal/)</p>`
      
    },
    7: {
      id:7,
    title: "Taroszkóp plusz",
    icon: "video_talk",
    price: "£2-£44",
    description:`TAROSZKÓP PLUSZ, avagy a szerelmi Taroszkóp visszatér; továbbá karrier-, pénzügyi, hivatás- és életút-taroszkóp stb és majd még meglátjuk, mi minden lesz ;)`,
    feeds: [
      "NAGY VAGY. Sokszor aon gondolkozom, biztos van egy távcsöved vagy egy kamerád amivel az életemet látod. Köszönet és hála neked <3",
    ],
    feltetelek:`<p>A következőképp részesülhetsz benne:</p>

    <p>Létrehoztam egy interaktív* csoportot, amely a TÁMOGATÓK SZÁMÁRA létezik. Ha tagja leszel, onnantól minden hónapban sok-sok kérdésedre választ kaphatsz a kártyák segítségével.</p>
   
   <p>A támogatás lehetősége a következő: több lehetőség is van, ezek közül mindenki maga választja ki, a saját adottsága szerint. Így az adni és kapni törvénye harmonikusan érvényesül, és az anyagi áldás is el tud kezdeni áramlani az életedben - hiszen, ahogy azt már sokan tudjátok, az áramlás csak úgy tud elindulni, ha te magad is elkezdesz adni ott, ahol kapsz. Ezt most ily módon sikerült megoldani :-) </p>
   
   <p class="text-secondary uppercase font-bold">A teendőd:</p>

   <p>Az alábbi négy összeg* (2, 5, 22, 44) közül válaszd ki a neked megfelelőt és küld el a <a class="text-secondary" href="https://PayPal.Me/JuditAntal">PayPal.Me/JuditAntal</a> linken keresztül minden hónapban. VAGY ha egy ötödik összeget választanál, akkor is! (Egyébként ott, a PayPalon - a saját PayPal fiókodon belül - is be tudod állítani, hogy automatikusan vonja tőled havonta). majd írj Viktornak itt: <span class="text-secondary">viktor.judit.iroda@gmail.com</span> és jelentkezz a csoportban ide kattintva: <a class="text-secondary" href="https://www.facebook.com/groups/230729034614413/">Taroszkóp Plusz csoport</a></p>
   
   
    `
   
   
  },
  }