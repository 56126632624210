<template>
  <div
    class="flex relative min-h-screen"
    style="background-size: cover; background: #543687"
  >
    <div
      class="absolute w-full h-full overflow-hidden"
      style="background-size: cover"
      :style="{
        backgroundImage: `url(${require(`@/assets/111.svg`)})`,
      }"
    >
      <svg
        class="w-full"
        version="1.1"
        id="Réteg_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 634.9 364.5"
        style="enable-background: new 0 0 634.9 364.5"
        xml:space="preserve"
      >
        <path
          fill="#391a6f"
          class="st0"
          d="M634.9,136.9c0-0.5,0-0.9,0-1.4c-3-0.7-5.7-2.2-8.6-3.4c-10.7-4.4-21.2-9.4-31.4-14.7
	c-8.2-4.2-16.3-8.5-24-13.7c-6-4-11.7-8.4-17.2-13c-4.6-3.8-9-7.8-13.4-11.9c-5.8-5.5-11.3-11.2-16.7-17.2
	c-3.3-3.6-6.6-7.2-9.9-10.8c-3.5-3.9-6.8-8-10.2-12c-4.6-5.4-9.2-10.9-13.8-16.4c-3.4-4.1-6.9-8.2-10.4-12.2
	c-2.7-3.1-5.5-6.1-8.2-9.2c-0.3-0.3-0.6-0.6-0.6-1c-0.2,0-0.3,0-0.5,0C412,0,354,0,295.9,0c-0.9,0-1.4,0.4-1.9,1
	c-2.8,4.4-5.7,8.8-8.1,13.4c-6,11.3-10.7,23.1-13.6,35.6c-1.8,7.6-2.9,15.3-3.5,23.1c-0.6,9.3-0.2,18.5,0.8,27.7
	c0.9,7.6,2.3,15.1,4.2,22.4c3.8,14.5,9.3,28.5,16.2,41.8c6,11.7,12.9,22.9,20.5,33.6c5.2,7.3,10.7,14.3,16.4,21.1
	c4.6,5.5,9.3,10.8,14.2,16c8.4,8.7,17,17.1,26.1,25.1c7.6,6.6,15.3,13,23.3,19.1c9.5,7.2,19.3,14,29.3,20.5
	c10,6.4,20.2,12.5,30.7,18.2c8.9,4.8,17.9,9.3,27.1,13.5c9.6,4.4,19.3,8.4,29.1,12c12.4,4.6,25,8.6,37.8,11.8
	c9.8,2.5,19.7,4.7,29.8,6.1c5.7,0.8,11.5,1.4,17.3,1.8c2.5,0.2,5.1,0,7.6,0.6c11.9,0,23.8,0,35.7,0c0-0.5,0-1,0-1.5
	C634.9,287.6,634.9,212.2,634.9,136.9z"
        />
        <path
          fill="#2c1456"
          class="st1"
          d="M468.4,0.1c0,0,55.3,100.9,166.5,136.9l0-136.9L468.4,0.1z"
        />
      </svg>
    </div>
    <div
      class="container m-auto overflow-hidden relative mt-0 lg:mt-16 my-16 rounded-lg"
      style="max-width: 1200px"
    >
      <div class="w-full">
        <img
          v-if="services[$route.params.id].img"
          :src="require(`@/assets/${services[$route.params.id].img.trim()}`)"
          class="w-full object-cover"
        />
        <img v-else src="@/assets/cards.jpg" class="w-full object-cover" />
      </div>
      <div
        class="w-full text-secondary flex items-end flex-col justify-center text-right px-3 lg:px-10 bg-primary py-10"
      >
        <div class="text-center w-full">
          <div class="flex items-center justify-center px-10">
            <div class="w-26 max-w-full">
              <icons :iconName="services[$route.params.id].icon" />
            </div>
          </div>
          <div class="uppercase font-bold custom-font text-xl mb-4 mt-8">
            {{ services[$route.params.id].title }}
          </div>
          <div class="uppercase font-bold text-lg mb-6">
            {{ services[$route.params.id].price }}
          </div>
          <div class="text-md text-white mt-2 mb-4">
            <div
              class="lg:text-lg text-opacity-90"
              v-if="services[$route.params.id].description"
              v-html="services[$route.params.id].description"
            ></div>
            <div v-else>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </div>
          </div>
        </div>
        <div
          class="text-white text-center text-sm lg:text-base lg:mt-5 bold-secondary opacity-90"
          v-html="services[$route.params.id].feltetelek"
        ></div>
        <div class="text-white text-sm text-center w-full">
          <span class="text-secondary">FELTÉTELEK:</span> A megrendeléssel
          IGAZOLOD, HOGY A FELTÉTELEKKEL EGYETÉRTESZ, ezeket
          <div>
            <button
              @click="$router.push('/terms')"
              class="bg-secondary rounded-lg text-primary px-2 py-0.5 uppercase text-sm font-bold mx-1"
            >
              itt
            </button>
            olvashatod
          </div>
        </div>
        <div class="flex justify-center w-full">
          <div
            class="flex flex-col gap-4 mt-6 mx-auto"
            style="max-width: 900px"
          >
            <div
              class="flex gap-1.5"
              v-for="(feed, i) in services[$route.params.id].feeds"
              :key="i"
            >
              <svg
                class="w-12 fill-current text-secondary flex-none"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 29 29"
              >
                <path
                  d="M14.5 2A12.514 12.514 0 0 0 2 14.5 12.521 12.521 0 0 0 14.5 27a12.5 12.5 0 0 0 0-25Zm7.603 19.713a8.48 8.48 0 0 0-15.199.008A10.367 10.367 0 0 1 4 14.5a10.5 10.5 0 0 1 21 0 10.368 10.368 0 0 1-2.897 7.213ZM14.5 7a4.5 4.5 0 1 0 4.5 4.5A4.5 4.5 0 0 0 14.5 7Z"
                />
              </svg>
              <div
                class="text-white text-left px-4 text-sm lg:text-base py-3 bg-white rounded-xl bg-opacity-30"
              >
                {{ feed }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/*import CardCorner from "../components/CardCorner.vue";*/
import Icons from "../components/Icons.vue";
import servicesData from "@/assets/services/services.js";

export default {
  name: "serviceView",
  components: { Icons },
  data() {
    return {
      services: servicesData,
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
