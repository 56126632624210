<template>
  <div
    class="w-full fill-current"
    :class="['text-' + color]"
    v-html="icons[iconName]"
  ></div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "icons_",
  props: {
    iconName: {
      default: "eye_envelope",
    },
    color: {
      default: "secondary",
    },
  },
  components: {},
  data() {
    return {
      icons: {
        video_talk: `<svg version="1.1" class="w-full" id="Réteg_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 270.1 254.7" style="enable-background:new 0 0 270.1 254.7;" xml:space="preserve">

<path class="st0" d="M99.3,76.2c-3.2,0-12.9-1.4-17.7-3c-5.3-1.7-11.2-9-14.3-15.4c-1.2-2.4-5.6-5.2-10.3-8.3
	c-6-3.9-12.8-8.2-17.4-14.1l2.4-1.9c4.3,5.5,10.9,9.7,16.7,13.4c5.5,3.5,9.8,6.3,11.3,9.5c2.8,5.7,8.2,12.5,12.5,13.9
	c4.7,1.5,14.6,2.9,17,2.8c1.9-0.1,5.5-0.4,6.9-0.5c0.7-0.1,1.4-0.2,2.1-0.4l1.7-0.4l0.2,0c1.5,0.1,3.1,0.1,3.5,0
	c0.9-0.3,4.2-1.3,4.2-1.3l0.4-0.1l0.4,0.1c0.1,0,9.7,2.1,13.4,2.1c1.3,0,2.7,0.1,4.2,0.2c3,0.2,6.4,0.4,8,0c0.9-0.2,3.8-2,4.3-4.1
	c0.7-3.1-0.3-4-0.6-4.3c-0.7-0.6-5-1.9-8-2.4c-1.4-0.2-3.7-1-5.9-1.7c-1.8-0.6-3.8-1.2-4.5-1.3c-0.5,0-1.2-0.1-2.1-0.1
	c-5.6-0.2-17.2-0.5-28.7-7.4l1.6-2.6c10.8,6.5,21.9,6.8,27.2,7c1,0,1.7,0.1,2.3,0.1c1,0.1,2.9,0.7,5.2,1.4c2,0.6,4.2,1.4,5.4,1.5
	c2,0.3,8,1.6,9.6,3.2c1.7,1.6,2.2,4,1.4,7.2c-0.9,3.6-5.1,6-6.6,6.4c-2.1,0.4-5.5,0.3-8.8,0.1c-1.5-0.1-2.9-0.2-4.1-0.2
	c-3.5,0-11.7-1.7-13.6-2.1c-0.9,0.3-3.2,1-4,1.2c-1,0.3-3.3,0.2-4.2,0.1l-1.2,0.3c-0.8,0.2-1.7,0.4-2.6,0.5
	c-1.8,0.2-5.2,0.5-7.1,0.6C99.5,76.2,99.4,76.2,99.3,76.2z"/>
<path class="st0" d="M192.5,74c-1.3,0-2.6-0.2-4.2-0.7c-4.9-1.5-7.3-2.9-8.7-3.7c-0.7-0.4-1.2-0.7-1.5-0.7c-0.2,0-0.6-0.1-1-0.1
	c-2.7-0.3-8.2-0.8-12.5-2.8c-0.4-0.2-0.9-0.4-1.2-0.7c-1.7,1.5-5.5,4-12.4,3.6l0.2-3c7.8,0.4,10.7-3.3,10.8-3.4l0.9-1.2l1.2,0.8
	c0,0,0.9,0.7,1.9,1.1c3.8,1.8,9,2.3,11.5,2.6c0.4,0,0.8,0.1,1.1,0.1c1,0.1,1.7,0.6,2.7,1.1c1.4,0.8,3.5,2,8.1,3.4
	c5.6,1.7,8.3-1,9.3-2.6c-1.9-1.5-5.4-3.9-7.7-4.6c-1.8-0.5-3.4-1.4-5.1-2.3c-2.1-1.2-4.5-2.5-7.9-3.5c-3.5-1-6.4-2.4-8.7-3.5
	c-1.7-0.8-3.3-1.6-4.2-1.7c-1.8-0.2-8.1,0.2-11.5,0.4c-1.1,0.1-1.9,0.1-2.4,0.2c-1.5,0.1-4-1.2-8.1-3.2c-2.3-1.2-4.6-2.4-5.8-2.7
	c-2-0.5-3-0.5-4.8-0.5c-0.7,0-1.5,0-2.5,0c-2.1-0.1-9.9-1.8-20.7-4.2c-5.8-1.3-11.3-2.5-13-2.8c-4.7-0.7-15.6-8.5-23.3-16.8
	c-3.7-4-10.9-12.1-17.4-21l2.4-1.8c6.4,8.8,13.5,16.8,17.2,20.7c7.6,8.2,18,15.4,21.5,15.9c1.8,0.2,7.1,1.4,13.2,2.8
	c8.1,1.8,18.2,4,20.1,4.1c0.9,0,1.7,0,2.4,0c1.9,0,3.2,0,5.5,0.6c1.5,0.4,3.9,1.6,6.4,2.9c2.3,1.2,5.7,2.9,6.6,2.9
	c0.5,0,1.3-0.1,2.3-0.1c5-0.3,10.1-0.7,12-0.4c1.4,0.2,3,1,5.1,2c2.2,1.1,5,2.4,8.3,3.4c3.7,1.1,6.4,2.5,8.5,3.7
	c1.6,0.9,3,1.7,4.4,2.1c3.9,1.1,9.5,5.7,9.8,5.9l0.8,0.7l-0.4,1C200.8,70.3,197.8,74,192.5,74z"/>
<path class="st0" d="M187.5,102.9c-1.6,0-7.3-0.3-9.7-4.5c-3.1-5.4-3.2-12.8-3.1-15.5c-1.5-0.9-4.6-3.1-7.1-6.1
	c-1.9-2.3-2.7-4.8-3.1-6.4c-2.6,0.3-7.9,0.6-11.6-1.8l1.7-2.5c3.9,2.6,10.8,1.2,10.8,1.2l1.7-0.3l0.1,1.7c0,0,0.3,3.4,2.7,6.3
	c2.9,3.5,7,5.8,7,5.9l0.8,0.5l-0.1,1c0,0.1-0.7,8.9,2.6,14.6c1.2,2.1,4.1,2.8,5.9,3c-0.3-4.1-1.1-14.7-1.5-17.7
	c-0.5-2.9-4-10.3-5.4-13l2.7-1.4c0.2,0.4,5,9.9,5.6,13.8c0.6,3.8,1.7,19,1.7,19.6l0.1,1.5l-1.5,0.1
	C187.9,102.9,187.8,102.9,187.5,102.9z"/>
<path class="st0" d="M171.5,105.1l-1.5-0.4c-3.1-0.9-8.8-4.3-8.3-10.8c0.4-4.9,1.6-7.9,2.3-9.5c-1.3-1.4-3.6-4.6-4.3-10.1
	c-0.2-1.8,0.1-5.3,0.1-5.7l3,0.2c-0.1,1-0.3,3.8-0.1,5.1c0.8,6.5,3.9,8.9,4.1,9l1.2,0.9l-0.9,1.2c0,0-2,2.7-2.4,9
	c-0.3,4,2.8,6.1,4.7,7.1c1-4.1,3.4-14.5,3.8-16.5c0.3-2.1,0.2-2.9,0.2-3l2.9-0.9c0.1,0.3,0.4,1.5-0.1,4.3
	c-0.4,2.7-4.1,17.8-4.2,18.4L171.5,105.1z"/>
<path class="st0" d="M152.5,99.9l-1.1-0.2c-0.3-0.1-2.5-0.6-4-3.1c-1.4-2.3-1.5-5.5-0.3-9.3c0.9-3.1,2.3-5.3,3.2-6.5
	c-1.3-1.6-3.1-5.1-2.3-10.3l3,0.5c-0.9,5.8,2.3,8.6,2.3,8.7l1.3,1.1l-1.2,1.1c0,0-2.1,2-3.3,6.2c-0.9,3-0.9,5.3,0,6.9
	c0.3,0.6,0.7,1,1.1,1.2c2.8-5,7.2-13,7.9-14.6c0.6-1.4,0.8-5.3,0.7-7.4l3-0.1c0,0.6,0.1,6.3-1,8.7c-1,2.2-8.4,15.5-8.7,16
	L152.5,99.9z"/>
<path class="st1" d="M118.6,149.1c-1.8,0-3.4-0.4-4.9-1.3c-3.4-2-5.3-6-5.3-11.2v-35.8c0-5.3,1.9-9.3,5.3-11.2c3.4-2,7.8-1.6,12.4,1
	l31,17.9c4.6,2.6,7.1,6.3,7.1,10.2c0,4-2.5,7.6-7.1,10.2l-31,17.9C123.5,148.3,120.9,149.1,118.6,149.1z M118.6,92.2
	c-1.1,0-2,0.2-2.9,0.7c-2.1,1.2-3.3,4-3.3,7.8v35.8c0,3.8,1.2,6.5,3.3,7.8c2.1,1.2,5.1,0.9,8.4-1l31-17.9c3.3-1.9,5.1-4.3,5.1-6.8
	s-1.8-4.9-5.1-6.8l-31-17.9C122.1,92.8,120.2,92.2,118.6,92.2z"/>
<path class="st0" d="M228.3,220.6c-4.3-5.5-10.9-9.7-16.7-13.4c-5.5-3.5-9.8-6.3-11.3-9.5c-2.8-5.7-8.2-12.5-12.5-13.9
	c-4.7-1.5-14.7-2.9-17-2.8c-1.8,0.1-5.1,0.4-6.9,0.5c-0.7,0.1-1.4,0.2-2.1,0.4l-1.7,0.4l-0.2,0c-1.5-0.1-3.1-0.1-3.5,0
	c-0.9,0.3-4.2,1.3-4.2,1.3l-0.4,0.1l-0.4-0.1c-0.1,0-9.7-2.1-13.4-2.1c-1.3,0-2.7-0.1-4.2-0.2c-3-0.2-6.4-0.4-8,0
	c-0.9,0.2-3.8,2-4.3,4.1c-0.7,3.1,0.3,4,0.6,4.3c0.7,0.6,5,1.9,8,2.4c1.4,0.2,3.7,1,5.9,1.7c1.8,0.6,3.8,1.2,4.5,1.3
	c0.5,0,1.2,0.1,2.1,0.1c5.6,0.2,17.2,0.5,28.7,7.4l-1.6,2.6c-10.8-6.5-21.9-6.8-27.2-7c-1,0-1.7-0.1-2.3-0.1c-1-0.1-2.9-0.7-5.2-1.4
	c-2-0.6-4.2-1.4-5.4-1.5c-2-0.3-8-1.6-9.6-3.2c-1.7-1.6-2.2-4-1.4-7.2c0.9-3.6,5.1-6,6.6-6.4c2.1-0.4,5.5-0.3,8.8-0.1
	c1.5,0.1,2.9,0.2,4.1,0.2c3.5,0,11.7,1.7,13.6,2.1c0.9-0.3,3.2-1,4-1.2c1-0.3,3.3-0.2,4.2-0.1l1.2-0.3c0.8-0.2,1.7-0.4,2.6-0.5
	c2.3-0.2,5.4-0.5,7.1-0.6c2.9-0.1,13,1.3,18,3c5.3,1.7,11.2,9,14.3,15.4c1.2,2.4,5.6,5.2,10.3,8.3c6,3.9,12.8,8.2,17.4,14.1
	L228.3,220.6z"/>
<path class="st0" d="M212.3,254.3c-6.4-8.8-13.5-16.8-17.2-20.7c-7.6-8.2-18-15.4-21.5-15.9c-1.8-0.2-7.1-1.4-13.2-2.8
	c-8.1-1.8-18.1-4-20.1-4.1c-0.9,0-1.7,0-2.4,0c-1.9,0-3.2,0-5.5-0.6c-1.5-0.4-3.9-1.6-6.4-2.9c-2.3-1.2-5.7-2.9-6.6-2.9
	c-0.5,0-1.3,0.1-2.3,0.1c-5,0.3-10.1,0.7-12,0.4c-1.4-0.2-3-1-5.1-2c-2.2-1.1-5-2.4-8.3-3.4c-3.7-1.1-6.4-2.5-8.5-3.7
	c-1.6-0.9-3-1.7-4.4-2.1c-3.9-1.1-9.5-5.7-9.8-5.9l-0.8-0.7l0.4-1c1.1-2.9,5.4-7.8,13.5-5.3c4.9,1.5,7.3,2.9,8.7,3.7
	c0.7,0.4,1.2,0.7,1.5,0.7c0.2,0,0.6,0.1,1,0.1c2.7,0.3,8.2,0.8,12.5,2.8c0.4,0.2,0.9,0.4,1.2,0.7c1.7-1.5,5.5-4,12.4-3.6l-0.2,3
	c-7.8-0.4-10.7,3.3-10.8,3.4l-0.9,1.2l-1.2-0.8c0,0-0.9-0.7-1.9-1.1c-3.8-1.8-9-2.3-11.5-2.6c-0.4,0-0.8-0.1-1.1-0.1
	c-1-0.1-1.7-0.6-2.7-1.1c-1.4-0.8-3.5-2-8.1-3.4c-5.6-1.7-8.3,1-9.3,2.6c1.9,1.5,5.4,3.9,7.7,4.6c1.8,0.5,3.4,1.4,5.1,2.3
	c2.1,1.2,4.5,2.5,7.9,3.5c3.5,1,6.4,2.4,8.7,3.5c1.7,0.8,3.3,1.6,4.2,1.7c1.8,0.2,8.1-0.2,11.5-0.4c1.1-0.1,1.9-0.1,2.4-0.2
	c1.5-0.1,4,1.2,8.1,3.2c2.3,1.2,4.6,2.4,5.8,2.7c2,0.5,3,0.5,4.8,0.5c0.7,0,1.5,0,2.5,0c2.1,0.1,9.9,1.8,20.7,4.2
	c5.8,1.3,11.3,2.5,13,2.8c4.7,0.7,15.6,8.5,23.3,16.8c3.7,4,10.9,12.1,17.4,21L212.3,254.3z"/>
<path class="st0" d="M115.6,188.1c-3.9-2.6-10.8-1.2-10.8-1.2l-1.7,0.3l-0.1-1.7c0,0-0.3-3.4-2.7-6.3c-2.9-3.5-7-5.8-7-5.9l-0.8-0.5
	l0.1-1c0-0.1,0.7-8.9-2.6-14.6c-1.2-2.1-4.1-2.8-5.9-3c0.3,4.1,1.1,14.7,1.5,17.7c0.5,2.9,4,10.3,5.4,13l-2.7,1.4
	c-0.2-0.4-5-9.9-5.6-13.8c-0.6-3.8-1.7-19-1.7-19.6l-0.1-1.5l1.5-0.1c0.3,0,7.3-0.3,10.2,4.5c3.1,5.4,3.2,12.8,3.1,15.5
	c1.5,0.9,4.6,3.1,7.1,6.1c1.9,2.3,2.7,4.8,3.1,6.4c2.6-0.3,7.9-0.6,11.6,1.8L115.6,188.1z"/>
<path class="st0" d="M110.4,185.5l-3-0.3c0.1-1,0.3-3.8,0.1-5.1c-0.8-6.5-3.9-8.9-4.1-9l-1.2-0.9l0.9-1.2c0,0,2-2.7,2.4-9
	c0.3-4-2.8-6.1-4.7-7.1c-1,4.1-3.4,14.4-3.8,16.5c-0.3,2.1-0.2,2.9-0.2,2.9l-2.9,0.9c-0.1-0.3-0.4-1.5,0.1-4.3
	c0.4-2.7,4.1-17.8,4.2-18.4l0.4-1.5l1.5,0.4c3.1,0.9,8.8,4.3,8.3,10.8c-0.4,4.9-1.6,7.9-2.3,9.5c1.3,1.4,3.6,4.6,4.3,10.1
	C110.7,181.6,110.4,185.2,110.4,185.5z"/>
<path class="st0" d="M122.2,183.8l-3-0.5c0.9-5.8-2.3-8.6-2.3-8.7l-1.3-1.1l1.2-1.1c0,0,2.1-2,3.3-6.2c0.9-3,0.9-5.3,0-6.9
	c-0.3-0.6-0.7-1-1.1-1.2c-2.8,5-7.2,13-7.9,14.6c-0.6,1.4-0.8,5.3-0.7,7.4l-3,0.1c0-0.6-0.1-6.3,1-8.7c1-2.2,8.4-15.5,8.7-16l0.5-1
	l1.1,0.2c0.3,0.1,2.5,0.6,4,3.1c1.4,2.3,1.5,5.5,0.3,9.3c-0.9,3.1-2.3,5.3-3.2,6.5C121.2,175.1,123,178.5,122.2,183.8z"/>
<g>
	<path class="st0" d="M131.3,183.2c-0.1,0-3.4-0.9-4.8-0.4c-1.4,0.5-2.6,1.5-3,2.8c-0.3,1.1-0.4,2.8,0.7,3.4c1,0.6,3.2,1.6,5,1.8
		c1.8,0.2,3.3,0.3,4.4-0.7c0.7-0.7,1.2-2.1,1.1-3.3c-0.1-1.2-0.7-2.4-1.7-3C132.6,183.6,131.3,183.2,131.3,183.2z"/>
	<path class="st0" d="M117.9,157.1c0,0,2.1,2.7,1,5c-1.3,2.7-2.7,3.2-2.7,3.2l-2.6-1.5L117.9,157.1z"/>
	<path class="st0" d="M99.4,152.3l-2.1,8.6l2.6,0.5c0,0,1.5-0.9,1.9-4.2C102.2,154.4,99.4,152.3,99.4,152.3z"/>
	<path class="st0" d="M82.3,153.9l0.7,9.5l2.6-0.4c0,0,1.2-2.5,0.5-5.1C85.4,154.9,82.3,153.9,82.3,153.9z"/>
	<path class="st0" d="M69.9,187.7l8.1,5l0.9-1.9c0,0-1.3-2.4-3.8-3.4C72.1,186.1,69.9,187.7,69.9,187.7z"/>
</g>
<path class="st0" d="M139,71c0.1,0,3.4,0.9,4.8,0.4c1.4-0.5,2.6-1.5,3-2.8c0.3-1.1,0.4-2.8-0.7-3.4c-1-0.6-3.2-1.6-5-1.8
	c-1.8-0.2-3.3-0.3-4.4,0.7c-0.7,0.7-1.2,2.1-1.1,3.3c0.1,1.2,0.7,2.4,1.7,3C137.7,70.6,139,71,139,71z"/>
<path class="st0" d="M152.3,97.1c0,0-2.1-2.7-1-5c1.3-2.7,2.7-3.2,2.7-3.2l2.6,1.5L152.3,97.1z"/>
<path class="st0" d="M170.8,101.9l2.1-8.6l-2.6-0.5c0,0-1.5,0.9-1.9,4.2C168,99.9,170.8,101.9,170.8,101.9z"/>
<path class="st0" d="M188,100.3l-0.7-9.5l-2.6,0.4c0,0-1.2,2.5-0.5,5.1C184.8,99.3,188,100.3,188,100.3z"/>
<path class="st0" d="M200.4,66.6l-8.1-5l-0.9,1.9c0,0,1.3,2.4,3.8,3.4C198.1,68.2,200.4,66.6,200.4,66.6z"/>
</svg>`,
        private: `<svg version="1.1" class="w-full" id="Réteg_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 270.1 254.7" style="enable-background:new 0 0 270.1 254.7;" xml:space="preserve">

<g>
	<g>
		<path class="st0" d="M214.2,254.5H53.6V81c0-44.3,36-80.3,80.3-80.3c44.3,0,80.3,36,80.3,80.3V254.5z M56.6,251.5h154.5V81
			c0-42.6-34.7-77.3-77.3-77.3C91.3,3.7,56.6,38.3,56.6,81V251.5z"/>
		<path class="st0" d="M214.7,255H53.1V81c0-44.6,36.2-80.8,80.8-80.8c44.6,0,80.8,36.2,80.8,80.8V255z M54.1,254h159.6V81
			c0-44-35.8-79.8-79.8-79.8S54.1,37,54.1,81V254z M211.7,252H56.1V81c0-42.9,34.9-77.8,77.8-77.8c42.9,0,77.8,34.9,77.8,77.8V252z
			 M57.1,251h153.5V81c0-42.3-34.4-76.8-76.8-76.8C91.6,4.2,57.1,38.6,57.1,81V251z"/>
	</g>
	<path class="st0" d="M214.2,238.6H84.8v-18.9h129.4V238.6z M87.8,235.6h123.4v-12.8H87.8V235.6z"/>
	<path class="st0" d="M214.2,254.5H70.3v-18.9h143.9V254.5z M73.4,251.5h137.8v-12.8H73.4V251.5z"/>
	<path class="st0" d="M214.2,222.8H101.9v-18.9h112.3V222.8z M104.9,219.8h106.3v-12.8H104.9V219.8z"/>
	<path class="st0" d="M214.2,206.9h-97.7V188h97.7V206.9z M119.6,203.9h91.6v-12.8h-91.6V203.9z"/>
	<path class="st0" d="M194.8,191.1h-58.3v-61.9c0-16.1,13.1-29.2,29.2-29.2s29.2,13.1,29.2,29.2V191.1z M139.5,188h52.3v-58.9
		c0-14.4-11.7-26.1-26.1-26.1c-14.4,0-26.1,11.7-26.1,26.1V188z"/>
	<path class="st0" d="M103.8,69c-5.4,0-10.8-2.1-15-6.2c-8.2-8.2-8.3-21.4-0.2-29.5c3.2-3.2,7.3-5.2,11.8-5.8l4.8-0.6l-3.6,3.3
		c-0.1,0.1-0.3,0.3-0.4,0.4c-5.4,5.4-5.3,14.2,0.2,19.7c5.5,5.5,14.3,5.6,19.7,0.2c0.1-0.1,0.3-0.3,0.4-0.4l3.2-3.6l-0.6,4.8
		c-0.6,4.5-2.6,8.6-5.8,11.8C114.3,67,109.1,69,103.8,69z M96.6,31.6c-2.2,0.9-4.1,2.2-5.8,3.9c-3.3,3.3-5.1,7.8-5.1,12.6
		c0,4.8,1.9,9.3,5.3,12.7c7,7,18.3,7.1,25.2,0.2c1.7-1.7,3-3.7,3.9-5.8c-6.5,3.9-15.1,3-20.8-2.7C93.6,46.7,92.7,38.1,96.6,31.6z"/>
	<polygon class="st0" points="181.5,78.9 175.4,82.9 177.4,75.9 171.6,71.3 179,71 181.5,64.1 184,71 191.4,71.3 185.6,75.9 
		187.6,82.9 	"/>
	<polygon class="st0" points="70.5,129.3 66.7,131.9 67.9,127.5 64.3,124.6 68.9,124.5 70.5,120.2 72,124.5 76.6,124.6 73,127.5 
		74.2,131.9 	"/>
	<path class="st0" d="M147.8,30.6c-2.4-2.4-2.4-6.2,0-8.6c2.4-2.4,6.2-2.4,8.6,0c2.4,2.4,2.4,6.2,0,8.6C154,33,150.1,33,147.8,30.6z
		"/>
	<circle class="st0" cx="122.6" cy="159.3" r="6"/>
</g>
</svg>`,
        card_play: `<svg version="1.1" class="w-full" id="Réteg_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 270.1 254.7" style="enable-background:new 0 0 270.1 254.7;" xml:space="preserve">

<path class="st0" d="M150.5,151.8c-0.9,1.3-1.9,2.3-3.3,3c-3.1,1.6-7,1.1-10.8-1.4l-26.5-17c-3.9-2.5-6-5.8-5.8-9.3
	c0.1-3.5,2.5-6.5,6.6-8.7l28-14.4c4.1-2.2,8-2.3,10.9-0.4c3,1.9,4.4,5.5,4.2,10.1l-1.5,31.4C152.2,147.8,151.6,150.1,150.5,151.8z
	 M108.4,124.7c-0.5,0.8-0.8,1.6-0.9,2.5c-0.1,2.1,1.4,4.3,4.2,6.2l26.5,17c2.8,1.8,5.4,2.2,7.3,1.3c1.9-1,3.1-3.3,3.3-6.7l1.5-31.4
	c0.2-3.3-0.8-5.8-2.6-7c-1.8-1.2-4.5-1-7.5,0.5l-28,14.4C110.5,122.4,109.2,123.6,108.4,124.7z"/>
<path class="st1" d="M179,253.6c-1.9,0-3.8-0.6-5.4-1.7L5.7,134.4c-4.3-3-5.3-8.9-2.3-13.2L84.7,4.8c1.5-2.1,3.6-3.5,6.1-3.9
	c2.5-0.4,5,0.1,7.1,1.6L265.8,120c2.1,1.5,3.5,3.6,3.9,6.1s-0.1,5-1.6,7.1l-81.4,116.3c-1.5,2.1-3.6,3.5-6.1,3.9
	C180.1,253.5,179.5,253.6,179,253.6z M87.2,6.6L5.8,122.9c-2.1,2.9-1.3,7,1.6,9.1l167.8,117.5c1.4,1,3.1,1.4,4.9,1.1
	c1.7-0.3,3.2-1.3,4.2-2.7l81.4-116.3c1-1.4,1.4-3.1,1.1-4.9c-0.3-1.7-1.3-3.2-2.7-4.2L96.2,5c-1.4-1-3.1-1.4-4.9-1.1
	S88.2,5.1,87.2,6.6L87.2,6.6z"/>
<g>
	<path class="st1" d="M178.4,244.4c-0.9,0-1.9-0.3-2.7-0.9l-163-114.1c-2.1-1.5-2.7-4.4-1.2-6.6L89.2,12c0.7-1,1.8-1.7,3.1-1.9
		c1.2-0.2,2.5,0.1,3.5,0.8l163,114.1c2.1,1.5,2.7,4.4,1.2,6.6l-77.7,111C181.4,243.7,179.9,244.4,178.4,244.4z M93.1,11.7
		c-0.2,0-0.3,0-0.5,0c-0.8,0.1-1.4,0.6-1.9,1.2L13,123.9c-0.4,0.6-0.6,1.4-0.5,2.2c0.1,0.8,0.6,1.4,1.2,1.9l163,114.1
		c1.3,0.9,3.2,0.6,4.1-0.7l77.7-111c0.9-1.3,0.6-3.2-0.7-4.1L94.8,12.3C94.3,11.9,93.7,11.7,93.1,11.7z"/>
</g>
<g>
	<g>
		<path class="st1" d="M171.9,154.1L171.9,154.1L171.9,154.1c-0.1,0.1-0.2,0.1-0.3,0.1l0,0c-0.1-0.1-0.1-0.2-0.1-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3-0.1l0,0C172,153.8,172,154,171.9,154.1L171.9,154.1L171.9,154.1z M172.2,153.4c-0.1-0.1-0.1-0.2-0.1-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0l0,0l0,0C172.4,153.4,172.3,153.4,172.2,153.4z M171.4,154.9
			c-0.1,0.1-0.2,0.1-0.3,0.1l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0C171.4,154.6,171.5,154.8,171.4,154.9
			L171.4,154.9L171.4,154.9z M173,152.5c-0.1,0.1-0.2,0.1-0.3,0.1l0,0c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0
			C173.1,152.2,173.1,152.4,173,152.5L173,152.5L173,152.5z M170.8,155.7c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3,0l0,0C170.8,155.4,170.9,155.5,170.8,155.7L170.8,155.7C170.8,155.7,170.8,155.7,170.8,155.7z M173.2,151.7
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0l0,0l0,0
			C173.5,151.8,173.3,151.8,173.2,151.7z M170.2,156.4c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3,0l0,0C170.2,156.2,170.3,156.3,170.2,156.4L170.2,156.4L170.2,156.4z M173.7,150.9c-0.1-0.1-0.2-0.2-0.1-0.3
			l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0l0,0l0,0c0,0,0,0,0,0l0,0C174,150.9,173.8,151,173.7,150.9z
			 M169.6,157.2c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0
			C169.6,156.9,169.6,157.1,169.6,157.2L169.6,157.2C169.6,157.2,169.6,157.2,169.6,157.2z M174.2,150.1c-0.1-0.1-0.2-0.2-0.1-0.3
			l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C174.5,150.1,174.3,150.1,174.2,150.1z
			 M168.9,157.9c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C169,157.7,169,157.8,168.9,157.9
			L168.9,157.9C168.9,157.9,168.9,157.9,168.9,157.9z M174.7,149.2c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C174.9,149.2,174.8,149.3,174.7,149.2z M168.3,158.7c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C168.3,158.4,168.4,158.5,168.3,158.7L168.3,158.7
			C168.3,158.6,168.3,158.7,168.3,158.7z M175.2,148.3c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C175.4,148.4,175.3,148.4,175.2,148.3z M167.6,159.4L167.6,159.4L167.6,159.4
			c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C167.7,159.1,167.7,159.3,167.6,159.4
			L167.6,159.4C167.6,159.4,167.6,159.4,167.6,159.4z M175.6,147.5c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C175.8,147.5,175.7,147.5,175.6,147.5z M166.9,160.1L166.9,160.1L166.9,160.1
			c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C167,159.8,167,159.9,166.9,160.1L166.9,160.1
			C166.9,160.1,166.9,160.1,166.9,160.1z M176,146.6c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0C176.2,146.6,176.1,146.6,176,146.6z M166.2,160.8
			C166.2,160.8,166.2,160.8,166.2,160.8L166.2,160.8c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.4,0
			l0,0C166.3,160.5,166.3,160.6,166.2,160.8L166.2,160.8C166.2,160.7,166.2,160.8,166.2,160.8z M176.4,145.7
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0,0.2,0.2,0.1,0.3l0,0l0,0l0,0c0,0,0,0,0,0l0,0
			C176.6,145.7,176.5,145.7,176.4,145.7z M165.5,161.4c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0
			c0.1-0.1,0.3-0.1,0.3,0l0,0C165.6,161.2,165.6,161.3,165.5,161.4L165.5,161.4C165.5,161.4,165.5,161.4,165.5,161.4z M176.8,144.8
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0,0.2,0.2,0.1,0.3l0,0l0,0l0,0c0,0,0,0,0,0l0,0
			C177,144.8,176.9,144.8,176.8,144.8z M164.8,162.1c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0
			l0,0C164.9,161.8,164.9,162,164.8,162.1L164.8,162.1C164.8,162.1,164.8,162.1,164.8,162.1z M177.1,143.9c-0.1-0.1-0.2-0.2-0.1-0.3
			l0,0c0-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0l0,0l0,0c0,0,0,0,0,0.1l0,0C177.4,143.9,177.2,143.9,177.1,143.9z
			 M164,162.7c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C164.1,162.5,164.1,162.6,164,162.7
			L164,162.7C164.1,162.7,164,162.7,164,162.7z M177.5,143c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0,0.2,0.2,0.2,0.3l0,0l0,0l0,0c0,0,0,0,0,0.1l0,0C177.7,143,177.6,143,177.5,143z M163.3,163.3c-0.1,0.1-0.3,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C163.4,163.1,163.4,163.2,163.3,163.3L163.3,163.3
			C163.3,163.3,163.3,163.3,163.3,163.3z M177.8,142.1c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3
			l0,0c0,0,0,0,0,0.1l0,0C178,142.1,177.9,142.1,177.8,142.1z M162.5,163.9c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0.1-0.3
			l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C162.6,163.7,162.6,163.8,162.5,163.9L162.5,163.9C162.5,163.9,162.5,163.9,162.5,163.9z
			 M178.1,141.1c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0,0,0.1l0,0
			C178.3,141.1,178.2,141.2,178.1,141.1z M161.7,164.5L161.7,164.5L161.7,164.5c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3
			l0,0c0.1-0.1,0.3-0.1,0.3,0.1l0,0C161.8,164.3,161.8,164.4,161.7,164.5L161.7,164.5C161.8,164.5,161.7,164.5,161.7,164.5z
			 M178.4,140.2c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0l0,0l0,0c0,0,0,0.1,0,0.1l0,0
			C178.6,140.2,178.5,140.2,178.4,140.2z M160.9,165.1L160.9,165.1L160.9,165.1c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c-0.1-0.1-0.1-0.3,0.1-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0.1l0,0C161,164.9,161,165,160.9,165.1L160.9,165.1
			C161,165.1,160.9,165.1,160.9,165.1z M178.6,139.3c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3
			l0,0c0,0,0,0,0,0l0,0c0,0,0,0.1,0,0.1l0,0C178.8,139.2,178.7,139.3,178.6,139.3z M160.1,165.7c-0.1,0.1-0.3,0.1-0.3-0.1l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C160.2,165.4,160.2,165.5,160.1,165.7L160.1,165.7
			C160.2,165.6,160.1,165.7,160.1,165.7z M178.9,138.3c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3
			l0,0c0,0,0,0,0,0l0,0c0,0,0,0.1,0,0.1l0,0C179.1,138.3,179,138.3,178.9,138.3z M159.3,166.2c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C159.4,166,159.4,166.1,159.3,166.2L159.3,166.2
			C159.3,166.2,159.3,166.2,159.3,166.2z M179.1,137.4c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3
			l0,0l0,0l0,0c0,0,0,0.1,0,0.1l0,0C179.3,137.4,179.2,137.4,179.1,137.4z M158.5,166.7C158.5,166.7,158.5,166.7,158.5,166.7
			L158.5,166.7c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0
			C158.6,166.5,158.6,166.6,158.5,166.7L158.5,166.7C158.5,166.7,158.5,166.7,158.5,166.7z M179.3,136.4c-0.1,0-0.2-0.2-0.2-0.3l0,0
			c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C179.5,136.4,179.4,136.4,179.3,136.4z M157.6,167.2
			c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C157.8,167,157.8,167.1,157.6,167.2
			L157.6,167.2C157.7,167.2,157.7,167.2,157.6,167.2z M179.5,135.5c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0
			c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C179.7,135.4,179.6,135.5,179.5,135.5z M156.8,167.7c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C156.9,167.5,156.9,167.6,156.8,167.7L156.8,167.7
			C156.8,167.7,156.8,167.7,156.8,167.7z M179.6,134.5c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3
			l0,0l0,0l0,0c0,0,0,0.1,0,0.1l0,0C179.8,134.5,179.7,134.5,179.6,134.5z M155.9,168.2c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C156.1,168,156,168.1,155.9,168.2L155.9,168.2
			C156,168.2,155.9,168.2,155.9,168.2z M179.8,133.5c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3
			l0,0c0,0,0,0.1,0,0.1l0,0C179.9,133.5,179.9,133.6,179.8,133.5z M155,168.6c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3
			l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C155.2,168.4,155.2,168.5,155,168.6L155,168.6C155.1,168.6,155.1,168.6,155,168.6z M179.9,132.6
			c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0l0,0l0,0c0,0,0,0.1,0,0.1l0,0
			C180,132.6,180,132.6,179.9,132.6z M154.2,169.1c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1
			l0,0C154.3,168.8,154.3,168.9,154.2,169.1L154.2,169.1C154.2,169,154.2,169.1,154.2,169.1z M180,131.6c-0.1,0-0.2-0.1-0.2-0.3l0,0
			c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C180.1,131.6,180.1,131.6,180,131.6z M153.3,169.5
			L153.3,169.5L153.3,169.5c-0.1,0.1-0.3,0-0.3-0.1l0,0c0-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0
			C153.4,169.2,153.4,169.3,153.3,169.5L153.3,169.5C153.3,169.4,153.3,169.5,153.3,169.5z M180,130.6c-0.1,0-0.2-0.1-0.2-0.3l0,0
			c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0l0,0l0,0c0,0,0,0.1,0,0.1l0,0C180.2,130.6,180.1,130.6,180,130.6z
			 M152.4,169.9c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0c0,0.1,0,0.2,0,0.2l0,0
			C152.4,169.8,152.4,169.9,152.4,169.9z M180.1,129.7c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3
			l0,0c0,0,0,0.1,0,0.1l0,0C180.3,129.6,180.2,129.7,180.1,129.7z M151.5,170.2c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3
			l0,0c0.1,0,0.3,0,0.3,0.1l0,0c0,0.1,0,0.2,0,0.2l0,0C151.5,170.2,151.5,170.2,151.5,170.2z M180.1,128.7c-0.1,0-0.2-0.1-0.2-0.3
			l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0l0,0l0,0c0,0,0,0.1,0,0.1l0,0C180.3,128.7,180.2,128.7,180.1,128.7z
			 M150.5,170.6c-0.1,0-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0c0,0.1,0,0.2,0,0.2l0,0
			C150.6,170.6,150.6,170.6,150.5,170.6z M180.1,127.7c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2l0,0c0.1,0,0.2,0.1,0.2,0.2
			l0,0l0,0l0,0c0,0,0,0.1,0,0.1l0,0C180.3,127.7,180.2,127.7,180.1,127.7z M149.6,170.9c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c0-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.2,0,0.2l0,0C149.7,170.9,149.7,170.9,149.6,170.9z M180.1,126.8
			c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.3l0,0c0.1,0,0.2,0.1,0.2,0.2l0,0c0,0,0,0,0,0l0,0c0,0,0,0.1,0,0.1l0,0
			C180.3,126.7,180.2,126.8,180.1,126.8z M148.7,171.2L148.7,171.2L148.7,171.2c-0.1,0-0.3,0-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3l0,0
			c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.2,0,0.2l0,0C148.8,171.2,148.7,171.2,148.7,171.2z M180.1,125.8c-0.1,0-0.2-0.1-0.3-0.2l0,0
			c0-0.1,0.1-0.2,0.2-0.3l0,0c0.1,0,0.2,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C180.3,125.7,180.2,125.8,180.1,125.8z M147.8,171.5
			c-0.1,0-0.3,0-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C147.9,171.5,147.8,171.5,147.8,171.5z M180.1,124.8c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.2,0.1,0.3,0.2
			l0,0c0,0,0,0,0,0l0,0c0,0.1,0,0.1,0,0.1l0,0C180.2,124.8,180.1,124.8,180.1,124.8z M146.8,171.8c-0.1,0-0.3,0-0.3-0.2l0,0
			c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C146.9,171.8,146.9,171.8,146.8,171.8z M180,123.8
			c-0.1,0-0.2-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C180.1,123.8,180.1,123.8,180,123.8z M145.9,172.1c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0
			c0,0.1,0,0.1,0,0.2l0,0C146,172,145.9,172.1,145.9,172.1z M179.9,122.9c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0
			c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C180,122.8,180,122.9,179.9,122.9z M144.9,172.3L144.9,172.3L144.9,172.3
			c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C145,172.3,145,172.3,144.9,172.3z M179.8,121.9c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0
			l0,0l0,0c0,0.1,0,0.1,0,0.2l0,0C179.9,121.9,179.9,121.9,179.8,121.9z M144,172.5c-0.1,0-0.3-0.1-0.3-0.2l0,0
			c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C144.1,172.5,144,172.5,144,172.5z M179.7,120.9
			c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0l0,0l0,0c0,0.1,0,0.1,0,0.2l0,0
			C179.8,120.9,179.7,120.9,179.7,120.9z M143,172.7c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2
			l0,0c0,0.1,0,0.1,0,0.2l0,0C143.1,172.7,143.1,172.7,143,172.7z M179.5,120c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0
			c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0,0,0,0,0l0,0c0,0.1,0,0.1,0,0.2l0,0C179.6,119.9,179.6,120,179.5,120z M142,172.9L142,172.9
			L142,172.9c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C142.2,172.8,142.1,172.9,142,172.9z M179.3,119c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0
			c0,0.1,0,0.1,0,0.2l0,0C179.4,119,179.4,119,179.3,119z M141.1,173c-0.1,0-0.2-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0
			c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C141.2,173,141.2,173,141.1,173z M179.1,118.1c-0.1,0-0.3-0.1-0.3-0.2l0,0
			c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0l0,0l0,0c0,0.1,0,0.1,0,0.2l0,0C179.3,118,179.2,118.1,179.1,118.1z
			 M140.1,173.2L140.1,173.2L140.1,173.2c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0
			c0,0.1,0,0.1,0,0.2l0,0C140.2,173.1,140.2,173.2,140.1,173.2z M178.9,117.1c-0.1,0-0.3,0-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0
			c0.1,0,0.3,0.1,0.3,0.2l0,0l0,0l0,0c0,0.1,0,0.1,0,0.2l0,0C179,117.1,179,117.1,178.9,117.1z M139.1,173.3c-0.1,0-0.3-0.1-0.3-0.2
			l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C139.3,173.2,139.2,173.3,139.1,173.3z
			 M178.7,116.2c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C178.8,116.1,178.8,116.2,178.7,116.2z M138.2,173.4L138.2,173.4L138.2,173.4c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3
			l0,0c0.1,0,0.2,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C138.3,173.3,138.2,173.4,138.2,173.4z M178.5,115.2c-0.1,0-0.3,0-0.3-0.2
			l0,0c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C178.5,115.2,178.5,115.2,178.5,115.2z M137.2,173.4
			L137.2,173.4L137.2,173.4c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.2,0.2-0.3l0,0c0.1,0,0.2,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.1l0,0
			C137.3,173.4,137.3,173.4,137.2,173.4z M178.2,114.3c-0.1,0-0.3,0-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0
			c0,0.1,0,0.2,0,0.2l0,0C178.3,114.2,178.2,114.3,178.2,114.3z M136.2,173.5c-0.1,0-0.2-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0
			c0.1,0,0.2,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.1l0,0C136.4,173.4,136.3,173.5,136.2,173.5z M177.9,113.4c-0.1,0-0.3,0-0.3-0.2l0,0
			c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.2,0,0.2l0,0C178,113.3,177.9,113.4,177.9,113.4z M135.2,173.5
			c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.3l0,0c0.1,0,0.2,0.1,0.2,0.2l0,0c0,0.1,0,0.1,0,0.1l0,0
			C135.4,173.5,135.3,173.5,135.2,173.5z M177.6,112.4c-0.1,0-0.3,0-0.3-0.1l0,0c0-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0
			c0,0.1,0,0.2,0,0.2l0,0C177.7,112.4,177.6,112.4,177.6,112.4z M134.3,173.5c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0
			c0.1,0,0.2,0.1,0.2,0.2l0,0c0,0,0,0.1,0,0.1l0,0C134.4,173.5,134.3,173.5,134.3,173.5z M177.2,111.5c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c0-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0c0,0,0,0,0,0l0,0c0,0.1,0,0.2,0,0.2l0,0C177.3,111.5,177.3,111.5,177.2,111.5z
			 M133.3,173.5L133.3,173.5L133.3,173.5c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2l0,0c0.1,0,0.2,0.1,0.2,0.2l0,0
			c0,0,0,0.1,0,0.1l0,0C133.4,173.5,133.4,173.5,133.3,173.5z M176.9,110.6c-0.1,0.1-0.3,0-0.3-0.1l0,0c0-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0c0,0.1,0,0.2,0,0.2l0,0C177,110.6,176.9,110.6,176.9,110.6z M132.3,173.5c-0.1,0-0.2-0.1-0.2-0.3l0,0
			c0-0.1,0.1-0.2,0.2-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0.1,0,0.1,0,0.1l0,0C132.5,173.4,132.4,173.5,132.3,173.5z M176.5,109.7
			c-0.1,0.1-0.3,0-0.3-0.1l0,0c0-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C176.7,109.5,176.7,109.6,176.5,109.7L176.5,109.7
			C176.6,109.7,176.6,109.7,176.5,109.7z M131.3,173.4L131.3,173.4L131.3,173.4c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2
			l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0.1,0,0.1,0,0.1l0,0C131.5,173.4,131.4,173.4,131.3,173.4z M176.1,108.8c-0.1,0.1-0.3,0-0.3-0.1
			l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0l0,0l0,0c0,0.1,0,0.2,0,0.2l0,0C176.2,108.8,176.2,108.8,176.1,108.8z
			 M130.4,173.4L130.4,173.4L130.4,173.4c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0
			c0,0,0,0.1,0,0.1l0,0C130.5,173.3,130.4,173.4,130.4,173.4z M175.7,107.9c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0l0,0l0,0C175.9,107.7,175.9,107.8,175.7,107.9L175.7,107.9C175.8,107.9,175.8,107.9,175.7,107.9z
			 M129.4,173.3c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C129.6,173.2,129.5,173.3,129.4,173.3z M175.3,107.1c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0l0,0l0,0C175.4,106.8,175.4,106.9,175.3,107.1L175.3,107.1C175.4,107,175.3,107,175.3,107.1z
			 M128.4,173.1L128.4,173.1L128.4,173.1c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0
			c0,0,0,0.1,0,0.1l0,0C128.6,173.1,128.5,173.2,128.4,173.1z M174.9,106.2c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0l0,0l0,0C175,105.9,175,106,174.9,106.2L174.9,106.2C174.9,106.2,174.9,106.2,174.9,106.2z M127.4,173
			C127.4,173,127.4,173,127.4,173L127.4,173c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0
			c0,0,0,0.1,0,0.1l0,0C127.6,173,127.5,173,127.4,173z M174.4,105.3c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0C174.5,105.1,174.5,105.2,174.4,105.3L174.4,105.3C174.4,105.3,174.4,105.3,174.4,105.3z M126.5,172.8
			L126.5,172.8L126.5,172.8c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C126.7,172.8,126.6,172.9,126.5,172.8z M173.9,104.5c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0C174,104.2,174,104.3,173.9,104.5L173.9,104.5C174,104.4,173.9,104.5,173.9,104.5z M125.5,172.7
			c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C125.7,172.6,125.6,172.7,125.5,172.7z M173.4,103.6c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0C173.5,103.4,173.5,103.5,173.4,103.6L173.4,103.6C173.5,103.6,173.4,103.6,173.4,103.6z M124.6,172.5
			L124.6,172.5L124.6,172.5c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C124.8,172.4,124.7,172.5,124.6,172.5z M172.9,102.8c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0C173,102.6,173,102.7,172.9,102.8L172.9,102.8C172.9,102.8,172.9,102.8,172.9,102.8z M123.6,172.3
			c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C123.8,172.2,123.7,172.3,123.6,172.3z M172.3,102c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1
			l0,0l0,0l0,0C172.5,101.8,172.5,101.9,172.3,102L172.3,102C172.4,102,172.4,102,172.3,102z M122.7,172c-0.1,0-0.2-0.2-0.2-0.3l0,0
			c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C122.9,172,122.8,172,122.7,172z M171.8,101.2
			c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0.1l0,0C171.9,101,171.9,101.1,171.8,101.2
			L171.8,101.2C171.8,101.2,171.8,101.2,171.8,101.2z M121.7,171.7c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0
			c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C121.9,171.7,121.8,171.8,121.7,171.7z M171.2,100.4c-0.1,0.1-0.3,0.1-0.3-0.1l0,0
			c-0.1-0.1-0.1-0.3,0.1-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0.1l0,0C171.3,100.2,171.3,100.3,171.2,100.4L171.2,100.4
			C171.3,100.4,171.3,100.4,171.2,100.4z M120.8,171.5c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3
			l0,0c0,0,0,0,0,0.1l0,0C121,171.5,120.9,171.5,120.8,171.5z M170.6,99.6c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0.1-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3,0l0,0C170.7,99.4,170.7,99.5,170.6,99.6L170.6,99.6C170.7,99.6,170.7,99.6,170.6,99.6z M119.9,171.2
			c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0,0,0.1l0,0
			C120.1,171.2,120,171.2,119.9,171.2z M170,98.9c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0
			C170.1,98.6,170.1,98.7,170,98.9L170,98.9C170.1,98.8,170,98.8,170,98.9z M118.9,170.8L118.9,170.8L118.9,170.8
			c-0.1,0-0.2-0.2-0.1-0.3l0,0c0-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0,0,0.1l0,0
			C119.2,170.9,119,170.9,118.9,170.8z M169.4,98.1c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0
			l0,0l0,0l0,0C169.5,97.9,169.5,98,169.4,98.1L169.4,98.1C169.4,98.1,169.4,98.1,169.4,98.1z M118,170.5c-0.1,0-0.2-0.2-0.1-0.3
			l0,0c0-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0.1l0,0C118.2,170.5,118.1,170.6,118,170.5z M168.8,97.4
			c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0l0,0l0,0C168.9,97.1,168.9,97.2,168.8,97.4
			L168.8,97.4C168.8,97.3,168.8,97.4,168.8,97.4z M117.1,170.2c-0.1,0-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C117.3,170.2,117.2,170.2,117.1,170.2z M168.1,96.6c-0.1,0.1-0.3,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.4,0l0,0C168.2,96.4,168.2,96.5,168.1,96.6L168.1,96.6
			C168.1,96.6,168.1,96.6,168.1,96.6z M116.2,169.8L116.2,169.8L116.2,169.8c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1
			l0,0c0.1,0,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C116.4,169.8,116.3,169.8,116.2,169.8z M167.5,95.9c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0l0,0l0,0C167.5,95.7,167.6,95.8,167.5,95.9L167.5,95.9
			C167.5,95.9,167.5,95.9,167.5,95.9z M115.3,169.4c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C115.5,169.4,115.4,169.4,115.3,169.4z M166.8,95.2c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0l0,0l0,0C166.9,95,166.9,95.1,166.8,95.2L166.8,95.2
			C166.8,95.2,166.8,95.2,166.8,95.2z M114.4,169L114.4,169L114.4,169c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C114.7,169,114.5,169,114.4,169z M166.1,94.6c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C166.2,94.3,166.2,94.4,166.1,94.6L166.1,94.6
			C166.1,94.5,166.1,94.6,166.1,94.6z M113.5,168.5c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C113.8,168.5,113.7,168.6,113.5,168.5z M165.4,93.9c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C165.4,93.6,165.5,93.8,165.4,93.9L165.4,93.9
			C165.4,93.9,165.4,93.9,165.4,93.9z M112.7,168.1L112.7,168.1L112.7,168.1c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1
			l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C112.9,168.1,112.8,168.1,112.7,168.1z M164.6,93.3c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0l0,0l0,0C164.7,93,164.7,93.1,164.6,93.3L164.6,93.3
			C164.7,93.2,164.7,93.2,164.6,93.3z M111.8,167.6c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C112.1,167.6,111.9,167.7,111.8,167.6z M163.9,92.6c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.2,0-0.4l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0l0,0l0,0C164,92.3,164,92.5,163.9,92.6L163.9,92.6
			C163.9,92.6,163.9,92.6,163.9,92.6z M111,167.1c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C111.2,167.1,111.1,167.2,111,167.1z M163.2,92c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0l0,0l0,0C163.2,91.7,163.2,91.9,163.2,92L163.2,92
			C163.2,92,163.2,92,163.2,92z M110.1,166.6c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3
			l0,0c0,0,0,0,0,0l0,0C110.4,166.6,110.2,166.7,110.1,166.6z M162.4,91.4c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3,0l0,0C162.5,91.1,162.5,91.3,162.4,91.4L162.4,91.4L162.4,91.4z M109.3,166.1
			C109.3,166.1,109.3,166.1,109.3,166.1L109.3,166.1c-0.1-0.1-0.1-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0
			c0.1,0.1,0.1,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C109.6,166.1,109.4,166.1,109.3,166.1z M161.6,90.8c-0.1,0.1-0.2,0.1-0.3,0.1l0,0
			c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C161.7,90.5,161.7,90.7,161.6,90.8L161.6,90.8L161.6,90.8z M108.5,165.5
			c-0.1-0.1-0.1-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0
			C108.8,165.6,108.6,165.6,108.5,165.5z M160.5,90.3C160.5,90.3,160.5,90.3,160.5,90.3L160.5,90.3
			C160.4,90.3,160.4,90.2,160.5,90.3L160.5,90.3c-0.2-0.1-0.2-0.3-0.1-0.4l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0,0,0,0,0,0l0,0
			c0,0,0,0,0,0l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0C160.7,90.3,160.6,90.4,160.5,90.3z M107.7,165c-0.1-0.1-0.1-0.2-0.1-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0C108,165,107.8,165,107.7,165z M159.6,89.7c-0.1-0.1-0.2-0.2-0.1-0.3
			l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C159.9,89.7,159.7,89.8,159.6,89.7z M107.2,164.3
			c-0.1,0.1-0.2,0.1-0.3,0l0,0l0,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C107.3,164.1,107.3,164.2,107.2,164.3
			L107.2,164.3C107.3,164.3,107.3,164.3,107.2,164.3z M158.8,89.2c-0.1-0.1-0.1-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0
			c0,0,0,0,0,0l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C159.1,89.2,158.9,89.2,158.8,89.2z M106.5,163.7
			c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C106.5,163.5,106.5,163.6,106.5,163.7
			L106.5,163.7L106.5,163.7z M158,88.7c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0l0,0l0,0c0.1,0.1,0.1,0.2,0.1,0.3
			l0,0c0,0,0,0,0,0l0,0C158.2,88.7,158.1,88.7,158,88.7z M105.7,163.1c-0.1,0.1-0.2,0.1-0.3,0l0,0l0,0l0,0c-0.1-0.1-0.1-0.2,0-0.3
			l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C105.8,162.9,105.8,163,105.7,163.1L105.7,163.1C105.7,163.1,105.7,163.1,105.7,163.1z M157.2,88.2
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0
			C157.4,88.2,157.3,88.2,157.2,88.2z M104.9,162.5c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0
			l0,0C105,162.3,105,162.4,104.9,162.5L104.9,162.5C105,162.5,105,162.5,104.9,162.5z M156.3,87.7L156.3,87.7
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0
			C156.6,87.7,156.4,87.8,156.3,87.7z M104.2,161.9c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0
			l0,0C104.3,161.6,104.3,161.8,104.2,161.9L104.2,161.9C104.2,161.9,104.2,161.9,104.2,161.9z M155.5,87.2L155.5,87.2
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0
			C155.7,87.3,155.6,87.3,155.5,87.2z M103.5,161.2c-0.1,0.1-0.2,0.1-0.3,0l0,0c0,0,0,0,0,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3,0l0,0C103.6,161,103.6,161.1,103.5,161.2L103.5,161.2C103.5,161.2,103.5,161.2,103.5,161.2z M154.6,86.8
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0
			C154.8,86.8,154.7,86.8,154.6,86.8z M102.8,160.6c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0
			l0,0C102.9,160.3,102.9,160.5,102.8,160.6L102.8,160.6C102.8,160.6,102.8,160.6,102.8,160.6z M153.7,86.4c-0.1,0-0.2-0.2-0.1-0.3
			l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C154,86.4,153.8,86.4,153.7,86.4z M102.1,159.9
			c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C102.2,159.6,102.2,159.8,102.1,159.9
			L102.1,159.9C102.1,159.9,102.1,159.9,102.1,159.9z M152.8,86c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C153.1,86,152.9,86,152.8,86z M101.4,159.2c-0.1,0.1-0.3,0.1-0.4,0l0,0
			c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C101.5,158.9,101.5,159.1,101.4,159.2L101.4,159.2
			C101.4,159.2,101.4,159.2,101.4,159.2z M151.9,85.6L151.9,85.6c-0.1,0-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0.1l0,0C152.2,85.6,152.1,85.6,151.9,85.6z M100.7,158.5c-0.1,0.1-0.3,0.1-0.3,0l0,0l0,0
			l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C100.8,158.2,100.8,158.4,100.7,158.5L100.7,158.5
			C100.8,158.5,100.7,158.5,100.7,158.5z M151.1,85.2c-0.1,0-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0l0,0l0,0
			c0.1,0,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C151.3,85.2,151.2,85.3,151.1,85.2z M100.1,157.8c-0.1,0.1-0.3,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C100.2,157.5,100.2,157.6,100.1,157.8L100.1,157.8
			C100.1,157.7,100.1,157.8,100.1,157.8z M150.1,84.9c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C150.4,84.9,150.3,84.9,150.1,84.9z M99.4,157c-0.1,0.1-0.3,0.1-0.3,0l0,0l0,0l0,0
			c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C99.5,156.8,99.5,156.9,99.4,157L99.4,157C99.5,157,99.5,157,99.4,157z
			 M149.2,84.5c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0,0,0.1l0,0
			C149.5,84.5,149.3,84.6,149.2,84.5z M98.8,156.3c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0
			l0,0C98.9,156,98.9,156.1,98.8,156.3L98.8,156.3C98.9,156.2,98.8,156.3,98.8,156.3z M148.3,84.2c-0.1,0-0.2-0.2-0.2-0.3l0,0
			c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0,0,0.1l0,0C148.5,84.2,148.4,84.3,148.3,84.2z M98.2,155.5
			c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0.1-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C98.3,155.3,98.3,155.4,98.2,155.5
			L98.2,155.5C98.3,155.5,98.2,155.5,98.2,155.5z M147.4,84c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0l0,0l0,0
			c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0,0,0.1l0,0C147.6,83.9,147.5,84,147.4,84z M97.6,154.7c-0.1,0.1-0.3,0.1-0.3-0.1l0,0l0,0l0,0
			c-0.1-0.1-0.1-0.3,0.1-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0.1l0,0C97.7,154.5,97.7,154.6,97.6,154.7L97.6,154.7
			C97.7,154.7,97.7,154.7,97.6,154.7z M146.5,83.7L146.5,83.7c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0l0,0l0,0
			c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C146.7,83.7,146.6,83.7,146.5,83.7z M97.1,153.9c-0.1,0.1-0.3,0.1-0.3-0.1l0,0
			c-0.1-0.1-0.1-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C97.2,153.7,97.2,153.8,97.1,153.9L97.1,153.9
			C97.1,153.9,97.1,153.9,97.1,153.9z M145.5,83.4L145.5,83.4c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0
			c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C145.7,83.4,145.6,83.5,145.5,83.4z M96.5,153.1c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C96.6,152.9,96.6,153,96.5,153.1L96.5,153.1
			C96.5,153.1,96.5,153.1,96.5,153.1z M144.6,83.2c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0l0,0l0,0
			c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C144.8,83.2,144.7,83.2,144.6,83.2z M96,152.3c-0.1,0.1-0.3,0-0.3-0.1l0,0l0,0l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C96.1,152.1,96.1,152.2,96,152.3L96,152.3C96,152.3,96,152.3,96,152.3z
			 M143.6,83c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C143.8,83,143.7,83,143.6,83z M95.4,151.5c-0.1,0.1-0.3,0-0.3-0.1l0,0c0,0,0,0,0,0l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0C95.6,151.3,95.6,151.4,95.4,151.5L95.4,151.5C95.5,151.5,95.5,151.5,95.4,151.5z M142.7,82.8
			c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0l0,0l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C142.9,82.8,142.8,82.8,142.7,82.8z M94.9,150.7c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1
			l0,0C95.1,150.4,95.1,150.5,94.9,150.7L94.9,150.7C95,150.6,95,150.6,94.9,150.7z M141.7,82.7c-0.1,0-0.2-0.1-0.2-0.3l0,0
			c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C141.9,82.6,141.8,82.7,141.7,82.7z M94.5,149.8
			c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C94.6,149.6,94.6,149.7,94.5,149.8L94.5,149.8
			C94.5,149.8,94.5,149.8,94.5,149.8z M140.8,82.5c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0l0,0l0,0
			c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C141,82.5,140.9,82.5,140.8,82.5z M94,149c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C94.1,148.7,94.1,148.8,94,149L94,149C94.1,148.9,94,148.9,94,149z
			 M139.8,82.4L139.8,82.4c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0l0,0l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0
			c0,0,0,0.1,0,0.1l0,0C140,82.4,139.9,82.4,139.8,82.4z M93.6,148.1c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0C93.7,147.8,93.7,147.9,93.6,148.1L93.6,148.1C93.6,148,93.6,148.1,93.6,148.1z M138.9,82.3
			c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C139,82.2,139,82.3,138.9,82.3z M93.1,147.2c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0
			c0,0.1,0,0.2,0,0.2l0,0C93.2,147.2,93.2,147.2,93.1,147.2z M137.9,82.2c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0
			c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C138.1,82.2,138,82.2,137.9,82.2z M92.7,146.3c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c0,0,0,0,0,0l0,0c0-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0C92.9,146.1,92.9,146.2,92.7,146.3L92.7,146.3
			C92.8,146.3,92.8,146.3,92.7,146.3z M136.9,82.1L136.9,82.1c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0
			c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C137.1,82.1,137,82.1,136.9,82.1z M92.3,145.4c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0C92.5,145.2,92.5,145.3,92.3,145.4L92.3,145.4
			C92.4,145.4,92.4,145.4,92.3,145.4z M136,82.1c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2l0,0c0,0,0,0,0,0l0,0
			c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C136.1,82.1,136.1,82.1,136,82.1z M92,144.5c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c0-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0c0,0.1,0,0.2,0,0.2l0,0C92,144.5,92,144.5,92,144.5z M135,82.1L135,82.1
			c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2l0,0l0,0l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C135.2,82,135.1,82.1,135,82.1z M91.6,143.6c-0.1,0.1-0.3,0-0.3-0.1l0,0c0,0,0,0,0,0l0,0c0-0.1,0-0.3,0.1-0.3l0,0
			c0.1,0,0.3,0,0.3,0.1l0,0c0,0.1,0,0.2,0,0.2l0,0C91.7,143.6,91.6,143.6,91.6,143.6z M134,82.1L134,82.1c-0.1,0-0.2-0.1-0.3-0.2
			l0,0c0-0.1,0.1-0.2,0.2-0.2l0,0l0,0l0,0c0.1,0,0.2,0.1,0.2,0.2l0,0c0,0,0,0.1,0,0.1l0,0C134.2,82,134.1,82.1,134,82.1z
			 M91.3,142.7c-0.1,0-0.3,0-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.2,0,0.2l0,0
			C91.4,142.7,91.3,142.7,91.3,142.7z M133.1,82.1L133.1,82.1c-0.1,0-0.2-0.1-0.3-0.2l0,0c0-0.1,0.1-0.2,0.2-0.3l0,0
			c0.1,0,0.2,0.1,0.3,0.2l0,0c0,0,0,0.1,0,0.1l0,0C133.2,82,133.2,82.1,133.1,82.1z M91,141.8c-0.1,0-0.3,0-0.3-0.2l0,0c0,0,0,0,0,0
			l0,0c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.2,0,0.2l0,0C91.1,141.7,91,141.8,91,141.8z M132.1,82.1
			c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.1l0,0
			C132.2,82.1,132.2,82.1,132.1,82.1z M90.7,140.9c-0.1,0-0.3,0-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0
			c0,0.1,0,0.2,0,0.2l0,0C90.8,140.8,90.7,140.8,90.7,140.9z M131.1,82.2c-0.1,0-0.2-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0l0,0
			l0,0c0.1,0,0.2,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C131.3,82.1,131.2,82.2,131.1,82.2z M90.4,139.9c-0.1,0-0.3,0-0.3-0.2l0,0
			c0-0.1,0-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C90.5,139.9,90.4,139.9,90.4,139.9z M130.2,82.3
			c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.2,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C130.3,82.2,130.2,82.3,130.2,82.3z M90.1,139c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0,0.3,0.2l0,0
			c0,0.1,0,0.1,0,0.2l0,0C90.2,138.9,90.2,139,90.1,139z M129.2,82.4L129.2,82.4c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3
			l0,0l0,0l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C129.3,82.3,129.3,82.4,129.2,82.4z M89.9,138
			c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C90,138,90,138,89.9,138z
			 M128.3,82.5c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C128.4,82.4,128.3,82.5,128.3,82.5z M89.7,137.1c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0
			c0,0.1,0,0.1,0,0.2l0,0C89.8,137,89.8,137.1,89.7,137.1z M127.3,82.6L127.3,82.6c-0.1,0-0.3-0.1-0.3-0.2l0,0
			c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C127.4,82.6,127.4,82.6,127.3,82.6z M89.5,136.1
			c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C89.6,136.1,89.6,136.1,89.5,136.1z M126.3,82.8L126.3,82.8c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0
			c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C126.5,82.7,126.4,82.8,126.3,82.8z M89.3,135.2c-0.1,0-0.3-0.1-0.3-0.2l0,0
			c0,0,0,0,0,0l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C89.5,135.1,89.4,135.2,89.3,135.2z
			 M125.4,83c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0,0,0,0,0,0l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1-0.1,0.2
			l0,0C125.5,82.9,125.5,83,125.4,83z M89.2,134.2c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.2,0.1,0.3,0.2l0,0
			c0,0.1,0,0.1,0,0.2l0,0C89.3,134.2,89.2,134.2,89.2,134.2z M124.4,83.2c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0l0,0
			l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C124.6,83.1,124.5,83.2,124.4,83.2z M89,133.2c-0.1,0-0.3-0.1-0.3-0.2l0,0
			l0,0l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C89.2,133.2,89.1,133.2,89,133.2z
			 M123.5,83.4c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0l0,0l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C123.6,83.4,123.6,83.4,123.5,83.4z M88.9,132.3c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.2,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0
			c0,0.1,0,0.1,0,0.2l0,0C89.1,132.2,89,132.3,88.9,132.3z M122.6,83.6L122.6,83.6c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3
			l0,0c0,0,0,0,0,0l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C122.7,83.6,122.6,83.6,122.6,83.6z M88.8,131.3
			c-0.1,0-0.2-0.1-0.3-0.2l0,0c0-0.1,0.1-0.2,0.2-0.3l0,0c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0,0,0.1,0,0.1l0,0
			C89,131.3,88.9,131.3,88.8,131.3z M121.6,83.9c-0.1,0-0.3,0-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3l0,0l0,0l0,0c0.1,0,0.3,0,0.3,0.2l0,0
			c0,0.1,0,0.1,0,0.2l0,0C121.7,83.9,121.7,83.9,121.6,83.9z M88.8,130.3c-0.1,0-0.3-0.1-0.3-0.2l0,0c0-0.1,0.1-0.3,0.2-0.3l0,0
			c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0C88.9,130.3,88.8,130.3,88.8,130.3z M120.7,84.2L120.7,84.2
			c-0.1,0-0.3,0-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3l0,0l0,0l0,0c0.1,0,0.3,0,0.3,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
			C120.8,84.1,120.8,84.2,120.7,84.2z M88.7,129.4c-0.1,0-0.2-0.1-0.3-0.2l0,0c0-0.1,0.1-0.2,0.2-0.3l0,0c0.1,0,0.2,0.1,0.3,0.2l0,0
			c0,0.1,0,0.1,0,0.2l0,0C88.9,129.3,88.8,129.4,88.7,129.4z M119.8,84.5L119.8,84.5c-0.1,0-0.3,0-0.3-0.2l0,0c0-0.1,0-0.3,0.2-0.3
			l0,0l0,0l0,0c0.1,0,0.3,0,0.3,0.1l0,0c0,0.1,0,0.2,0,0.2l0,0C119.9,84.4,119.8,84.5,119.8,84.5z M88.7,128.4
			c-0.1,0-0.2-0.1-0.3-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2l0,0c0.1,0,0.2,0.1,0.3,0.2l0,0c0,0.1,0,0.1-0.1,0.1l0,0
			C88.8,128.4,88.8,128.4,88.7,128.4z M118.9,84.8c-0.1,0-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0
			c0,0.1,0,0.2,0,0.2l0,0C119,84.8,118.9,84.8,118.9,84.8z M88.7,127.4c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0
			c0.1,0,0.2,0.1,0.2,0.2l0,0c0,0,0,0.1,0,0.1l0,0C88.8,127.4,88.8,127.4,88.7,127.4z M118,85.1L118,85.1c-0.1,0-0.3,0-0.3-0.1l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1,0,0.3,0,0.3,0.1l0,0c0,0.1,0,0.2,0,0.2l0,0C118,85.1,118,85.1,118,85.1z M88.7,126.4
			c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.2-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C88.9,126.4,88.8,126.5,88.7,126.4z M117.1,85.5L117.1,85.5c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0c0,0.1,0,0.2,0,0.2l0,0C117.1,85.5,117.1,85.5,117.1,85.5z M88.7,125.5c-0.1,0-0.2-0.1-0.2-0.3l0,0
			c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.2l0,0c0,0.1,0,0.1,0,0.1l0,0C88.9,125.4,88.8,125.5,88.7,125.5z M116.2,85.9
			c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0l0,0l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C116.3,85.6,116.3,85.7,116.2,85.9
			L116.2,85.9C116.2,85.8,116.2,85.9,116.2,85.9z M88.8,124.5c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0
			c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C89,124.5,88.9,124.5,88.8,124.5z M115.3,86.3c-0.1,0.1-0.3,0-0.3-0.1l0,0
			c-0.1-0.1,0-0.3,0.1-0.3l0,0l0,0l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C115.4,86,115.4,86.1,115.3,86.3L115.3,86.3
			C115.4,86.2,115.3,86.3,115.3,86.3z M88.9,123.5c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0
			c0,0,0,0.1,0,0.1l0,0C89,123.5,88.9,123.6,88.9,123.5z M114.4,86.7c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0
			c0.1-0.1,0.3,0,0.3,0.1l0,0C114.6,86.5,114.5,86.6,114.4,86.7L114.4,86.7C114.5,86.7,114.4,86.7,114.4,86.7z M89,122.6
			c-0.1,0-0.2-0.1-0.2-0.3l0,0c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C89.1,122.5,89,122.6,89,122.6z M113.5,87.1c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0
			C113.7,86.9,113.7,87,113.5,87.1L113.5,87.1C113.6,87.1,113.6,87.1,113.5,87.1z M89.1,121.6c-0.1,0-0.2-0.1-0.2-0.3l0,0
			c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C89.2,121.6,89.2,121.6,89.1,121.6z M112.7,87.6
			L112.7,87.6c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0l0,0l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0
			C112.8,87.3,112.8,87.4,112.7,87.6L112.7,87.6C112.7,87.5,112.7,87.6,112.7,87.6z M89.2,120.7c-0.1,0-0.2-0.1-0.2-0.3l0,0
			c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2,0.1,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C89.4,120.6,89.3,120.7,89.2,120.7z M111.8,88.1
			c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C112,87.8,112,87.9,111.8,88.1L111.8,88.1
			C111.9,88,111.9,88,111.8,88.1z M89.4,119.7c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0
			c0,0,0,0.1,0,0.1l0,0C89.6,119.7,89.5,119.7,89.4,119.7z M111,88.6c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0l0,0
			l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C111.1,88.3,111.1,88.4,111,88.6L111,88.6C111,88.5,111,88.5,111,88.6z M89.5,118.7
			c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0
			C89.7,118.7,89.6,118.8,89.5,118.7z M110.2,89.1c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1
			l0,0C110.3,88.8,110.3,88.9,110.2,89.1L110.2,89.1C110.2,89,110.2,89,110.2,89.1z M89.7,117.8c-0.1,0-0.2-0.2-0.2-0.3l0,0
			c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C89.9,117.8,89.8,117.8,89.7,117.8z M109.3,89.6
			c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0l0,0l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C109.5,89.3,109.5,89.5,109.3,89.6
			L109.3,89.6C109.4,89.6,109.4,89.6,109.3,89.6z M89.9,116.8c-0.1,0-0.2-0.2-0.2-0.3l0,0l0,0l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0
			c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0.1,0,0.1l0,0C90.2,116.8,90,116.9,89.9,116.8z M108.5,90.1L108.5,90.1c-0.1,0.1-0.3,0-0.3-0.1
			l0,0c-0.1-0.1,0-0.3,0.1-0.3l0,0c0.1-0.1,0.3,0,0.3,0.1l0,0C108.7,89.9,108.7,90,108.5,90.1L108.5,90.1
			C108.6,90.1,108.6,90.1,108.5,90.1z M90.2,115.9c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0
			c0,0,0,0.1,0,0.1l0,0C90.4,115.9,90.3,115.9,90.2,115.9z M107.7,90.7c-0.1,0.1-0.3,0-0.3-0.1l0,0c-0.1-0.1-0.1-0.3,0.1-0.3l0,0
			c0,0,0,0,0,0l0,0c0.1-0.1,0.3-0.1,0.3,0.1l0,0C107.9,90.4,107.9,90.6,107.7,90.7L107.7,90.7C107.8,90.7,107.8,90.7,107.7,90.7z
			 M90.4,114.9c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0,0,0.1l0,0
			C90.7,114.9,90.6,115,90.4,114.9z M107,91.3c-0.1,0.1-0.3,0.1-0.3-0.1l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0,0,0,0,0,0l0,0
			c0.1-0.1,0.3-0.1,0.3,0l0,0C107.1,91,107.1,91.1,107,91.3L107,91.3C107,91.2,107,91.3,107,91.3z M90.7,114c-0.1,0-0.2-0.2-0.2-0.3
			l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0c0,0,0,0,0,0.1l0,0C90.9,114,90.8,114.1,90.7,114z M106.2,91.9
			c-0.1,0.1-0.3,0.1-0.4,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C106.3,91.6,106.3,91.7,106.2,91.9L106.2,91.9
			C106.2,91.8,106.2,91.8,106.2,91.9z M91,113.1c-0.1,0-0.2-0.2-0.2-0.3l0,0c0-0.1,0.2-0.2,0.3-0.2l0,0c0.1,0,0.2,0.2,0.2,0.3l0,0
			c0,0,0,0,0,0.1l0,0C91.2,113.1,91.1,113.1,91,113.1z M105.4,92.5L105.4,92.5c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3
			l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C105.6,92.2,105.6,92.3,105.4,92.5L105.4,92.5C105.5,92.4,105.5,92.5,105.4,92.5z M91.3,112.2
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0.1l0,0
			C91.6,112.2,91.4,112.2,91.3,112.2z M104.7,93.1c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0
			l0,0C104.8,92.8,104.8,93,104.7,93.1L104.7,93.1C104.7,93.1,104.7,93.1,104.7,93.1z M91.7,111.3c-0.1,0-0.2-0.2-0.1-0.3l0,0
			c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C91.9,111.3,91.8,111.3,91.7,111.3z M104,93.7
			c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C104.1,93.5,104.1,93.6,104,93.7L104,93.7
			C104,93.7,104,93.7,104,93.7z M92,110.4c-0.1,0-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0
			c0,0,0,0,0,0l0,0C92.3,110.4,92.1,110.4,92,110.4z M103.3,94.4c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.3,0-0.3l0,0
			c0.1-0.1,0.3-0.1,0.3,0l0,0C103.4,94.1,103.4,94.3,103.3,94.4L103.3,94.4C103.3,94.4,103.3,94.4,103.3,94.4z M92.4,109.5
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0
			C92.6,109.5,92.5,109.5,92.4,109.5z M102.6,95.1c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.3-0.1,0.4,0
			l0,0C102.7,94.8,102.7,94.9,102.6,95.1L102.6,95.1C102.6,95,102.6,95.1,102.6,95.1z M92.8,108.6c-0.1-0.1-0.2-0.2-0.1-0.3l0,0l0,0
			l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C93,108.6,92.9,108.6,92.8,108.6z M101.9,95.8
			L101.9,95.8c-0.1,0.1-0.3,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0l0,0l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0
			C102,95.5,102,95.6,101.9,95.8L101.9,95.8C101.9,95.7,101.9,95.7,101.9,95.8z M93.2,107.7c-0.1-0.1-0.2-0.2-0.1-0.3l0,0
			c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C93.5,107.7,93.3,107.7,93.2,107.7z M101.2,96.5
			c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0l0,0C101.3,96.2,101.3,96.3,101.2,96.5L101.2,96.5
			C101.2,96.4,101.2,96.4,101.2,96.5z M93.7,106.8c-0.1-0.1-0.2-0.2-0.1-0.3l0,0l0,0l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C93.9,106.8,93.8,106.9,93.7,106.8z M100.6,97.2c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.2,0-0.3l0,0l0,0l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C100.6,96.9,100.7,97,100.6,97.2L100.6,97.2
			C100.6,97.2,100.6,97.2,100.6,97.2z M94.1,105.9c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0
			c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C94.4,106,94.2,106,94.1,105.9z M99.9,97.9c-0.1,0.1-0.2,0.1-0.3,0l0,0
			c-0.1-0.1-0.1-0.2,0-0.3l0,0l0,0l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C100,97.6,100,97.8,99.9,97.9L99.9,97.9
			C99.9,97.9,99.9,97.9,99.9,97.9z M94.6,105.1c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.2,0.2,0.1,0.3
			l0,0c0,0,0,0,0,0l0,0C94.8,105.1,94.7,105.2,94.6,105.1z M99.3,98.6c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3,0l0,0C99.4,98.4,99.4,98.5,99.3,98.6L99.3,98.6C99.3,98.6,99.3,98.6,99.3,98.6z M95.1,104.3
			c-0.1-0.1-0.2-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.2,0.3-0.1l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0l0,0l0,0
			C95.3,104.3,95.2,104.3,95.1,104.3z M98.7,99.4c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0,0,0,0,0,0l0,0
			c0.1-0.1,0.2-0.1,0.3,0l0,0C98.8,99.1,98.8,99.3,98.7,99.4L98.7,99.4L98.7,99.4z M95.6,103.4c-0.1-0.1-0.2-0.2-0.1-0.3l0,0
			c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0c0,0,0,0,0,0l0,0C95.8,103.5,95.7,103.5,95.6,103.4z M98.1,100.2
			c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0l0,0l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C98.2,99.9,98.2,100,98.1,100.2
			L98.1,100.2C98.1,100.2,98.1,100.2,98.1,100.2z M96.1,102.6c-0.1-0.1-0.1-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0
			c0.1,0.1,0.1,0.2,0.1,0.3l0,0l0,0l0,0C96.4,102.6,96.2,102.7,96.1,102.6z M97.5,100.9c-0.1,0.1-0.2,0.1-0.3,0.1l0,0
			c-0.1-0.1-0.1-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0,0C97.6,100.7,97.6,100.8,97.5,100.9L97.5,100.9L97.5,100.9z M96.6,101.8
			c-0.1-0.1-0.1-0.2-0.1-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0.1,0.1,0.1,0.2,0.1,0.3l0,0l0,0l0,0
			C96.9,101.8,96.7,101.9,96.6,101.8z M96.6,101.5L96.6,101.5L96.6,101.5L96.6,101.5L96.6,101.5L96.6,101.5z"/>
	</g>
	<g>
		<path class="st1" d="M174.2,155.4c-15.3,21.9-45.7,27.3-67.6,11.9C84.7,152,79.3,121.7,94.6,99.7c15.3-21.9,45.7-27.3,67.6-11.9
			C184.2,103.1,189.5,133.5,174.2,155.4z M95,100c-15.2,21.7-9.9,51.7,11.8,66.9c21.7,15.2,51.7,9.9,66.9-11.8
			c15.2-21.7,9.9-51.7-11.8-66.9C140.3,73,110.2,78.3,95,100z"/>
	</g>
	<g>
		<path class="st1" d="M177.4,157.7c-0.1,0.2-0.2,0.3-0.4,0.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0
			c0.9-1.3,1.8-2.7,2.6-4.1c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0.1,0.2,0.2,0.1,0.3C179,155.3,178.3,156.5,177.4,157.7z M174.1,162
			C174.1,162,174.1,162,174.1,162c-1.1,1.2-2.2,2.4-3.4,3.6c-0.1,0.1-0.3,0.1-0.3,0c-0.1-0.1-0.1-0.3,0-0.3c1.2-1.1,2.3-2.3,3.3-3.5
			c0.1-0.1,0.2-0.1,0.3,0C174.1,161.8,174.1,161.9,174.1,162z M182,149.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1-0.1-0.2-0.2-0.1-0.3
			c0.7-1.5,1.3-3,1.8-4.5c0-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.2,0.1,0.3C183.3,146.8,182.7,148.3,182,149.8
			C182,149.8,182,149.8,182,149.8z M167,168.7C167,168.7,167,168.8,167,168.7c-1.3,1.1-2.7,2-4,2.9c-0.1,0.1-0.3,0-0.3-0.1
			c-0.1-0.1,0-0.3,0.1-0.3c1.4-0.9,2.7-1.8,3.9-2.8c0.1-0.1,0.3-0.1,0.3,0C167.1,168.5,167.1,168.6,167,168.7z M185.3,140.6
			c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.2-0.2-0.3c0.4-1.6,0.7-3.2,1-4.7c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.3
			C186,137.4,185.7,139,185.3,140.6C185.3,140.6,185.3,140.6,185.3,140.6z M158.9,174C158.9,174,158.8,174.1,158.9,174
			c-1.5,0.8-3,1.5-4.5,2.1c-0.1,0.1-0.3,0-0.3-0.1c-0.1-0.1,0-0.3,0.1-0.3c1.5-0.6,3-1.3,4.4-2c0.1-0.1,0.3,0,0.3,0.1
			C158.9,173.8,158.9,173.9,158.9,174z M186.8,131c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.3c0.1-1.6,0.1-3.2,0.1-4.8
			c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.2,0.1,0.3,0.2c0,1.6,0,3.3-0.1,4.9C186.8,131,186.8,131,186.8,131z M149.9,177.7
			c0,0-0.1,0.1-0.1,0.1c-1.6,0.5-3.1,0.9-4.7,1.2c-0.1,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.3,0.2-0.3c1.6-0.3,3.2-0.7,4.7-1.2
			c0.1,0,0.3,0,0.3,0.2C149.9,177.6,149.9,177.6,149.9,177.7z M186.5,121.3c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.3-0.1-0.3-0.2
			c-0.2-1.6-0.5-3.2-0.8-4.8c0-0.1,0.1-0.3,0.2-0.3c0.1,0,0.3,0.1,0.3,0.2c0.3,1.6,0.6,3.2,0.8,4.8
			C186.6,121.2,186.5,121.3,186.5,121.3z M140.4,179.7c0,0.1-0.1,0.1-0.2,0.1c-1.6,0.2-3.3,0.3-4.9,0.3c-0.1,0-0.2-0.1-0.2-0.2
			c0-0.1,0.1-0.2,0.2-0.2c1.6,0,3.2-0.1,4.8-0.3c0.1,0,0.3,0.1,0.3,0.2C140.4,179.6,140.4,179.6,140.4,179.7z M184.4,111.8
			c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.3,0-0.3-0.2c-0.5-1.5-1.1-3.1-1.7-4.5c-0.1-0.1,0-0.3,0.1-0.3c0.1-0.1,0.3,0,0.3,0.1
			c0.6,1.5,1.2,3,1.7,4.6C184.5,111.7,184.5,111.8,184.4,111.8z M130.6,179.8c0,0.1-0.1,0.1-0.2,0.1c-1.6-0.1-3.3-0.3-4.9-0.6
			c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.2,0.3-0.2c1.6,0.3,3.2,0.5,4.8,0.6c0.1,0,0.2,0.1,0.2,0.3
			C130.7,179.8,130.7,179.8,130.6,179.8z M180.6,102.9C180.6,102.9,180.6,102.9,180.6,102.9c-0.2,0.1-0.4,0.1-0.4,0
			c-0.8-1.4-1.6-2.8-2.5-4.2c-0.1-0.1,0-0.3,0.1-0.3c0.1-0.1,0.3,0,0.3,0.1c0.9,1.4,1.7,2.8,2.5,4.2
			C180.7,102.7,180.7,102.8,180.6,102.9z M121.1,178.2c-0.1,0.1-0.2,0.1-0.3,0.1c-1.6-0.4-3.1-0.9-4.7-1.5c-0.1,0-0.2-0.2-0.1-0.3
			c0.1-0.1,0.2-0.2,0.3-0.1c1.5,0.6,3.1,1.1,4.6,1.5C121.1,177.9,121.1,178,121.1,178.2C121.1,178.2,121.1,178.2,121.1,178.2z
			 M175.2,94.8C175.2,94.8,175.2,94.8,175.2,94.8c-0.2,0.1-0.3,0.1-0.4,0c-1-1.2-2.1-2.5-3.2-3.6c-0.1-0.1-0.1-0.2,0-0.3
			c0.1-0.1,0.3-0.1,0.3,0c1.1,1.2,2.2,2.4,3.3,3.6C175.3,94.6,175.3,94.7,175.2,94.8z M111.9,174.8c-0.1,0.1-0.2,0.1-0.3,0.1
			c-1.5-0.7-2.9-1.5-4.3-2.3c-0.1-0.1-0.2-0.2-0.1-0.3c0-0.1,0.2-0.2,0.3-0.1c1.4,0.8,2.8,1.6,4.3,2.3
			C112,174.5,112,174.7,111.9,174.8C112,174.8,112,174.8,111.9,174.8z M168.4,87.8C168.4,87.8,168.4,87.8,168.4,87.8
			c-0.1,0.1-0.3,0.1-0.4,0c-1.2-1-2.5-2-3.8-3c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c1.3,0.9,2.6,1.9,3.9,3
			C168.5,87.6,168.5,87.7,168.4,87.8z M103.6,169.8C103.6,169.8,103.6,169.8,103.6,169.8c-0.1,0.1-0.2,0.1-0.3,0.1
			c-1.3-1-2.6-2-3.8-3.1c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3,0c1.2,1.1,2.5,2.1,3.8,3C103.7,169.5,103.7,169.7,103.6,169.8z
			 M162.6,83.6c-0.1,0.1-0.2,0.1-0.3,0.1c-1.4-0.9-2.8-1.7-4.2-2.4c-0.1-0.1-0.2-0.2-0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.1
			c1.4,0.7,2.9,1.6,4.2,2.4C162.7,83.3,162.7,83.4,162.6,83.6C162.6,83.5,162.6,83.6,162.6,83.6z M96.4,163.3
			C96.3,163.4,96.3,163.4,96.4,163.3c-0.1,0.1-0.3,0.1-0.4,0c-1.1-1.2-2.2-2.4-3.2-3.7c-0.1-0.1-0.1-0.3,0-0.3
			c0.1-0.1,0.3-0.1,0.3,0c1,1.3,2,2.5,3.1,3.7C96.4,163.1,96.4,163.2,96.4,163.3z M154,79.1c-0.1,0.1-0.2,0.1-0.3,0.1
			c-1.5-0.6-3-1.1-4.6-1.6c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.3-0.2c1.6,0.5,3.1,1,4.6,1.6C154,78.8,154,78.9,154,79.1
			C154,79.1,154,79.1,154,79.1z M90.4,155.6C90.4,155.7,90.4,155.7,90.4,155.6c-0.2,0.1-0.3,0.1-0.4,0c-0.9-1.4-1.7-2.8-2.4-4.3
			c-0.1-0.1,0-0.3,0.1-0.3c0.1-0.1,0.3,0,0.3,0.1c0.7,1.4,1.5,2.9,2.4,4.2C90.5,155.5,90.5,155.6,90.4,155.6z M144.6,76.4
			c-0.1,0.1-0.1,0.1-0.2,0.1c-1.6-0.3-3.2-0.5-4.8-0.7c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.2,0.3-0.2c1.6,0.2,3.2,0.4,4.8,0.7
			C144.6,76,144.7,76.1,144.6,76.4C144.7,76.3,144.7,76.3,144.6,76.4z M86,147c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.3,0-0.3-0.1
			c-0.6-1.5-1.1-3.1-1.6-4.6c0-0.1,0-0.3,0.2-0.3c0.1,0,0.3,0,0.3,0.2c0.5,1.5,1,3.1,1.6,4.6C86.1,146.8,86.1,146.9,86,147z
			 M135,75.4c0,0.1-0.1,0.1-0.2,0.1c-1.6,0-3.2,0.1-4.8,0.2c-0.1,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.3,0.2-0.3c1.6-0.1,3.3-0.2,4.9-0.2
			c0.1,0,0.2,0.1,0.2,0.2C135,75.3,135,75.4,135,75.4z M83.3,137.6c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.3-0.1-0.3-0.2
			c-0.3-1.6-0.5-3.2-0.7-4.8c0-0.1,0.1-0.3,0.2-0.3c0.1,0,0.3,0.1,0.3,0.2c0.2,1.6,0.4,3.2,0.7,4.8
			C83.3,137.5,83.3,137.6,83.3,137.6z M125.3,76.3c0,0.1-0.1,0.1-0.2,0.1c-1.6,0.3-3.2,0.7-4.7,1.1c-0.1,0-0.3,0-0.3-0.2
			c0-0.1,0-0.3,0.2-0.3c1.6-0.4,3.2-0.8,4.8-1.1c0.1,0,0.3,0.1,0.3,0.2C125.3,76.1,125.3,76.2,125.3,76.3z M82.3,128
			c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-1.6,0.1-3.3,0.2-4.9c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.3
			c-0.1,1.6-0.2,3.2-0.2,4.8C82.4,127.9,82.4,127.9,82.3,128z M115.9,78.9c0,0-0.1,0.1-0.1,0.1c-1.5,0.6-3,1.2-4.4,1.9
			c-0.1,0.1-0.3,0-0.3-0.1c-0.1-0.1,0-0.3,0.1-0.3c1.5-0.7,3-1.4,4.5-2c0.1,0,0.3,0,0.3,0.1C116,78.7,116,78.8,115.9,78.9z
			 M83.2,118.3c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.2-0.2-0.3c0.3-1.6,0.7-3.2,1.1-4.8c0-0.1,0.2-0.2,0.3-0.2
			c0.1,0,0.2,0.2,0.2,0.3C83.9,115,83.5,116.6,83.2,118.3C83.2,118.2,83.2,118.2,83.2,118.3z M107.2,83.2
			C107.2,83.2,107.2,83.2,107.2,83.2c-1.4,0.9-2.8,1.8-4.1,2.8c-0.1,0.1-0.3,0.1-0.3,0c-0.1-0.1-0.1-0.3,0-0.3c1.3-1,2.7-1.9,4-2.8
			c0.1-0.1,0.3,0,0.3,0.1C107.3,83,107.3,83.1,107.2,83.2z M85.8,108.9c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.2-0.1-0.3
			c0.6-1.5,1.2-3,2-4.5c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0.1,0.2,0.2,0.1,0.3C87.1,105.9,86.4,107.4,85.8,108.9
			C85.8,108.9,85.8,108.9,85.8,108.9z M99.4,89C99.4,89,99.4,89,99.4,89c-1.2,1.1-2.4,2.3-3.5,3.5c-0.1,0.1-0.2,0.1-0.3,0
			c-0.1-0.1-0.1-0.2,0-0.3c1.1-1.2,2.3-2.4,3.5-3.5c0.1-0.1,0.3-0.1,0.3,0C99.5,88.8,99.5,88.9,99.4,89z M90.1,100.2
			c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0.9-1.4,1.8-2.7,2.8-4c0.1-0.1,0.2-0.1,0.3,0c0.1,0.1,0.1,0.2,0,0.3
			C91.9,97.5,91,98.8,90.1,100.2C90.1,100.2,90.1,100.2,90.1,100.2z"/>
	</g>
</g>
</svg>`,
        eye_envelope: `<svg class="w-full" version="1.1" id="Réteg_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 270.1 269.3" style="enable-background:new 0 0 270.1 269.3;" xml:space="preserve">

<path class="st0" d="M245.2,269.8H25.9c-14,0-25.5-11-25.5-24.6V118.1c0-1.8,0.8-3.5,2.2-5c0-0.1,0.1-0.1,0.1-0.2
	c0.2-0.3,0.5-0.5,0.7-0.7c0.1-0.1,0.2-0.2,0.2-0.3l0.1-0.1l30.3-26.3V41c0-4.4,3.6-8,8-8h52.4L130.3,2c3-2.6,7.5-2.6,10.5,0
	l35.8,31.2h52.4c4.4,0,8,3.6,8,8v44.5l30.4,26.4c0.1,0.1,0.1,0.2,0.2,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.4,0.5,0.6,1,0.8,1.4
	c0.1,0.2,0.3,0.5,0.4,0.8c0.4,0.8,0.6,1.8,0.6,2.8c0,0.2,0.1,0.4,0.1,0.6v126.6C270,258.7,258.8,269.8,245.2,269.8z M6.8,116
	c-0.1,0.1-0.2,0.3-0.3,0.4c-0.9,0.9-1.1,1.4-1.1,1.7v127.1c0,10.8,9.2,19.6,20.5,19.6h219.3c10.9,0,19.9-8.9,19.9-19.9V118.8
	c0-0.2-0.1-0.4-0.1-0.6c0-0.4-0.1-0.9-0.2-1.2l-0.1-0.1c0,0,0-0.1-0.1-0.1l-0.1-0.2l-0.2-0.4c0,0,0,0,0-0.1
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3l-31.8-27.6V41.1c0-1.7-1.3-3-3-3h-54.3L137.5,5.7c-1.1-1-2.8-1-3.9,0L96.4,38.1
	H42.1c-1.7,0-3,1.3-3,3v46.8L7.3,115.5c-0.1,0.2-0.2,0.3-0.3,0.4C6.9,115.9,6.9,115.9,6.8,116z M247.8,266.3l-222.1,1.8
	c-1.5,0-8.8-1.5-9.8-1.8l-4.1-4.1l119.3-106c2.3-2.1,6.5-2.1,8.8-0.1l117.6,104.5l-4.1,5.8C252.1,266.7,249.1,266.3,247.8,266.3z
	 M17.5,263.1l236,1.8L136.7,159.8c-0.4-0.4-1.8-0.3-2.2,0.1L17.5,263.1z M89.6,184.9l-44.9-39.5V43.8h181.8v44l-0.2,0.5
	c-0.3,0.6-0.2,1.4,0,2.1l0.2,0.4v54.3l-52,45.6L136,158.4c-4.7-4-36,27.4-40.7,31.4L89.6,184.9z M49.7,143.1l48.3,42.5l32-28.4
	c6.7-5.8,6.6,0.2,13.3,5.9l29,23.1l49.3-43.2V91.7c-0.5-1.6-0.5-3.3,0-4.8V48.8H49.7V143.1z M39.1,140.4L13.6,118l25.4-22.1V140.4z
	 M21.2,118l12.8,11.3v-22.4L21.2,118z M232.1,140.3V95.9l25.4,22.1L232.1,140.3z M237.1,106.9v22.4l12.8-11.2L237.1,106.9z
	 M165.6,38.1h-60.1l30-26.1L165.6,38.1z M118.9,33.1h33.3l-16.7-14.4L118.9,33.1z"/>
<g>
	<path class="st0" d="M136.6,130.6c-24.2,0-47-10.9-61.1-29.1l-1.4-1.8l1.4-1.8c14-18.2,36.9-29.1,61.1-29.1s47,10.9,61.1,29.1
		l1.4,1.8l-1.4,1.8C183.6,119.8,160.8,130.6,136.6,130.6z M81.7,99.7c13,15.7,33.4,24.9,54.9,24.9s41.9-9.3,54.9-24.9
		c-13-15.7-33.4-24.9-54.9-24.9S94.7,84.1,81.7,99.7z"/>
	<path class="st0" d="M157.1,102.7c-0.6,4.3-2.5,8.4-5.7,11.6c-8,8-21,7.8-29.1-0.2c-8.1-8.1-8.2-21.1-0.2-29.1
		c3.3-3.3,7.4-5.2,11.6-5.7c-0.2,0.2-0.3,0.3-0.5,0.5c-6.4,6.4-6.3,16.8,0.2,23.2c6.5,6.5,16.9,6.6,23.2,0.2
		C156.8,103,156.9,102.8,157.1,102.7z"/>
	<polygon class="st0" points="146.4,88.9 143.3,91 144.4,87.4 141.4,85.1 145.2,84.9 146.4,81.4 147.7,84.9 151.5,85.1 148.5,87.4 
		149.6,91 	"/>
	<path class="st0" d="M136.6,130.6c-17,0-30.9-13.9-30.9-30.9s13.9-30.9,30.9-30.9s30.9,13.9,30.9,30.9S153.6,130.6,136.6,130.6z
		 M136.6,74.8c-13.8,0-24.9,11.2-24.9,24.9s11.2,24.9,24.9,24.9s24.9-11.2,24.9-24.9S150.4,74.8,136.6,74.8z"/>
</g>
</svg>`,

        eye_play: `<svg class="w-full" version="1.1" id="Réteg_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 270.1 254.7" style="enable-background:new 0 0 270.1 254.7;" xml:space="preserve">

<g>
	<path class="st0" d="M135.5,196.5c-51.9,0-100.8-24.9-130.9-66.6l-0.7-1l0.7-1c30.1-41.7,79-66.6,130.9-66.6
		c51.9,0,100.8,24.9,130.9,66.6l0.7,1l-0.7,1C236.4,171.6,187.4,196.5,135.5,196.5z M8.1,129c29.5,40.2,77,64.2,127.4,64.2
		c50.4,0,97.9-24,127.4-64.2c-29.5-40.2-77-64.2-127.4-64.2C85.1,64.8,37.6,88.8,8.1,129z"/>
	<path class="st0" d="M136,183.1c-44.2,0-85.9-19.8-111.6-53.1l-0.8-1l0.8-1C50.1,94.7,91.8,74.9,136,74.9
		c44.2,0,85.9,19.8,111.5,53.1l0.8,1l-0.8,1C221.9,163.2,180.2,183.1,136,183.1z M27.9,129c25,31.8,65.3,50.7,108,50.7
		c42.7,0,83-18.9,108-50.7C219,97.2,178.7,78.3,136,78.3C93.3,78.3,53,97.2,27.9,129z"/>
	<path class="st0" d="M136,183.1c-29.8,0-54.1-24.3-54.1-54.1c0-29.8,24.3-54.1,54.1-54.1c29.8,0,54.1,24.3,54.1,54.1
		C190.1,158.8,165.8,183.1,136,183.1z M136,78.3c-28,0-50.7,22.7-50.7,50.7c0,28,22.7,50.7,50.7,50.7c28,0,50.7-22.7,50.7-50.7
		C186.7,101,163.9,78.3,136,78.3z"/>
	<path class="st0" d="M122.9,40.1l4.2-15.1l-12.3-9.7l15.7-0.6L136,0l5.4,14.7l15.7,0.6L144.8,25l4.2,15.1l-13-8.7L122.9,40.1z
		 M136,27.3l7.3,4.8l-2.4-8.4l6.8-5.4L139,18l-3-8.2l-3,8.2l-8.7,0.3l6.8,5.4l-2.4,8.4L136,27.3z"/>
	<path class="st0" d="M199.5,53.4c-0.1,0-0.1,0-0.2,0c-4.6,0-9-1.9-12.3-5.2c-3.3-3.3-5.1-7.6-5.2-12.3c0-4.6,1.7-9,5-12.2
		c2.7-2.7,6.1-4.3,9.8-4.8l5.4-0.7l-4.1,3.7c-0.1,0.1-0.2,0.2-0.3,0.3c-4.2,4.2-4.1,11,0.2,15.2c4.2,4.2,11.1,4.3,15.2,0.1
		c0.1-0.1,0.2-0.2,0.3-0.3l3.7-4.1l-0.7,5.5c-0.5,3.8-2.2,7.2-4.8,9.8C208.3,51.6,204,53.4,199.5,53.4z M192.5,23.7
		c-1.2,0.6-2.3,1.4-3.3,2.4c-2.6,2.6-4,6.1-4,9.8c0,3.7,1.5,7.2,4.2,9.9c2.7,2.7,6.2,4.1,9.9,4.2c3.7,0.1,7.2-1.4,9.8-4
		c1-1,1.8-2.1,2.4-3.3c-5.2,2.4-11.7,1.4-16.1-2.9C191.1,35.4,190.1,29,192.5,23.7z"/>
	<path class="st0" d="M255.8,82.9c-7.7,0-14-6.3-14-14s6.3-14,14-14c7.7,0,14,6.3,14,14S263.5,82.9,255.8,82.9z M255.8,58.4
		c-5.8,0-10.6,4.7-10.6,10.6c0,5.8,4.7,10.6,10.6,10.6c5.8,0,10.6-4.7,10.6-10.6C266.4,63.1,261.7,58.4,255.8,58.4z"/>
	<path class="st0" d="M71.3,53.4c-4.6,0-8.9-1.8-12.1-5c-2.7-2.7-4.3-6.1-4.8-9.8l-0.7-5.4l3.7,4.1c0.1,0.1,0.2,0.2,0.3,0.3
		c4.2,4.2,11,4.1,15.2-0.1c4.2-4.2,4.3-11.1,0.2-15.2c-0.1-0.1-0.2-0.2-0.3-0.3l-4.1-3.7l5.4,0.7c3.8,0.5,7.2,2.2,9.8,4.8
		c3.2,3.2,5,7.6,5,12.2c0,4.6-1.9,9-5.2,12.3c-3.3,3.3-7.6,5.1-12.3,5.2C71.5,53.4,71.4,53.4,71.3,53.4z M59.3,42.7
		c0.6,1.2,1.4,2.3,2.4,3.3c2.6,2.6,6,4,9.7,4c0,0,0.1,0,0.1,0c3.7,0,7.2-1.5,9.9-4.2c2.7-2.7,4.1-6.2,4.2-9.9c0-3.7-1.4-7.2-4-9.8
		c-1-1-2.1-1.8-3.3-2.4c2.4,5.2,1.4,11.7-2.9,16.1C71,44.2,64.5,45.2,59.3,42.7z"/>
	<path class="st0" d="M15,82.9c-7.7,0-14-6.3-14-14s6.3-14,14-14c7.7,0,14,6.3,14,14S22.7,82.9,15,82.9z M15,58.4
		C9.1,58.4,4.4,63.1,4.4,69c0,5.8,4.7,10.6,10.6,10.6c5.8,0,10.6-4.7,10.6-10.6C25.5,63.1,20.8,58.4,15,58.4z"/>
	<path class="st0" d="M122.9,255.1l4.2-15.1l-12.3-9.7l15.7-0.6l5.4-14.7l5.4,14.7l15.7,0.6l-12.3,9.7l4.2,15.1l-13-8.7L122.9,255.1
		z M136,242.3l7.3,4.8l-2.4-8.4l6.8-5.4L139,233l-3-8.2l-3,8.2l-8.7,0.3l6.8,5.4l-2.4,8.4L136,242.3z"/>
	<path class="st0" d="M199.5,237.1c-0.1,0-0.1,0-0.2,0c-4.6,0-9-1.9-12.3-5.2c-3.3-3.3-5.1-7.6-5.2-12.3c0-4.6,1.7-9,5-12.2
		c2.7-2.7,6.1-4.3,9.8-4.8l5.4-0.7l-4.1,3.7c-0.1,0.1-0.2,0.2-0.3,0.3c-4.2,4.2-4.1,11,0.2,15.2c4.2,4.2,11.1,4.3,15.2,0.1
		c0.1-0.1,0.2-0.2,0.3-0.3l3.7-4.1l-0.7,5.5c-0.5,3.8-2.2,7.2-4.8,9.8C208.3,235.3,204,237.1,199.5,237.1z M192.5,207.4
		c-1.2,0.6-2.3,1.4-3.3,2.4c-2.6,2.6-4,6.1-4,9.8c0,3.7,1.5,7.2,4.2,9.9c2.7,2.7,6.2,4.1,9.9,4.2c0,0,0.1,0,0.1,0
		c3.7,0,7.1-1.4,9.7-4c1-1,1.8-2.1,2.4-3.3c-5.2,2.4-11.7,1.4-16.1-2.9C191.1,219.1,190.1,212.7,192.5,207.4z"/>
	<path class="st0" d="M255.8,201c-7.7,0-14-6.3-14-14c0-7.7,6.3-14,14-14c7.7,0,14,6.3,14,14C269.8,194.7,263.5,201,255.8,201z
		 M255.8,176.5c-5.8,0-10.6,4.7-10.6,10.6c0,5.8,4.7,10.6,10.6,10.6c5.8,0,10.6-4.7,10.6-10.6C266.4,181.2,261.7,176.5,255.8,176.5z
		"/>
	<path class="st0" d="M71.3,237.1c-4.6,0-8.9-1.8-12.1-5c-2.7-2.7-4.3-6.1-4.8-9.8l-0.7-5.4l3.7,4.1c0.1,0.1,0.2,0.2,0.3,0.3
		c4.2,4.2,11,4.1,15.2-0.1c4.2-4.2,4.3-11.1,0.2-15.2c-0.1-0.1-0.2-0.2-0.3-0.3l-4.1-3.7l5.4,0.7c3.8,0.5,7.2,2.2,9.8,4.8
		c3.2,3.2,5,7.6,5,12.2c0,4.6-1.9,9-5.2,12.3c-3.3,3.3-7.6,5.1-12.3,5.2C71.5,237.1,71.4,237.1,71.3,237.1z M59.3,226.4
		c0.6,1.2,1.4,2.3,2.4,3.3c2.6,2.6,6,4,9.7,4c0,0,0.1,0,0.1,0c3.7,0,7.2-1.5,9.9-4.2c2.7-2.7,4.1-6.2,4.2-9.9c0-3.7-1.4-7.2-4-9.8
		c-1-1-2.1-1.8-3.3-2.4c2.4,5.2,1.4,11.7-2.9,16.1C71,227.9,64.5,228.9,59.3,226.4z"/>
	<path class="st0" d="M15,201c-7.7,0-14-6.3-14-14c0-7.7,6.3-14,14-14c7.7,0,14,6.3,14,14C28.9,194.7,22.7,201,15,201z M15,176.5
		c-5.8,0-10.6,4.7-10.6,10.6c0,5.8,4.7,10.6,10.6,10.6c5.8,0,10.6-4.7,10.6-10.6C25.5,181.2,20.8,176.5,15,176.5z"/>
	<path class="st0" d="M202.7,140.4l-1.6-4.2c-1-2.6-3-4.6-5.6-5.6l-4.2-1.6l4.2-1.6c2.6-1,4.6-3,5.6-5.6l1.6-4.3l1.6,4.3
		c1,2.6,3,4.6,5.6,5.6l4.2,1.6l-4.2,1.6c-2.6,1-4.6,3-5.6,5.6L202.7,140.4z M199.7,129c1.2,0.8,2.2,1.8,3,3c0.8-1.2,1.8-2.2,3-3
		c-1.2-0.8-2.2-1.9-3-3C201.9,127.1,200.9,128.1,199.7,129z"/>
	<path class="st0" d="M224.4,142l-1.6-4.2c-1.3-3.3-3.9-6-7.2-7.2l-4.2-1.6l4.2-1.6c3.3-1.3,6-3.9,7.2-7.2l1.6-4.3l1.6,4.3
		c1.2,3.3,3.9,6,7.2,7.2l4.2,1.6l-4.2,1.6c-3.3,1.3-6,3.9-7.2,7.2L224.4,142z M219.9,129c1.8,1.2,3.3,2.7,4.5,4.5
		c1.2-1.8,2.7-3.3,4.5-4.5c-1.8-1.2-3.3-2.7-4.5-4.5C223.2,126.3,221.7,127.8,219.9,129z"/>
	<path class="st0" d="M68.1,140.4l-1.6-4.3c-1-2.6-3-4.6-5.6-5.6l-4.2-1.6l4.2-1.6c2.6-1,4.6-3,5.6-5.6l1.6-4.3l1.6,4.3
		c1,2.6,3,4.6,5.6,5.6l4.2,1.6l-4.2,1.6c-2.6,1-4.6,3-5.6,5.6L68.1,140.4z M65.1,129c1.2,0.8,2.2,1.8,3,3c0.8-1.2,1.8-2.2,3-3
		c-1.2-0.8-2.2-1.9-3-3C67.3,127.1,66.2,128.1,65.1,129z"/>
	<path class="st0" d="M48,142l-1.6-4.3c-1.2-3.3-3.9-6-7.2-7.2l-4.2-1.6l4.2-1.6c3.3-1.3,6-3.9,7.2-7.2l1.6-4.3l1.6,4.3
		c1.3,3.3,3.9,6,7.2,7.2L61,129l-4.2,1.6c-3.3,1.3-6,3.9-7.2,7.2L48,142z M43.5,129c1.8,1.2,3.3,2.7,4.5,4.5
		c1.2-1.8,2.7-3.3,4.5-4.5c-1.8-1.2-3.3-2.7-4.5-4.5C46.8,126.3,45.3,127.8,43.5,129z"/>
	<path class="st1" d="M135.4-3.1"/>
	<path class="st1" d="M135.4-60.3"/>
	<path class="st1" d="M135.4,319.5"/>
	<path class="st1" d="M135.4,262.3"/>
</g>
<path class="st2" d="M123.7,159.8c-1.8,0-3.4-0.4-4.9-1.3c-3.4-2-5.3-6-5.3-11.2v-35.8c0-5.3,1.9-9.3,5.3-11.2c3.4-2,7.8-1.6,12.4,1
	l31,17.9c4.6,2.6,7.1,6.3,7.1,10.2c0,4-2.5,7.6-7.1,10.2l-31,17.9C128.6,159,126,159.8,123.7,159.8z M123.7,102.9
	c-1.1,0-2,0.2-2.9,0.7c-2.1,1.2-3.3,4-3.3,7.8v35.8c0,3.8,1.2,6.5,3.3,7.8c2.1,1.2,5.1,0.9,8.4-1l31-17.9c3.3-1.9,5.1-4.3,5.1-6.8
	c0-2.5-1.8-4.9-5.1-6.8l-31-17.9C127.2,103.5,125.3,102.9,123.7,102.9z"/>
</svg>
`,
        sun_point: `<svg class="w-full" version="1.1" id="Réteg_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 354.7 336.7" style="enable-background:new 0 0 354.7 336.7;" xml:space="preserve">

<g>
	<path class="st0" d="M295.6,140.2c-3.1,0.8-7.7,1.4-13.5,2.1c-5.6,0.6-12.4,1-20.1,2.1c-7.5,1-15.7,2.9-24,6.6
		c-3.9,1.7-7.5,3.7-11,6c0,0,0,0,0,0.2c0.4,0.6,0.8,1.2,1.2,2.1c3.3-2.1,6.8-4.1,10.6-5.6c7.9-3.3,15.9-5.4,23.4-6.8
		c7.5-1.4,14.1-2.1,19.9-3.1c5.8-0.8,10.4-1.9,13.3-2.9c3.1-1,4.8-1.9,4.8-1.9C300.4,138.7,298.5,139.4,295.6,140.2z"/>
	<path class="st0" d="M216.1,141.2c2.3-3.3,4.8-6.4,7.9-9.3c6-6.2,12.6-11.2,18.8-15.1c6.2-4.1,12.2-7.5,17.2-10.4
		c5-2.9,8.7-5.6,11.2-7.9c2.5-2.1,3.5-3.5,3.7-3.5c0,0-1.2,1.2-3.9,3.1c-2.5,1.9-6.6,4.3-11.6,7c-5,2.7-11.2,5.8-17.6,9.5
		c-6.4,3.9-13.5,8.9-19.7,15.3c-2.9,3.1-5.6,6.4-7.9,9.7c0,0,0.2,0.2,0.4,0.2C214.9,140.4,215.5,140.8,216.1,141.2z"/>
	<path class="st0" d="M122.7,193.8c-1.7,0-3.3,0.2-5,0.2c1.4-1.2,2.9-2.5,4.3-3.7c-0.2-1-0.2-2.3-0.4-3.3c0,0,0,0-0.2,0
		c-6,1.2-11.6,1.9-16.6,1.9c-6.2,0-11.4-0.6-15.7-1.4c-8.5-1.7-13-3.7-13-3.7s4.1,2.3,12.6,4.6c4.1,1,9.5,2.1,15.7,2.5
		c4.1,0.2,8.5,0,13.5-0.6c-1.7,1.4-3.1,2.7-4.8,3.9c-4.6,0-8.9-0.2-13.5-0.6c-8.7-1-17.2-3.1-25.1-6.8l0,0l0,0
		c-4.3-1.4-8.5-3.5-12.6-5.4c-4.3-1.9-8.5-3.9-13-5.2c-4.6-1.4-9.3-2.1-14.1-1.4c-4.8,0.6-8.9,2.9-12.4,6c3.7-2.9,8.1-4.8,12.6-5.2
		s9.1,0.4,13.5,2.1c4.3,1.4,8.5,3.5,12.6,5.6s8.3,4.1,12.8,6l0,0c3.9,2.1,8.3,3.5,12.6,4.8s8.7,2.1,13.3,2.7
		c3.5,0.4,6.8,0.6,10.4,0.6c-2.5,1.9-5,3.7-7.7,5.4c-7.2,4.6-15.3,8.3-23.6,10.1l0,0l0,0c-4.3,1.4-8.9,2.3-13.3,3.3
		c-4.6,1-9.1,2.1-13.3,3.9c-4.3,1.9-8.3,4.8-11.2,8.3c-3.1,3.5-5.2,7.7-6.6,12.2c1.7-4.3,4.1-8.3,7-11.6c3.1-3.3,7-5.8,11.2-7.5
		s8.7-2.7,13.3-3.5c4.6-0.8,9.1-1.7,13.5-2.9c4.3-0.8,8.7-2.1,12.8-3.7c4.1-1.7,8.1-3.9,11.8-6.2c3.7-2.3,7.2-4.8,10.6-7.5
		c3.1,0,6-0.2,9.1-0.2C123.1,195.7,122.9,194.9,122.7,193.8z"/>
	<path class="st0" d="M122.3,172.1c-1.7-0.6-3.5-1.2-5.2-1.9c2.1-0.6,4.1-1.2,6.2-1.9c0.4-1.2,0.8-2.3,1.4-3.3v-0.2
		c-0.4,0.2-1,0.4-1.4,0.4c-6-1-11.4-2.7-15.9-4.6c-5.6-2.3-10.4-5-13.9-7.5c-7.2-4.8-10.6-8.5-10.8-8.3c0,0,3.1,3.7,9.9,9.1
		c3.5,2.7,7.9,5.6,13.7,8.3c3.7,1.7,7.9,3.3,12.6,4.6c-1.9,0.6-3.9,1.2-6,1.9c-4.1-1.7-8.3-3.5-12.2-5.8
		c-7.7-4.1-14.7-9.5-20.7-15.9l0,0l0,0c-3.3-3.1-6.4-6.4-9.7-9.9c-3.1-3.3-6.4-6.8-9.9-9.9c-3.7-2.9-7.9-5.6-12.4-6.6
		c-4.6-1.2-9.5-0.8-13.9,0.6c4.6-1.2,9.3-1.2,13.7,0s8.3,3.9,11.8,7c3.3,3.1,6.4,6.4,9.5,9.9c3.1,3.5,6.2,7,9.5,10.4l0,0
		c2.9,3.3,6.2,6.4,9.7,9.3c3.5,2.9,7.2,5.2,11.2,7.5c3.1,1.7,6.2,3.3,9.3,4.6c-3.1,0.8-6,1.4-9.1,2.1c-8.5,1.4-17.2,1.9-25.7,0.4
		l0,0l0,0c-4.6-0.4-9.1-1.2-13.5-2.1c-4.6-0.8-9.1-1.7-13.9-1.4c-4.8,0-9.3,1.2-13.5,3.3c-4.1,2.1-7.7,5.2-10.8,8.7
		c3.3-3.3,6.8-6.2,11-8.1c4.1-1.9,8.7-2.7,13.3-2.7c4.6,0.2,9.1,0.8,13.5,1.9c4.6,0.8,8.9,1.9,13.7,2.5c4.3,0.8,8.9,1.2,13.3,1.2
		c4.6,0,8.9-0.4,13.3-1.2c4.3-0.6,8.5-1.7,12.6-2.9c2.9,1.2,6,2.3,8.9,3.3C122.1,173.9,122.3,172.9,122.3,172.1z"/>
	<path class="st0" d="M224.8,134.6c-1.4,1.7-3.1,3.3-4.6,5.4c-0.6,1-1.4,2.1-2.1,3.1c0.6,0.6,1.2,1.2,1.9,1.9
		c0.6-1.2,1.2-2.5,2.1-3.5c1.2-2.1,2.7-3.9,3.9-5.6c2.7-3.3,5.2-6,7.5-8.3c4.1-4.3,6.8-6.8,6.8-6.8s-2.7,2.1-7.2,6
		C230.6,128.8,227.7,131.3,224.8,134.6z"/>
	<path class="st0" d="M278.2,96.5c-3.3,3.1-7,5.8-10.8,8.3c-3.7,2.5-7.7,5-11.4,7.9l0,0c-3.7,2.5-7.2,5.4-10.4,8.5
		c-3.1,3.1-6,6.6-8.5,10.4c-2.5,3.5-4.8,7.2-6.8,11c-3.1,1.2-6,2.7-8.9,3.9c0.6,0.8,1.2,1.4,1.9,2.3c1.7-0.8,3.3-1.7,5-2.3
		c-1,1.9-2.1,3.7-2.9,5.6c0.6,1,1,2.1,1.4,3.1c0.2-0.4,0.4-0.6,0.4-1c5-3.5,9.9-6,14.7-8.1c5.6-2.3,10.8-3.7,15.1-4.6
		c8.7-1.7,13.5-1.4,13.5-1.7c0,0-4.8-0.4-13.5,0.6c-4.3,0.6-9.7,1.7-15.5,3.7c-3.7,1.4-8.1,3.3-12.2,5.6c1-1.9,1.9-3.7,2.9-5.4
		c4.1-1.7,8.5-3.3,12.8-4.6c8.3-2.5,17-3.7,25.7-3.3l0,0l0,0c4.6-0.2,9.3,0,13.9,0.2c4.6,0.2,9.3,0.4,14.1,0
		c4.6-0.4,9.3-1.7,13.5-4.1c4.1-2.5,7.2-6.2,9.3-10.4c-2.3,3.9-5.6,7.5-9.7,9.7c-3.9,2.3-8.5,3.1-13.3,3.3c-4.6,0.2-9.3,0-13.9-0.2
		c-4.6-0.4-9.3-0.6-14.1-0.6l0,0c-4.6-0.2-8.9-0.2-13.5,0.4c-4.6,0.4-8.9,1.4-13.3,2.7c-3.3,1-6.6,2.1-9.9,3.3
		c1.7-2.7,3.3-5.4,5-7.9c5-7,11-13.7,18-18.4l0,0l0,0c3.5-2.9,7.2-5.4,11-8.1s7.7-5.4,10.8-8.7c3.3-3.3,5.8-7.5,7.2-12
		c1.4-4.3,1.9-9.1,1.4-13.7c0,4.6-0.6,9.3-2.1,13.5C284,89.5,281.5,93.4,278.2,96.5z"/>
	<path class="st0" d="M60.8,120.7c4.1,1.9,8.1,4.3,11.8,6.8c3.7,2.5,7.7,5.2,11.8,7.5l0,0c3.7,2.5,7.7,4.6,11.8,6.4
		c4.1,1.7,8.5,2.9,12.8,3.9c4.1,1,8.5,1.7,12.8,2.3c2.5,2.5,5.2,5,7.9,7.5c0.4-0.8,1-1.7,1.4-2.5c-1.7-1.4-3.3-2.9-5-4.6
		c2.5,0.2,4.8,0.4,7.2,0.6l-0.6,1.4c1-1.4,2.1-2.7,3.3-4.1h-0.6c-0.6,0-1,0-1.7-0.2c-5.2-3.3-9.5-6.8-13-10.4
		c-4.3-4.3-7.7-8.5-9.9-12.2c-4.8-7.2-6.6-11.8-6.6-11.8s1.4,4.6,5.8,12.2c2.3,3.7,5.2,8.3,9.5,12.8c2.7,3.1,6,6.2,9.7,9.1
		c-2.1-0.2-4.1-0.4-6.2-0.6c-3.1-3.1-6.2-6.4-9.1-9.9c-5.4-6.8-9.9-14.3-13-22.6l0,0l0,0c-1.9-4.1-3.5-8.5-5.2-12.8
		c-1.7-4.3-3.3-8.7-5.4-12.8c-2.3-4.1-5.2-8.1-8.9-10.8c-3.7-2.9-8.5-4.3-13-4.8c4.6,0.6,9.1,2.3,12.6,5.2s6.2,6.8,8.1,11
		c2.1,4.1,3.5,8.5,5,12.8c1.4,4.3,2.9,8.7,4.8,13c1.4,4.1,3.3,8.3,5.4,12.2c2.1,3.9,4.8,7.7,7.5,11.2c2.1,2.7,4.6,5.4,6.8,7.9
		c-3.1-0.4-6.2-0.8-9.3-1.7c-8.5-1.9-16.8-4.8-24-9.5l0,0c-3.9-2.1-7.9-4.6-11.8-7c-3.9-2.5-7.9-5-12.2-6.6
		c-4.3-1.7-9.1-2.5-13.9-2.1c-4.3,0-8.9,1.7-13,3.5c4.1-1.9,8.7-3.1,13.3-3.1C52.1,117.8,56.7,118.9,60.8,120.7z"/>
	<path class="st0" d="M238,64.4c-1.9,4.1-4.3,8.1-6.8,11.8c-2.5,3.7-5.2,7.5-7.5,11.6l0,0c-2.5,3.7-4.6,7.7-6.4,11.8
		c-1.7,4.1-2.9,8.5-3.9,12.8c-1,4.1-1.7,8.5-2.3,12.8c-2.7,2.7-5.2,5.4-7.7,8.1c0.8,0.4,1.7,0.8,2.5,1.4c1.4-1.7,3.1-3.3,4.6-5.2
		c-0.2,2.5-0.4,4.8-0.6,7.2l-1.4-0.6c1.4,0.8,2.7,1.7,4.1,2.7l0,0c0-0.6,0-1,0-1.7c3.3-5.2,6.8-9.5,10.6-13
		c4.3-4.3,8.5-7.7,12.2-9.9c7.2-4.8,11.8-6.6,11.8-6.6s-4.6,1.4-12.2,5.8c-3.7,2.3-8.3,5.2-13,9.5c-3.1,2.7-6.2,6-9.1,9.9
		c0.2-2.1,0.4-4.1,0.6-6.2c3.1-3.3,6.4-6.2,9.9-9.1c6.8-5.4,14.3-9.9,22.6-13l0,0l0,0c4.1-1.9,8.5-3.5,12.8-5.2
		c4.3-1.7,8.9-3.3,12.8-5.4c4.1-2.3,8.1-5.2,10.8-8.9c2.9-3.7,4.3-8.5,4.8-13c-0.6,4.6-2.3,9.1-5.2,12.6c-2.9,3.5-6.8,6.2-11,8.1
		s-8.5,3.5-12.8,5c-4.3,1.4-8.9,2.9-13,4.8l0,0c-4.3,1.4-8.5,3.3-12.2,5.4c-3.9,2.1-7.7,4.8-11.2,7.5c-2.7,2.1-5.4,4.6-7.9,6.8
		c0.4-3.1,0.8-6.2,1.7-9.3c1.9-8.5,5-16.8,9.5-24l0,0l0,0c2.1-3.9,4.6-7.9,7-11.8c2.5-3.9,5-7.9,6.6-12.2s2.5-9.1,2.1-13.9
		c-0.2-4.8-1.9-9.1-3.9-13.3c1.9,4.1,3.1,8.7,3.1,13.3C240.9,55.7,239.9,60.3,238,64.4z"/>
	<path class="st0" d="M203.3,120.1c-0.8,2.1-1.4,4.3-2.1,6.8c-0.4,1.4-0.6,3.1-0.8,4.8c0.8,0.4,1.7,0.8,2.5,1.2
		c0-1.9,0.2-3.7,0.4-5.4c0.4-2.3,1-4.6,1.7-6.6c1.2-4.1,2.5-7.7,3.7-10.6c2.3-5.8,3.7-8.9,3.5-8.9c0,0-1.7,2.9-4.3,8.3
		C206.4,112.7,204.9,116,203.3,120.1z"/>
	<path class="st0" d="M137.8,55.9c1.7,4.1,2.7,8.7,3.5,13.3c0.8,4.6,1.7,9.1,2.9,13.7l0,0c0.8,4.3,2.1,8.7,3.9,12.8
		c1.7,4.1,3.9,8.1,6.2,11.8s4.8,7.2,7.5,10.6c0,3.7,0.2,7.5,0.4,11.2c0.8-0.2,1.9-0.6,2.7-0.8c-0.2-2.3-0.2-4.6-0.2-6.8
		c1.4,1.9,3.1,3.7,4.6,5.6l-1.9,0.8c1.9-0.4,3.7-0.6,5.6-0.8l-0.4-0.4c-0.4-0.4-0.6-0.8-1-1.2c-1.2-6-1.9-11.6-1.9-16.6
		c0-6.2,0.6-11.4,1.4-15.7c1.9-8.5,3.7-13,3.7-13s-2.3,4.1-4.6,12.6c-1,4.1-2.1,9.5-2.5,15.7c-0.2,4.1,0,8.5,0.6,13.5
		c-1.2-1.7-2.7-3.1-3.9-4.8c0-4.6,0.2-9.1,0.6-13.5c1-8.7,3.1-17.2,6.6-25.1l0,0l0,0c1.4-4.3,3.5-8.5,5.6-12.8
		c1.9-4.3,3.9-8.5,5.2-13c1.2-4.6,2.1-9.3,1.4-13.9c-0.6-4.8-2.9-8.9-6-12.4c2.9,3.7,4.8,8.1,5.2,12.6c0.6,4.6-0.4,9.1-1.9,13.5
		c-1.4,4.3-3.5,8.5-5.6,12.6c-2.1,4.1-4.1,8.3-5.8,12.8l0,0c-2.1,3.9-3.5,8.3-4.8,12.6c-1.2,4.3-2.1,8.7-2.5,13.3
		c-0.4,3.5-0.6,6.8-0.8,10.4c-1.9-2.5-3.7-5-5.4-7.7c-4.6-7.2-8.3-15.3-10.1-23.6l0,0l0,0c-1.4-4.3-2.3-8.9-3.3-13.3
		c-1-4.6-2.1-9.1-3.9-13.3c-1.9-4.3-4.8-8.3-8.3-11.2c-3.5-3.1-7.7-5.2-12.2-6.6c4.3,1.7,8.3,3.9,11.6,7
		C133.7,47.6,136.2,51.6,137.8,55.9z"/>
	<path class="st0" d="M92.9,80.6c3.1,3.3,5.8,7,8.3,10.8s5,7.7,7.9,11.4c2.5,3.7,5.4,7.2,8.5,10.4s6.6,6,10.4,8.5
		c3.5,2.5,7.2,4.8,11,6.8c1.4,3.5,3.1,6.8,4.6,10.4c0.8-0.6,1.4-1.2,2.3-1.9l0,0c-1-2.1-2.1-4.3-2.9-6.4c2.1,1,4.3,2.3,6.4,3.3
		l-2.1,2.1c1.9-1.2,3.7-2.5,5.8-3.3l-0.8-0.4c-0.4-0.2-1-0.4-1.4-0.6c-3.5-5-6-9.9-8.1-14.7c-2.3-5.6-3.7-10.8-4.6-15.1
		c-1.7-8.5-1.4-13.5-1.7-13.5c0,0-0.4,4.8,0.6,13.5c0.6,4.3,1.7,9.5,3.7,15.5c1.4,3.7,3.1,7.9,5.6,12.2c-1.9-1-3.7-1.9-5.4-2.9
		c-1.7-4.1-3.3-8.3-4.6-12.6c-2.5-8.3-3.7-17-3.3-25.7l0,0l0,0c-0.2-4.6,0-9.3,0.2-13.9c0.2-4.6,0.4-9.3-0.2-14.1
		c-0.4-4.6-1.7-9.3-4.1-13.5c-2.5-4.1-6.2-7.2-10.4-9.3c3.9,2.3,7.5,5.6,9.7,9.7c2.3,3.9,3.1,8.7,3.3,13.3s0,9.3-0.2,13.9
		c-0.4,4.6-0.6,9.3-0.6,14.1l0,0c-0.2,4.6-0.2,8.9,0.4,13.5s1.4,8.9,2.7,13c1,3.3,2.1,6.6,3.3,9.9c-2.7-1.7-5.4-3.1-7.9-5
		c-7-5-13.5-10.8-18.4-18l0,0l0,0c-2.9-3.5-5.4-7.2-8.1-11s-5.4-7.7-8.7-10.8c-3.3-3.3-7.5-5.8-12-7.2c-4.3-1.4-9.1-1.9-13.7-1.4
		c4.6,0,9.3,0.6,13.5,2.1C85.9,74.6,89.8,77.2,92.9,80.6z"/>
	<path class="st0" d="M188.8,50.1c0,4.6-0.8,9.1-1.7,13.5c-1,4.6-2.1,9.1-2.5,13.7V77c-0.8,4.3-1.2,8.9-1.2,13.3
		c0,4.6,0.4,8.9,1.2,13.3c0.8,4.3,1.7,8.5,2.9,12.6c-1.4,3.3-2.7,6.8-3.9,10.1c0.4,0,0.6,0,1,0.2c0.6,0,1.2,0.2,1.7,0.4
		c0.8-2.1,1.4-4.3,2.3-6.4c0.6,2.3,1.4,4.6,2.3,6.8h-2.1c1.9,0.4,3.7,1,5.4,1.7l-0.2-0.6c-0.2-0.4-0.4-1-0.4-1.4
		c1-6,2.7-11.4,4.6-16.2c2.3-5.6,5-10.4,7.5-13.9c4.8-7.2,8.5-10.6,8.3-10.8c0,0-3.7,3.1-8.9,9.9c-2.7,3.5-5.6,8.1-8.3,13.7
		c-1.7,3.7-3.3,7.9-4.6,12.6c-0.6-2.1-1.2-3.9-1.9-6c1.7-4.1,3.5-8.3,5.8-12.2c4.1-7.7,9.5-14.7,15.7-20.5l0,0l0,0
		c3.1-3.3,6.6-6.4,9.9-9.7c3.5-3.1,6.8-6.4,9.9-9.9c2.9-3.7,5.6-7.9,6.6-12.4c1.2-4.6,0.8-9.5-0.6-13.9c1.2,4.6,1.2,9.3,0,13.7
		c-1.2,4.3-3.9,8.3-7,11.8c-3.1,3.3-6.4,6.6-9.9,9.5c-3.5,3.1-7,6.2-10.4,9.5l0,0c-3.3,2.9-6.4,6.2-9.3,9.7s-5.2,7.2-7.5,11.2
		c-1.7,3.1-3.1,6-4.6,9.3c-0.8-3.1-1.4-6-2.1-9.1c-1.4-8.5-1.9-17.2-0.4-25.7l0,0l0,0c0.4-4.6,1.2-9.1,2.1-13.7
		c0.8-4.6,1.4-9.1,1.4-13.9c-0.2-4.8-1.2-9.3-3.3-13.5s-5.2-7.7-8.7-10.8c3.3,3.3,6.2,6.8,8.1,11C188.1,41,189,45.6,188.8,50.1z"/>
	<path class="st0" d="M114.4,157.4c-8.1-3.1-15.1-7.5-21.3-11.6c-6.2-4.3-11.6-8.5-16.2-11.8s-8.5-6-11.4-7.5
		c-2.9-1.4-4.6-2.1-4.6-2.1s1.7,0.6,4.3,2.3c2.7,1.7,6.4,4.3,10.8,8.1s9.5,8.1,15.7,12.6c6.2,4.6,13.3,9.1,21.7,12.4
		c3.9,1.7,8.1,2.7,12,3.5c0.2-0.8,0.6-1.7,1-2.5C122.3,160.1,118.4,159,114.4,157.4z"/>
	<path class="st0" d="M138.4,143.3c-3.5-2.5-6.8-5.2-10.1-8.3c-6.2-6-11.2-12.6-15.1-18.8c-4.1-6.4-7.5-12.2-10.4-17.2
		c-2.9-5-5.6-8.7-7.9-11.2c-2.1-2.5-3.5-3.5-3.5-3.5s1.2,1.2,3.1,3.9c1.9,2.5,4.3,6.6,7,11.6c2.7,5,5.8,11.2,9.7,17.6
		c3.9,6.6,8.9,13.5,15.3,19.7c3.3,3.1,6.6,5.8,10.1,8.1C137.2,144.5,137.8,143.9,138.4,143.3z"/>
	<path class="st0" d="M116.5,155.5c2.1,0.8,4.3,1.4,6.8,2.1c1.4,0.4,2.7,0.6,4.3,0.8c0.4-0.8,0.8-1.7,1.2-2.3c-1.7,0-3.3-0.2-5-0.4
		c-2.3-0.4-4.6-1-6.6-1.7c-4.1-1.2-7.7-2.5-10.6-3.7c-5.8-2.3-8.9-3.7-8.9-3.5c0,0,2.9,1.7,8.3,4.3
		C109,152.4,112.4,153.9,116.5,155.5z"/>
	<path class="st0" d="M121.7,176.8c-1.2,0.4-2.3,0.8-3.5,1c-2.3,0.6-4.8,0.8-6.8,1.2c-4.3,0.4-8.1,0.6-11,0.6c-6.2,0.2-9.5,0-9.5,0
		s3.3,0.4,9.3,0.8c3.1,0.2,6.8,0.4,11.2,0.2c2.3,0,4.6-0.2,7-0.6c1-0.2,2.1-0.4,3.1-0.6C121.5,178.7,121.5,177.9,121.7,176.8z"/>
	<path class="st0" d="M121.3,182.2c-3.7,0.6-7.5,1-11.2,1c-8.7,0.2-16.8-1-24.2-2.7c-7.2-1.7-13.9-3.5-19.5-4.8
		c-5.6-1.4-10.1-2.3-13.5-2.5c-3.1-0.2-5,0-5,0s1.9,0,5,0.4c3.1,0.4,7.7,1.7,13,3.3c5.4,1.7,12,3.9,19.3,5.8
		c7.5,1.9,15.7,3.3,24.6,3.1c3.9,0,7.7-0.4,11.2-1C121.3,184.1,121.3,183.3,121.3,182.2z"/>
	<path class="st0" d="M222.9,229.4c3.3,2.7,6,5.2,8.3,7.5c4.3,4.1,6.8,6.8,6.8,6.8s-2.1-2.7-6-7.2c-2.1-2.3-4.6-5-7.9-8.1
		c-1.7-1.4-3.3-3.1-5.4-4.6c-0.6-0.4-1.2-0.8-1.9-1.2c-0.6,0.6-1.2,1-1.9,1.7c0.8,0.4,1.7,0.8,2.5,1.4
		C219.4,226.7,221.3,228.2,222.9,229.4z"/>
	<path class="st0" d="M215.3,286c0.8,5.8,1.9,10.4,2.9,13.3c1,3.1,1.9,4.8,1.9,4.8s-0.6-1.7-1.4-4.8s-1.4-7.7-2.1-13.5
		c-0.6-5.6-1-12.4-2.1-20.1c-1-7.5-2.9-15.7-6.6-24c-1.4-3.5-3.3-6.8-5.4-9.9c-0.8,0.4-1.7,0.6-2.5,0.8c2.1,3.1,3.9,6.6,5.4,10.1
		c3.3,7.9,5.4,15.9,6.8,23.4C213.6,273.5,214.4,280.2,215.3,286z"/>
	<path class="st0" d="M238,208.5c4.1,1.2,7.7,2.5,10.6,3.7c5.8,2.3,8.9,3.7,8.9,3.7s-2.9-1.7-8.3-4.3c-2.7-1.4-6.2-2.9-10.4-4.6
		c-2.1-0.8-4.3-1.4-6.8-2.1c-1-0.2-1.9-0.4-2.9-0.6c-0.4,0.8-0.6,1.4-1,2.3c1.2,0,2.3,0.2,3.3,0.4C233.7,207.3,236,207.9,238,208.5z
		"/>
	<path class="st0" d="M252.5,263.6c2.9,5,5.6,8.7,7.7,11.2c2.1,2.5,3.5,3.5,3.5,3.5s-1.2-1.2-3.1-3.9s-4.3-6.6-7-11.6
		c-2.7-5-5.6-11.2-9.5-17.6c-3.9-6.4-8.9-13.5-15.3-19.7c-2.7-2.7-5.4-5-8.3-6.8c-0.6,0.6-1,1.4-1.7,2.1c2.9,2.1,5.6,4.3,8.3,6.8
		c6.2,6,11,12.6,15.1,18.8C246.1,252.6,249.4,258.6,252.5,263.6z"/>
	<path class="st0" d="M201,243.1c2.1,3.7,3.5,7.2,4.8,9.9c2.5,5.6,3.7,8.9,3.7,8.9s-0.8-3.3-2.9-8.9c-1-2.9-2.3-6.4-4.1-10.4
		c-0.8-2.1-1.9-4.1-3.3-6.2c-0.6-1-1.2-1.9-2.1-2.9c-0.8,0.2-1.7,0.4-2.5,0.8c1,1,1.9,2.1,2.7,3.3
		C198.7,239.4,199.9,241.2,201,243.1z"/>
	<path class="st0" d="M178.8,237.3c0.6,3.7,1,7.5,1,11.4c0.2,8.5-1,16.8-2.7,24.2c-1.7,7.5-3.5,13.9-4.8,19.5
		c-1.4,5.6-2.3,10.1-2.5,13.5c-0.2,3.3,0,5,0,5s0-1.9,0.6-5c0.4-3.1,1.7-7.7,3.3-13s3.9-12,5.8-19.3c1.9-7.5,3.3-15.7,3.1-24.6
		c0-3.9-0.4-7.7-1-11.2C180.5,237.5,179.7,237.5,178.8,237.3z"/>
	<path class="st0" d="M230,162.8c0.2,0.4,0.2,1,0.4,1.4c1-1,2.3-2.1,3.5-2.9c1.9-1.2,3.9-2.5,5.8-3.5c3.7-2.1,7.2-3.5,9.9-4.8
		c5.6-2.5,8.9-3.7,8.9-3.7s-3.3,0.8-9.1,2.7c-2.9,1-6.4,2.3-10.4,4.1c-1.9,0.8-4.1,2.1-6.2,3.3c-1,0.6-2.1,1.4-3.1,2.1
		C229.8,161.9,229.8,162.4,230,162.8z"/>
	<path class="st0" d="M176.5,240.4c-0.2-1-0.4-2.3-0.6-3.3c-0.6,0-1.2,0-1.7,0c-0.2,0-0.6,0-0.8,0c0.4,1.2,0.8,2.5,1,3.7
		c0.4,2.3,0.8,4.6,1,6.6c0.4,4.3,0.6,8.1,0.6,11.2c0,6,0,9.5,0,9.5s0.4-3.3,0.8-9.3c0.2-3.1,0.4-6.8,0.2-11.2
		C177.2,245.2,177,242.9,176.5,240.4z"/>
	<path class="st0" d="M262.3,281.8c-3.1-3.3-5.8-7-8.3-10.8c-2.5-3.7-5-7.7-7.9-11.4l0,0c-2.5-3.7-5.2-7.2-8.5-10.4
		c-3.1-3.1-6.6-6-10.4-8.5c-3.5-2.5-7.2-4.8-11-6.8c-1-2.7-2.3-5.4-3.5-7.9c-0.8,0.6-1.4,1-2.3,1.7c0.6,1.4,1.2,2.7,1.9,4.1
		c-1.7-0.8-3.1-1.7-4.8-2.5c-1,0.6-2.1,1-3.1,1.7c3.5,5,6,9.9,7.9,14.5c2.5,5.6,3.7,10.8,4.6,15.1c1.7,8.5,1.7,13.5,1.7,13.5
		s0.4-4.8-0.6-13.5c-0.6-4.3-1.7-9.7-3.7-15.5c-1.4-3.9-3.3-8.1-5.6-12.2c1.9,1,3.7,1.9,5.6,2.9c1.7,4.1,3.3,8.5,4.6,12.8
		c2.5,8.3,3.7,17,3.3,25.7l0,0l0,0c0.2,4.6,0,9.3-0.2,13.9c-0.2,4.6-0.4,9.3,0.2,14.1c0.4,4.8,1.7,9.3,4.1,13.5s6.2,7.2,10.4,9.3
		c-3.9-2.3-7.5-5.6-9.7-9.7c-2.3-3.9-3.1-8.7-3.3-13.3s0-9.3,0.2-13.9c0.4-4.6,0.6-9.3,0.6-14.1l0,0c0.2-4.6,0.2-8.9-0.4-13.5
		c-0.4-4.6-1.4-8.9-2.7-13.3c-1-3.3-2.1-6.6-3.3-9.9c2.7,1.7,5.4,3.1,7.9,5c7,5,13.5,11,18.4,18l0,0l0,0c2.9,3.5,5.4,7.2,8.1,11
		s5.4,7.7,8.9,10.8c3.3,3.3,7.5,5.8,11.8,7.2c4.6,1.4,9.1,1.7,13.7,1.4c-4.6,0-9.1-0.6-13.5-2.1
		C269.3,287.6,265.4,285.1,262.3,281.8z"/>
	<path class="st0" d="M321.9,189.9c-4.1,1.9-8.7,2.7-13.3,2.7s-9.1-0.8-13.5-1.7c-4.6-1-9.1-2.1-13.7-2.5l0,0
		c-4.3-1-8.9-1.2-13.3-1.2s-8.9,0.4-13.3,1.2c-4.3,0.8-8.5,1.7-12.6,2.9c-3.1-1.2-6.2-2.5-9.3-3.5c0,1-0.2,1.9-0.2,2.9
		c1.9,0.6,3.5,1.2,5.4,1.9c-2.1,0.6-3.9,1.2-6,1.9c-0.2,1-0.4,2.1-0.6,3.1c0.2,0,0.2,0,0.4-0.2c6,1,11.4,2.7,16.2,4.6
		c5.6,2.3,10.4,5,13.9,7.5c7.2,4.8,10.6,8.5,10.8,8.5c0,0-3.1-3.7-9.9-8.9c-3.5-2.7-8.1-5.6-13.7-8.3c-3.7-1.7-7.9-3.3-12.6-4.6
		c2.1-0.6,3.9-1.2,6-1.9c4.1,1.7,8.3,3.5,12.2,5.8c7.7,4.1,14.7,9.5,20.7,15.7l0,0l0,0c3.3,3.1,6.6,6.6,9.7,9.9
		c3.1,3.5,6.4,6.8,9.9,9.9c3.7,2.9,7.9,5.6,12.4,6.6c4.6,1.2,9.5,0.8,13.9-0.6c-4.6,1.2-9.3,1.2-13.7,0c-4.3-1.2-8.3-3.9-11.8-7
		c-3.3-3.1-6.4-6.4-9.5-9.9c-3.1-3.5-6.2-7-9.5-10.4l0,0c-2.9-3.3-6.2-6.4-9.7-9.3c-3.5-2.7-7.2-5.2-11.2-7.5
		c-3.1-1.7-6.2-3.1-9.3-4.6c3.1-0.8,6-1.4,9.1-2.1c8.5-1.4,17.2-1.9,25.9-0.4l0,0l0,0c4.6,0.4,9.1,1.2,13.7,2.1
		c4.6,0.8,9.1,1.4,13.9,1.4c4.6,0,9.3-1.2,13.5-3.3c4.1-2.1,7.7-5.2,10.8-8.7C329.8,184.9,326,187.8,321.9,189.9z"/>
	<path class="st0" d="M278.2,228.6c4.6,3.5,8.5,5.8,11.4,7.5c2.9,1.4,4.6,2.1,4.6,2.1s-1.7-0.6-4.3-2.3c-2.7-1.7-6.4-4.3-11-8.1
		c-4.3-3.5-9.5-8.1-15.7-12.6s-13.5-9.1-21.7-12.4c-3.7-1.4-7.5-2.5-11-3.3c-0.2,0.8-0.4,1.7-0.8,2.5c3.5,0.8,7.2,1.9,10.8,3.3
		c8.1,3.1,15.1,7.5,21.3,11.8C268.3,221,273.7,225.1,278.2,228.6z"/>
	<path class="st0" d="M294.4,241.7c-4.1-1.9-7.9-4.3-11.8-6.8c-3.7-2.5-7.5-5.2-11.6-7.5l0,0c-3.7-2.5-7.7-4.8-11.8-6.4
		c-4.1-1.7-8.3-2.9-12.8-3.9c-4.1-1-8.5-1.7-12.8-2.3c-2.1-2.1-4.3-4.1-6.6-6.2c-0.4,0.8-0.8,1.7-1.4,2.5c1.2,1,2.5,2.3,3.5,3.3
		c-1.9-0.2-3.5-0.4-5.4-0.4c-0.6,1-1.2,1.9-2.1,2.7c5,3.3,9.3,6.8,12.8,10.4c4.3,4.3,7.7,8.5,9.9,12.2c4.8,7.2,6.6,12,6.6,11.8
		c0,0-1.4-4.6-5.8-12.2c-2.1-3.7-5.2-8.3-9.3-13c-2.7-3.1-6-6.2-9.7-9.1c2.1,0.2,4.1,0.4,6.2,0.6c3.3,3.1,6.2,6.4,9.1,9.9
		c5.4,6.8,9.9,14.3,13,22.6l0,0l0,0c1.9,4.1,3.5,8.5,5.2,12.8c1.7,4.3,3.3,8.9,5.4,12.8c2.3,4.1,5.2,8.1,8.9,10.8
		c3.7,2.9,8.5,4.3,13,4.8c-4.6-0.6-9.1-2.3-12.6-5.2c-3.5-2.9-6.2-6.8-8.1-11c-2.1-4.1-3.5-8.5-5-12.8c-1.4-4.3-2.9-8.9-4.8-13.3
		l0,0c-1.4-4.1-3.3-8.3-5.4-12.2c-2.3-3.9-4.8-7.7-7.5-11.2c-2.1-2.7-4.6-5.4-6.8-7.9c3.1,0.4,6.2,0.8,9.3,1.7
		c8.5,1.9,16.8,4.8,24,9.5l0,0c3.9,2.1,7.9,4.6,11.8,7c3.9,2.5,7.9,5,12.2,6.6c4.3,1.7,9.1,2.5,13.7,2.1c4.8-0.4,9.1-1.9,13.3-3.9
		c-4.3,1.9-8.7,3.1-13.3,3.1C303.1,244.6,298.5,243.5,294.4,241.7z"/>
	<path class="st0" d="M130.2,281.8c-3.3,4.6-6,8.5-7.5,11.4c-1.4,2.9-1.9,4.6-2.1,4.6c0,0,0.6-1.7,2.3-4.3c1.7-2.7,4.3-6.4,8.1-10.8
		s8.1-9.5,12.6-15.7c4.6-6.2,9.1-13.3,12.4-21.7c1.4-3.5,2.5-7.2,3.3-10.8c-0.8-0.2-1.7-0.6-2.5-0.8c-0.8,3.5-1.9,7-3.3,10.8
		c-3.1,7.9-7.5,15.1-11.8,21.3C137.8,271.9,133.5,277.3,130.2,281.8z"/>
	<path class="st0" d="M116.3,206.2c2.1-0.8,4.1-2.1,6.2-3.3c0.8-0.4,1.7-1,2.3-1.7c-0.2-0.8-0.6-1.7-0.8-2.5
		c-0.8,0.8-1.7,1.4-2.7,2.1c-1.9,1.4-3.9,2.5-5.8,3.5c-3.7,2.1-7.2,3.5-9.9,4.8c-5.6,2.5-8.9,3.7-8.9,3.7s3.3-0.8,8.9-2.9
		C108.6,209.4,112.2,207.9,116.3,206.2z"/>
	<path class="st0" d="M129.3,226.5c-2.7,3.3-5.2,6-7.5,8.3c-4.3,4.3-6.8,6.8-6.8,6.8s2.7-2.1,7.2-6c2.3-2.1,5-4.6,8.1-7.7
		c1.4-1.7,3.1-3.3,4.6-5.4c0.6-0.8,1-1.4,1.4-2.3c-0.6-0.6-1-1.2-1.7-1.9c-0.4,1-1,1.9-1.7,2.9C132,223,130.6,224.9,129.3,226.5z"/>
	<path class="st0" d="M95.2,256.2c-5,2.9-8.7,5.6-11.2,7.7c-2.5,2.1-3.5,3.5-3.5,3.5s1.2-1.2,3.9-3.1c2.7-1.9,6.6-4.3,11.6-6.8
		c5-2.7,11.2-5.6,17.8-9.5c6.6-3.9,13.5-8.9,19.7-15.3c2.7-2.7,5-5.6,7-8.5c-0.6-0.6-1.2-1.2-1.9-1.9c-2.1,2.9-4.6,5.8-7,8.5
		c-6,6.2-12.6,11.2-19,15.1C106.1,249.7,100.1,253,95.2,256.2z"/>
	<path class="st0" d="M143,260.9c0,0,1.7-2.9,4.3-8.3c1.4-2.7,2.9-6.2,4.6-10.1c0.8-2.1,1.4-4.3,2.1-6.8c0.2-0.8,0.4-1.9,0.6-2.7
		c-0.8-0.4-1.4-0.6-2.3-1c0,1-0.2,2.3-0.4,3.3c-0.4,2.3-1,4.6-1.7,6.6c-1.2,4.1-2.5,7.7-3.7,10.6C144.5,257.8,143,260.9,143,260.9z"
		/>
	<path class="st0" d="M116.9,298c1.9-4.1,4.3-8.1,6.8-11.8c2.5-3.7,5.4-7.7,7.5-11.8l0,0c2.5-3.7,4.6-7.7,6.4-11.8
		c1.7-4.1,2.9-8.5,3.9-12.8c1-4.1,1.7-8.5,2.3-12.8c2.1-2.1,4.1-4.3,6.2-6.6c-0.8-0.4-1.7-1-2.5-1.4c-1,1.2-2.3,2.5-3.3,3.7
		c0.2-1.9,0.4-3.7,0.4-5.6c-0.8-0.6-1.9-1.2-2.7-2.1c-3.3,5-6.8,9.3-10.4,12.8c-4.3,4.3-8.5,7.7-12.2,9.9
		c-7.2,4.8-11.8,6.6-11.8,6.6s4.6-1.4,12.2-5.8c3.7-2.1,8.3-5.2,12.8-9.3c3.1-2.7,6.2-6,9.1-9.7c-0.2,2.1-0.4,4.1-0.6,6.2
		c-3.1,3.1-6.4,6.2-9.9,9.1c-6.8,5.4-14.3,9.9-22.6,13l0,0l0,0c-4.1,1.9-8.5,3.5-12.8,5.2c-4.3,1.7-8.9,3.3-13,5.4
		c-4.1,2.3-8.1,5-10.8,8.9c-2.9,3.7-4.3,8.5-4.8,13c0.6-4.6,2.3-9.1,5.2-12.6s6.8-6.2,11-8.1c4.1-2.1,8.5-3.5,12.8-5
		c4.3-1.4,8.9-2.9,13.3-4.8l0,0c4.1-1.4,8.3-3.3,12.2-5.4c3.9-2.3,7.7-4.8,11.2-7.5c2.7-2.1,5.4-4.6,7.9-6.8
		c-0.4,3.1-0.8,6.2-1.7,9.3c-1.9,8.5-4.8,16.8-9.5,24l0,0l0,0c-2.1,4.1-4.6,7.9-7,11.8c-2.5,3.9-5,7.9-6.6,12.2
		c-1.7,4.3-2.5,9.1-2.1,13.7c0.4,4.8,1.9,9.1,3.9,13.3c-1.9-4.3-3.1-8.7-3.1-13.3C114.2,306.7,115.3,302.1,116.9,298z"/>
	<path class="st0" d="M73,220.1c5.6-0.6,12.4-1,20.1-2.1c7.5-1,15.9-2.9,24-6.6c3.5-1.4,6.8-3.3,9.9-5.2c-0.4-0.8-0.8-1.7-1-2.3
		c-3.1,1.9-6.4,3.7-9.9,5.2c-7.9,3.5-15.9,5.4-23.4,6.8c-7.5,1.2-14.1,2.1-19.9,3.1c-5.6,0.8-10.4,1.9-13.3,2.9
		c-3.1,1-4.6,1.9-4.6,1.9s1.7-0.6,4.8-1.4C62.7,221.4,67.4,220.7,73,220.1z"/>
	<path class="st0" d="M166.4,312.3c0.2-4.6,0.8-9.1,1.9-13.5c0.8-4.6,1.9-9.1,2.5-13.7l0,0c1-4.3,1.4-8.9,1.4-13.3
		c0-4.6-0.4-8.9-1.2-13.3c-0.6-4.3-1.7-8.5-2.9-12.6c1.2-2.9,2.3-5.8,3.3-8.9c-1,0-1.9-0.2-2.9-0.2c-0.6,1.7-1.2,3.3-1.9,5
		c-0.6-1.9-1.2-3.7-1.7-5.6c-1-0.2-2.1-0.4-3.1-0.8l0,0c-1,6-2.7,11.4-4.6,16.2c-2.3,5.6-5,10.4-7.5,13.9c-5,7.2-8.5,10.6-8.5,10.6
		s3.7-3.1,8.9-9.9c2.7-3.5,5.6-8.1,8.3-13.7c1.7-3.7,3.3-7.9,4.6-12.6c0.6,2.1,1.2,3.9,1.9,6c-1.7,4.1-3.5,8.3-5.8,12.2
		c-4.1,7.7-9.5,14.7-15.9,20.7l0,0l0,0c-3.1,3.3-6.4,6.4-9.9,9.7c-3.5,3.1-6.8,6.4-9.9,9.9c-2.9,3.7-5.6,7.9-6.6,12.4
		c-1.2,4.6-0.8,9.5,0.6,13.9c-1.2-4.6-1.2-9.3,0-13.7c1.2-4.6,3.9-8.3,7-11.8c3.1-3.3,6.4-6.4,9.9-9.5c3.5-3.1,7-6,10.4-9.5
		c3.3-2.9,6.4-6.2,9.3-9.7c2.7-3.5,5.2-7.2,7.5-11.2c1.7-3.1,3.1-6.2,4.6-9.3c0.8,3.1,1.4,6,2.1,9.1c1.4,8.5,1.9,17.2,0.4,25.9l0,0
		l0,0c-0.4,4.6-1.2,9.1-2.1,13.7c-0.8,4.6-1.7,9.1-1.4,13.9c0,4.6,1,9.3,3.3,13.5c2.1,4.1,5.2,7.7,8.7,10.8c-3.3-3.1-6.2-6.8-8.1-11
		C167,321.4,166.2,316.8,166.4,312.3z"/>
	<path class="st0" d="M76.9,265.9c3.3-3.1,7-5.8,10.8-8.3c3.7-2.5,7.7-5,11.4-7.9c3.7-2.5,7.2-5.2,10.4-8.5c3.1-3.1,6-6.6,8.5-10.4
		c2.5-3.5,4.8-7.2,7-11c2.9-1.2,5.6-2.5,8.3-3.7c-0.6-0.8-1-1.4-1.7-2.3c-1.4,0.6-3.1,1.4-4.6,2.1c0.8-1.7,1.7-3.3,2.7-5
		c-0.6-1-1.2-1.9-1.7-2.9l0,0c-5,3.5-9.9,6-14.5,7.9c-5.6,2.5-10.8,3.7-15.1,4.6C90,222.2,85,222,85,222.2c0,0,4.8,0.4,13.5-0.6
		c4.3-0.6,9.5-1.7,15.5-3.7c3.9-1.4,7.9-3.3,12.2-5.6c-1,1.9-1.9,3.7-2.9,5.6c-4.1,1.7-8.5,3.3-12.6,4.6c-8.3,2.5-17,3.7-25.9,3.3
		l0,0l0,0c-4.6,0.2-9.3,0-13.9-0.2c-4.8-0.2-9.3-0.4-14.1,0.2c-4.8,0.4-9.3,1.7-13.5,4.1c-4.1,2.5-7.2,6.2-9.3,10.4
		c2.3-3.9,5.6-7.5,9.7-9.7c3.9-2.3,8.7-3.1,13.3-3.3s9.1,0,13.9,0.2c4.6,0.4,9.3,0.6,14.1,0.6h-0.2c4.6,0.2,8.9,0.2,13.5-0.4
		c4.6-0.4,8.9-1.4,13.3-2.7c3.3-1,6.6-2.1,9.9-3.3c-1.7,2.7-3.3,5.4-5,7.9c-5,7-10.8,13.5-18,18.4l0,0l0,0c-3.5,2.9-7.2,5.4-11,8.1
		s-7.7,5.4-10.8,8.9c-3.3,3.3-5.8,7.5-7.2,11.8c-1.4,4.6-1.7,9.1-1.4,13.7c0-4.6,0.6-9.1,2.1-13.5C71.2,272.9,73.6,269,76.9,265.9z"
		/>
	<path class="st0" d="M217.3,306.5c-1.7-4.1-2.7-8.7-3.5-13.3s-1.7-9.1-2.9-13.5l0,0c-0.8-4.3-2.1-8.7-3.9-12.8
		c-1.7-4.1-3.9-8.1-6.2-11.8c-2.3-3.7-4.8-7.2-7.5-10.6c0-3.3-0.2-6.6-0.2-9.9c-0.8,0.2-1.9,0.6-2.7,0.8c0,1.9,0.2,3.7,0.2,5.6
		c-1.2-1.7-2.5-3.1-3.9-4.6c-1,0.2-2.1,0.4-3.1,0.6l0,0c1.2,6,1.9,11.6,1.9,16.6c0,6.2-0.6,11.4-1.4,15.7c-1.7,8.5-3.7,13-3.7,13
		s2.3-4.1,4.6-12.6c1-4.1,2.1-9.5,2.5-15.7c0.2-4.1,0-8.5-0.6-13.5c1.4,1.4,2.7,3.1,3.9,4.8c0,4.6-0.2,9.1-0.6,13.5
		c-1,8.7-3.1,17.2-6.8,25.1l0,0l0,0c-1.4,4.3-3.5,8.5-5.4,12.8c-1.9,4.1-3.9,8.5-5.2,13c-1.4,4.6-2.1,9.3-1.4,14.1
		c0.6,4.8,2.9,9.1,6,12.6c-2.9-3.7-4.8-8.1-5.2-12.6c-0.4-4.6,0.4-9.1,2.1-13.5c1.4-4.3,3.5-8.5,5.6-12.6s4.1-8.3,5.8-12.8v0.2
		c2.1-4.1,3.5-8.3,4.8-12.6c1.2-4.3,2.1-8.7,2.7-13.3c0.4-3.5,0.6-6.8,0.6-10.4c1.9,2.5,3.7,5,5.4,7.7c4.6,7.2,8.3,15.3,10.1,23.6
		l0,0l0,0c1.4,4.3,2.3,8.9,3.3,13.3c1,4.6,2.1,9.1,3.9,13.5c1.9,4.3,4.6,8.3,8.3,11.2c3.5,3.1,7.7,5.2,12.2,6.6
		c-4.3-1.7-8.3-3.9-11.6-7C221.5,314.8,219,310.8,217.3,306.5z"/>
	<path class="st0" d="M237,184.3c2.3-0.4,4.6-0.8,6.8-1c4.1-0.4,8.1-0.6,11.2-0.6c6-0.2,9.5,0,9.5,0s-3.3-0.4-9.3-0.8
		c-3.1-0.2-6.8-0.4-11.2-0.2c-2.1,0-4.6,0.2-7,0.6c-1.2,0.2-2.5,0.4-3.9,0.8c0,0.8,0,1.7,0,2.7C234.3,185.1,235.8,184.7,237,184.3z"
		/>
	<path class="st0" d="M288.8,186.4c5.6,1.4,10.1,2.3,13.5,2.5c3.3,0.2,5,0,5,0s-1.9,0-5-0.6c-3.1-0.4-7.7-1.7-13-3.3
		c-5.4-1.7-11.8-3.9-19.3-5.8c-7.5-1.9-15.7-3.3-24.6-3.1c-4.3,0-8.5,0.6-12.4,1.2c0,0.8,0.2,1.7,0.2,2.5c3.9-0.6,8.1-1.2,12.2-1.2
		c8.5-0.2,16.8,1,24.2,2.7C276.6,183.3,283.2,185.1,288.8,186.4z"/>
	<path class="st0" d="M306.8,185.1c-4.3-1.4-8.5-3.5-12.6-5.6s-8.3-4.1-12.8-5.8h0.2c-4.1-1.9-8.3-3.5-12.6-4.8s-8.7-2.1-13.3-2.5
		c-3.5-0.4-6.8-0.6-10.4-0.8c2.5-1.9,5-3.7,7.7-5.4c7.2-4.6,15.3-8.3,23.6-10.1l0,0l0,0c4.3-1.4,8.7-2.3,13.3-3.3
		c4.6-1,9.1-2.1,13.5-4.1c4.3-1.9,8.3-4.6,11.2-8.3c3.1-3.5,5.2-7.7,6.6-12.2c-1.7,4.3-3.9,8.3-7,11.6c-3.1,3.3-7,5.8-11.2,7.5
		s-8.7,2.7-13.3,3.5s-9.1,1.7-13.7,2.9l0,0c-4.3,0.8-8.7,2.1-12.8,3.9c-4.1,1.7-8.1,3.9-11.8,6.2c-3.7,2.3-7.2,4.8-10.6,7.5
		c-3.5,0-6.8,0.2-10.4,0.2c0.2,0.8,0.4,1.9,0.6,2.7c2.1-0.2,4.1-0.2,6.2-0.2c-1.9,1.4-3.7,3.1-5.6,4.6c0.2,1,0.4,2.3,0.4,3.3
		c0.4-0.4,0.8-0.6,1.2-1c6-1.2,11.6-1.9,16.6-1.9c6.2,0,11.4,0.6,15.7,1.4c8.5,1.9,13,3.7,13,3.7s-4.1-2.3-12.6-4.6
		c-4.1-1-9.5-2.1-15.7-2.5c-4.1-0.2-8.5,0-13.3,0.6c1.4-1.2,3.1-2.7,4.8-3.9c4.6,0,9.1,0.2,13.5,0.6c8.7,1,17.2,3.1,25.1,6.8l0,0
		l0,0c4.3,1.4,8.5,3.5,12.8,5.6c4.1,1.9,8.5,3.9,13,5.2c4.6,1.2,9.3,2.1,14.1,1.4c4.8-0.6,8.9-2.9,12.6-6c-3.7,2.9-8.1,4.8-12.6,5.2
		C315.7,187.4,311.1,186.6,306.8,185.1z"/>
	<path class="st0" d="M175.3,113.7c-0.2-8.7,1-16.8,2.7-24.2c1.7-7.2,3.5-13.9,4.8-19.5c1.4-5.6,2.1-10.1,2.5-13.5c0.2-3.3,0-5,0-5
		s0,1.9-0.6,5c-0.4,3.1-1.7,7.7-3.3,13s-3.9,12-5.8,19.3c-1.9,7.5-3.3,15.7-3.1,24.6c0,4.6,0.6,8.7,1.4,12.8c0.8,0,1.7,0,2.5-0.2
		C175.9,122.4,175.5,118,175.3,113.7z"/>
	<path class="st0" d="M201.4,118c3.1-8.1,7.5-15.1,11.8-21.3c4.3-6.2,8.5-11.6,11.8-16.2c3.3-4.6,5.8-8.5,7.2-11.4
		c1.4-2.9,2.1-4.6,2.1-4.6s-0.6,1.7-2.3,4.3c-1.7,2.7-4.3,6.4-8.1,10.8c-3.5,4.3-8.1,9.5-12.6,15.7c-4.6,6.2-9.1,13.5-12.4,21.7
		c-1.7,4.3-2.9,8.5-3.7,12.6c0.8,0.2,1.7,0.6,2.5,1C198.5,126.3,199.7,122.2,201.4,118z"/>
	<path class="st0" d="M179.9,114.9c-0.4-4.3-0.6-8.1-0.6-11c-0.2-6.2,0-9.5,0-9.5s-0.4,3.3-0.8,9.3c-0.2,3.1-0.2,6.8-0.2,11.2
		c0,2.3,0.2,4.6,0.6,7c0.2,1.4,0.6,2.9,1,4.6c0.8,0,1.9,0,2.7,0.2c-0.6-1.7-1.2-3.3-1.7-5C180.3,119.3,180.1,117,179.9,114.9z"/>
	<path class="st0" d="M152.5,119.9c1,2.1,2.1,4.1,3.3,6.2c0.8,1.2,1.7,2.7,2.7,3.9c0.8-0.4,1.7-0.6,2.7-0.8
		c-1.2-1.2-2.5-2.7-3.5-4.1c-1.4-2.1-2.5-3.9-3.5-5.8c-2.1-3.7-3.5-7.2-4.8-9.9c-2.5-5.6-3.7-8.9-3.7-8.9s0.8,3.3,2.7,9.1
		C149.4,112.2,150.9,115.8,152.5,119.9z"/>
	<path class="st0" d="M131,134c1.7,1.4,3.5,3.1,5.4,4.6c1.2,0.8,2.7,1.9,4.1,2.5c0.6-0.6,1.4-1.2,2.1-1.9c-1.7-0.6-3.3-1.7-5-2.5
		c-2.1-1.2-3.9-2.7-5.6-3.9c-3.3-2.7-6-5.2-8.3-7.5c-4.3-4.3-6.8-6.8-6.8-6.6c0,0,2.1,2.7,6,7.2C125.2,128.2,127.7,131.1,131,134z"
		/>
	<path class="st0" d="M149.6,119.7c-3.5-7.9-5.4-15.9-6.8-23.4c-1.4-7.5-2.1-14.1-3.1-19.9c-0.8-5.6-1.9-10.4-2.9-13.3
		c-1-3.1-1.9-4.6-1.9-4.6s0.6,1.7,1.4,4.8c0.8,3.1,1.4,7.7,2.1,13.3s1,12.4,2.1,20.1c1,7.5,2.9,15.9,6.6,24
		c1.9,4.1,3.9,8.1,6.4,11.6c0.8-0.4,1.4-0.8,2.3-1C153.6,127.6,151.5,123.8,149.6,119.7z"/>
	<g>
		<g>
			<path class="st0" d="M197,173.3c0.8,0,1.9-0.4,2.3-1c0.2-0.2,0.2-0.2,0.2-0.4c-1.2,0-2.7,0-4.1,0c-0.2,0-0.4,0-0.6,0
				C195.6,172.5,196.2,173.1,197,173.3z"/>
		</g>
		<g>
			<path class="st0" d="M207.2,172.5C207.4,172.5,207.4,172.5,207.2,172.5h0.4C207.6,172.5,207.4,172.5,207.2,172.5z"/>
		</g>
		<g>
			<path class="st0" d="M205.1,170.6c-0.6-0.8-1.4-1.7-2.5-2.5c-1-0.6-2.1-1.2-3.3-1.4c-1.2-0.2-2.5-0.2-3.5,0
				c-1.2,0.2-2.3,0.6-3.5,1c-1,0.6-2.1,1.2-2.9,2.1c-1.9,1.4-3.3,3.3-4.3,5.2c1.7-1.7,3.3-3.1,5.2-4.3c0.8-0.6,1.9-1.2,2.9-1.7
				c0.4-0.2,0.6-0.2,1-0.4c0.2,0,0.2,0,0.4-0.2c0.4-0.2,1-0.2,1.7-0.4c1.2-0.2,2.3-0.2,3.3,0h0.2c0.8,0.2,1.7,0.4,2.3,0.8
				c0.8,0.6,1.7,1.2,2.7,2.1c0.2,0.2,0.4,0.4,0.6,0.4c-0.4-0.2-0.6-0.2-1-0.4c-0.6-0.2-1.7-0.4-2.5-0.6c-0.2,0-0.4,0-0.6,0
				c-1.7-0.2-3.5-0.4-5.4-0.2c-0.8,0-1.7,0.2-2.5,0.4c-0.2,0-0.4,0-0.6,0.2c-0.6,0.2-1,0.2-1.7,0.4c-1.4,0.4-2.5,1.2-3.5,1.9
				c-0.6,0.6-1.2,1.2-1.7,1.7c-0.6,0.6-0.6,1-0.6,1.2c0.2,0,0.6-0.4,1.2-1c1.7,0.8,3.3,1.4,5,1.9c1.9,0.4,3.7,0.8,5.8,0.8
				c1.9,0,3.9-0.2,5.6-1.2c0.8-0.4,1.7-1,2.3-1.7c0.6-0.6,1-1.4,1.4-2.1c0.4,0,0.8,0,0.8,0s0,0,0-0.2l0,0
				C207.4,172.5,205.7,171.5,205.1,170.6z M194.4,171.9C194.4,171.9,194.4,172.1,194.4,171.9c0,0.6,0.2,1,0.6,1.7l0.2,0.2
				c0.4,0.4,1,0.8,1.7,0.8c0.8,0,1.7-0.2,2.1-0.6c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.6,0.4-0.8s0-0.4,0-0.6c0.2,0,0.6,0,0.8,0
				c0,0.4-0.2,1-0.4,1.4s-0.4,0.8-0.8,1c-0.6,0.6-1.7,1-2.5,1c-1,0-1.9-0.2-2.7-0.8c-0.2-0.2-0.4-0.4-0.4-0.4
				c-0.4-0.4-0.6-1.2-0.8-1.9C193.5,172.1,193.9,171.9,194.4,171.9z M202.4,176c-1.7,0.6-3.5,0.8-5.4,0.8c-1.9,0-3.7-0.2-5.6-0.6
				c-0.8-0.2-1.9-0.4-2.7-0.6c-0.8-0.2-1.7-0.4-2.3-0.6c0.2,0,0.2-0.2,0.4-0.2c0.2-0.2,0.6-0.4,1-0.6c1-0.6,2.7-1.2,4.3-1.9
				c0,0.6,0.2,1.2,0.4,1.9c0.2,0.4,0.6,0.8,0.8,1.2c0.4,0.4,1,0.8,1.7,1c0.6,0.2,1.2,0.4,1.9,0.4c1.4,0,2.7-0.6,3.5-1.7
				c0.4-0.6,0.8-1.2,1-2.1c0-0.4,0.2-0.8,0-1c0.8,0,1.4,0.2,2.1,0.2c1,0.2,1.9,0.2,2.5,0.4h0.2C205.5,174.2,204.1,175.4,202.4,176z"
				/>
		</g>
		<g>
			<path class="st0" d="M207.8,161.7c-1.2-0.4-3.3-0.6-5.6-1c-2.5-0.2-5.4-0.4-8.7,0c-3.3,0.4-6.8,1.7-9.7,4.3
				c-1.4,1.2-2.5,2.9-3.1,4.6c-0.8,1.7-1,3.3-1,5c0,3.3,0.4,6.2,0.8,8.5c0.4,2.3,0.6,4.3,0.4,5.6c0,1.2-0.4,2.1-0.2,2.1
				c0,0,0.6-0.6,0.8-2.1c0.2-0.8,0.2-1.9,0.2-2.9l0.2,0.2c0.4,0.6,1.2,1.4,1.9,2.5c0.2,0.4,0.4,1,0.4,1c0,0.2-1.4,1-1.9,1.2
				c0-0.2,0-0.4,0-0.4s-0.2,0.6-1,0.8c-0.6,0.4-1.7,0.6-2.9,0.6c-1.2,0-2.3-0.2-3.1-0.6c-0.8-0.4-1.2-0.8-1.2-0.6c0,0,0,0,0,0.2l0,0
				l0,0c0,0.2,0.2,0.6,0.6,1c0.6,0.6,1.9,1.4,3.3,1.4c1.4,0,2.9-0.6,3.5-1.4c0.2-0.4,0.4-0.6,0.4-1c0,0.2,0.6,0.4,1.4,0.2
				c0.2,0,0.4,0,0.8-0.2c0.2-0.2,0.6-0.4,0.8-0.8c0.2-0.4,0.2-0.8,0-1.2c0-0.4-0.2-0.6-0.4-1c-0.6-1.2-1.7-1.9-2.3-2.5
				c-0.4-0.2-0.6-0.4-0.8-0.4c0-0.6,0-1.2-0.2-1.9c-0.2-2.5-0.6-5.2-0.4-8.3c0-1.4,0.2-3.1,0.8-4.8c0.6-1.4,1.7-2.9,2.9-4.1
				c2.7-2.3,6-3.5,8.9-3.9c3.1-0.4,6-0.4,8.3-0.2s4.3,0.4,5.6,0.6c1.2,0.2,2.1,0.2,2.1,0C209.9,162.1,209.1,161.9,207.8,161.7z"/>
		</g>
		<g>
			<path class="st0" d="M189.8,198.8c0.2-0.4,0.4-0.6,0.2-0.6c-0.2-0.2-0.8,0.6-1.9,0.4c-1-0.2-2.5-1.2-4.1-2.1
				c-0.8-0.4-1.9-0.8-2.7-0.8c-0.8,0-1.9,0.2-2.3,0.6c-0.6,0.4-0.8,1-1,1.2c-0.2,0.4-0.2,0.6-0.2,0.6c0.2,0.2,0.6-0.6,1.7-1
				c0.4-0.2,1-0.4,1.9-0.2c0.6,0.2,1.4,0.4,2.3,0.8c1.4,0.8,2.7,1.7,4.1,1.9c-1,0.4-2.5,0.6-4.1,0.6c-1.7,0-3.3,0.2-4.6,0.4
				c-0.6,0.2-1,0.2-1.2,0.4c-0.2-0.2-0.6-0.4-1.2-0.4c-1.2-0.4-2.9-0.4-4.6-0.4c-1.7,0-3.1-0.2-4.1-0.6c1.2-0.2,2.7-1,4.1-1.9
				c0.8-0.4,1.7-0.8,2.3-0.8c0.6-0.2,1.2,0,1.9,0.2c1,0.6,1.4,1.2,1.7,1c0,0,0-0.2-0.2-0.6s-0.4-0.8-1-1.2c-0.6-0.4-1.4-0.8-2.5-0.6
				c-0.8,0-1.9,0.4-2.7,0.8c-1.7,1-3.1,2.1-4.1,2.1c-1,0.2-1.7-0.6-1.9-0.4c0,0,0,0.2,0.2,0.6c0.2,0.2,0.2,0.2,0.4,0.4
				c0,0,0.2,0.2,0.2,0.4c0.2,0.4,0.4,1.2,0.8,2.1c0.8,1.4,2.3,3.5,4.3,4.8c0.4,0.2,1,0.6,1.4,0.8c-1.2,0.4-2.5,1.2-3.1,1.9
				c-1.4,1.2-2.1,2.5-1.9,2.7c0.2,0.2,0.8-0.8,2.3-1.9c3.5-2.5,12-2.3,15.1,0c1.4,1,2.3,2.1,2.3,1.9c0.2,0-0.4-1.2-1.9-2.7
				c-0.8-0.6-1.9-1.4-3.1-1.9c0.4-0.2,1-0.4,1.4-0.8c2.1-1.2,3.5-3.3,4.3-4.8c0.4-0.8,0.6-1.7,0.8-2.1c0-0.2,0.2-0.2,0.2-0.4
				C189.6,199,189.8,199,189.8,198.8z M187.7,201.1c-0.8,1.2-2.3,2.9-4.1,4.1c-1.2,0.6-2.3,1-3.5,1.4c-0.4,0-4.3,1.2-8.1-1.4
				c-1.9-1.2-3.3-2.9-4.1-4.1c-0.2-0.2-0.2-0.4-0.4-0.6c1,0.6,2.7,1,4.6,1c1.9,0,3.3,0,4.6,0c0.4,0,0.6,0,1,0c0,0,0.2,0,0.4,0
				s0.2,0,0.4,0s0.6,0,1,0c1,0,2.7,0,4.6,0c1.9,0,3.5-0.4,4.6-1C187.9,200.7,187.7,200.9,187.7,201.1z"/>
		</g>
		<g>
			<path class="st0" d="M184.6,171.5c0.4-1,1-1.9,1.9-2.9c0.6-1,1.4-1.9,2.7-2.5c2.1-1.2,4.6-1.9,6.6-2.1s4.1-0.2,5.8,0
				c3.3,0.4,5.4,1.2,5.4,1.2s-1.9-1-5.2-1.4c-1.7-0.2-3.7-0.4-6-0.2s-4.8,0.6-7,2.1c-1,0.6-2.1,1.7-2.7,2.7c-0.6,1-1.2,2.1-1.9,3.1
				c-1,2.1-1.4,3.9-1.7,5.6c-0.2,1.7-0.2,3.1,0,3.9c0.2,0.8,0.6,1.4,0.6,1.4s-0.2-0.4-0.4-1.4c-0.2-0.8-0.2-2.3,0.2-3.9
				C183,175.2,183.6,173.3,184.6,171.5z"/>
		</g>
		<g>
			<path class="st0" d="M190.2,196.9c-1-0.6-1.9-1-2.3-1.9c-1-1.2-1-2.5-1-2.5s0,0.2,0,0.8c0,0.4,0.2,1.2,0.6,2.1
				c0.4,0.8,1.2,1.7,2.3,2.3c0.2,0.2,0.6,0.4,0.8,0.4c-0.4,0.4-0.6,0.8-0.8,1.4c-0.2,0.8-0.4,1.7-0.4,2.1c0,1.2,0.2,1.9,0.2,1.9
				s-0.2-0.6,0.2-1.7c0.2-0.6,0.4-1.2,0.6-1.9c0.4-0.6,3.1-2.9,3.1-2.9C193.1,197.3,191.7,197.6,190.2,196.9z"/>
		</g>
		<g>
			<path class="st0" d="M157.1,172.3c0.4,0.6,1.4,1,2.3,1s1.7-0.6,2.1-1.4c-0.2,0-0.6,0-0.8,0c-1.2,0-2.5,0-3.7,0
				C156.9,171.9,156.9,172.1,157.1,172.3z"/>
		</g>
		<g>
			<path class="st0" d="M168.9,173.3c-0.8-0.6-2.1-1.4-3.5-1.9c-0.6-0.2-1-0.4-1.7-0.4c-0.2,0-0.4-0.2-0.6-0.2
				c-0.8-0.2-1.7-0.2-2.5-0.4c-1.9-0.2-3.7,0-5.4,0.2c-0.2,0-0.4,0-0.6,0c-1,0.2-1.9,0.4-2.5,0.6c-0.4,0-0.8,0.2-1.2,0.4
				c0.2-0.2,0.4-0.4,0.6-0.4c0.8-0.8,1.7-1.4,2.7-2.1c0.8-0.4,1.7-0.8,2.3-0.8h0.2c1-0.2,2.3-0.2,3.3,0c0.6,0,1,0.2,1.7,0.4
				c0.2,0,0.2,0,0.4,0.2c0.4,0.2,0.6,0.2,1,0.4c1,0.4,1.9,1,2.9,1.7c1.9,1.2,3.5,2.7,5.2,4.3c-1-2.1-2.7-3.7-4.3-5.2
				c-0.8-0.8-1.9-1.4-2.9-2.1c-1-0.4-2.3-0.8-3.5-1c-1.2-0.2-2.3-0.2-3.5,0s-2.3,0.8-3.3,1.4c-1,0.6-1.7,1.7-2.5,2.5
				c-0.8,0.8-2.3,1.9-2.1,1.9l0,0c0,0-0.2,0,0,0.2c0,0,0.4,0,0.8,0c0.4,0.8,0.8,1.4,1.4,2.1c0.6,0.6,1.4,1.2,2.3,1.7
				c1.7,0.8,3.7,1.2,5.6,1.2c1.9,0,3.9-0.4,5.6-0.8c1.7-0.4,3.5-1,5-1.9c0.8,0.6,1.2,1,1.4,1c0,0-0.2-0.6-0.6-1.2
				C170.1,174.4,169.5,173.7,168.9,173.3z M156.5,171.9c0.2,0.8,1,2.1,1,2.1c0.4,0.4,1.2,0.8,2.1,0.6c0.6,0,1.2-0.4,1.7-0.8l0.2-0.2
				c0.4-0.6,0.6-1,0.6-1.4l0,0c0.2,0,0.6,0,0.8,0.2c-0.2,0.6-0.4,1.2-0.8,1.9c-0.2,0.2-0.4,0.4-0.6,0.4c-0.8,0.6-1.7,1-2.7,0.8
				c-0.8,0-1.9-0.4-2.5-1c-0.2-0.2-0.6-0.6-0.8-1s-0.4-0.8-0.4-1.4C155.6,171.9,156,171.7,156.5,171.9z M167.4,175.6
				c-0.8,0.2-1.9,0.4-2.7,0.6c-1.9,0.4-3.7,0.6-5.6,0.6c-1.9,0-3.7-0.2-5.4-0.8c-1.7-0.6-3.1-1.9-4.1-3.3h0.2c0.6,0,1.4-0.2,2.5-0.4
				c0.6,0,1.2-0.2,2.1-0.2c0,0.4,0,0.8,0,1c0.2,0.8,0.4,1.4,1,2.1c0.8,1,2.1,1.7,3.5,1.7c0.6,0,1.2-0.2,1.9-0.4
				c0.6-0.2,1.2-0.6,1.7-1c0.4-0.4,0.6-0.8,0.8-1.2c0.2-0.6,0.4-1.2,0.4-1.9c1.9,0.4,3.3,1,4.3,1.9c0.4,0.2,0.6,0.4,1,0.6
				c0.2,0,0.2,0.2,0.4,0.2C168.9,175.2,168.1,175.6,167.4,175.6z"/>
		</g>
		<g>
			<path class="st0" d="M172.2,189.7c0-0.2,0-0.6,0.4-1c0.6-1,1.2-1.9,1.9-2.5l0.2-0.2c0,1.2,0,2.1,0.2,2.9c0.2,1.4,0.6,2.1,0.8,2.1
				s-0.2-0.8-0.2-2.1c0-1.2,0-3.3,0.4-5.6c0.4-2.5,0.8-5.2,0.8-8.5c0-1.7-0.2-3.3-1-5.2c-0.6-1.7-1.7-3.3-3.1-4.6
				c-2.9-2.7-6.4-3.9-9.7-4.3c-3.3-0.4-6.2-0.2-8.7,0c-2.5,0.2-4.3,0.6-5.6,1s-2.1,0.6-2.1,0.6s0.8,0,2.1,0c1.2-0.2,3.3-0.4,5.6-0.6
				c2.5-0.2,5.2-0.2,8.3,0.2c3.1,0.4,6.4,1.7,8.9,3.9c1.2,1.2,2.3,2.7,2.9,4.1c0.6,1.4,0.8,3.1,0.8,4.8c0.2,3.1-0.2,6-0.4,8.3
				c0,0.6-0.2,1.2-0.2,1.9c-0.2,0-0.6,0.2-0.8,0.4c-0.6,0.4-1.7,1.2-2.3,2.5c-0.2,0.4-0.4,0.6-0.4,1c0,0.4-0.2,0.8,0,1.2
				s0.4,0.6,0.8,0.8c0.2,0.2,0.6,0.2,0.8,0.2c1,0,1.4-0.2,1.4-0.4C173.9,190.7,172.2,189.9,172.2,189.7z"/>
		</g>
		<g>
			<path class="st0" d="M173.2,182.2c0,0,0.2-0.4,0.6-1.4c0.2-0.8,0.4-2.3,0-3.9c-0.2-1.7-0.8-3.7-1.9-5.6c-0.4-1-1-2.1-1.9-3.1
				c-0.6-1-1.7-2.1-2.7-2.7c-2.3-1.4-4.8-1.9-7-2.1s-4.3,0-6,0.2c-3.3,0.6-5.2,1.4-5.2,1.4s2.1-0.8,5.4-1.2c1.7-0.2,3.7-0.2,5.8,0
				c2.3,0.2,4.6,0.8,6.6,2.1c1,0.6,1.9,1.4,2.5,2.5c0.6,1,1.2,1.9,1.9,2.9c1,1.9,1.7,3.9,1.9,5.6c0.4,1.7,0.4,2.9,0.2,3.9
				C173.6,181.8,173.2,182.2,173.2,182.2z"/>
		</g>
		<g>
			<path class="st0" d="M165.4,199.6c-0.2-0.4-0.4-1-0.8-1.4c0.4-0.2,0.6-0.2,0.8-0.4c1-0.6,1.9-1.4,2.3-2.3
				c0.4-0.8,0.6-1.4,0.6-2.1c0-0.4,0-0.8,0-0.8s0,1.2-1,2.5c-0.6,0.6-1.2,1.4-2.3,1.9c-0.4,0.2-0.8,0.4-1.4,0.4c0,0-1.4,0-2.1-0.2
				l1.4,1.2c0,0,1.2,1,1.7,1.7c0.4,0.6,0.6,1.2,0.8,1.9c0.2,1,0.2,1.7,0.2,1.7s0.4-0.6,0.2-1.9C165.8,201.1,165.8,200.5,165.4,199.6
				z"/>
		</g>
		<g>
			<path class="st0" d="M187.3,200.7c0,0-0.6,1-2.3,2.5c-0.6,0.8-1.7,1.7-2.9,2.3c-1.2,0.6-2.9,1-4.6,1c-3.3,0-6-1.4-7.7-2.9
				c-1.4-1.4-1.9-2.7-1.9-2.7s0.6,1,2.3,2.3c1.7,1.2,4.1,2.5,7.2,2.3c1.4,0,2.9-0.2,4.1-0.6c1.2-0.4,2.3-1.2,3.1-1.9
				C186.5,201.5,187.3,200.7,187.3,200.7z"/>
		</g>
		<g>
			<path class="st0" d="M204.1,169.4c0,0-0.6-0.2-2.3-0.2c-1.7,0-3.9,0-6.4,0.4c-2.5,0.4-4.6,1-6,1.7c-1.4,0.6-2.1,1.2-2.1,1
				c0,0,0.6-0.6,1.9-1.2c1.2-0.8,3.3-1.4,6-1.9c2.5-0.4,5-0.2,6.6,0C203.5,169.2,204.1,169.4,204.1,169.4z"/>
		</g>
		<g>
			<path class="st0" d="M152.5,169.4c0,0,1-0.2,2.7-0.4c1.7-0.2,3.9-0.2,6.6,0c0.6,0.2,1.2,0.2,1.9,0.4c0.4,0.2,1,0.4,1.4,0.6
				c1,0.4,1.9,0.8,2.3,1c1.2,0.8,1.7,1.2,1.7,1.2s-0.8-0.4-2.1-1c-0.6-0.4-1.4-0.6-2.5-1l-1.4-0.4c-0.4-0.2-1-0.2-1.7-0.4
				c-2.5-0.4-4.8-0.4-6.4-0.4C153.6,169.2,152.5,169.4,152.5,169.4z"/>
		</g>
		<g>
			<path class="st0" d="M188.6,202.3c0,0-0.8,1.2-2.5,2.9c-0.8,0.8-1.9,1.7-3.3,2.3c-1.4,0.6-3.1,1-5,1c-3.7,0-6.6-1.7-8.5-3.3
				c-1.7-1.7-2.3-3.1-2.3-3.1s0.6,1.2,2.5,2.7s4.8,2.7,8.1,2.7c1.7,0,3.3-0.4,4.6-0.8c1.4-0.6,2.5-1.2,3.3-2.1
				C187.5,203.4,188.6,202.3,188.6,202.3z"/>
		</g>
	</g>
	<g>
		<path class="st0" d="M234.7,184.1v-4.3l0,0v-0.2v-0.4l0,0l-0.4-4.3l0,0v-0.2v-0.2l0,0l-0.8-4.3l0,0v-0.2v-0.2l0,0l-1-4.3l0,0V165
			v-0.2l0,0l-1.4-4.1l0,0l-0.2-0.2v-0.2l0,0l-1.9-3.9l0,0v-0.2l-0.2-0.2l0,0l-2.3-3.7l0,0l-0.2-0.2l-0.2-0.2l0,0l-2.9-4.3l0,0v-0.2
			l-0.2-0.2l0,0l-2.9-3.3l0,0l-0.2-0.2l-0.2-0.2l0,0l-3.1-3.1l0,0l-0.2-0.2l0-0.2l0,0l-3.5-2.9l0,0l-0.2-0.2l-0.2-0.2l0,0l-3.7-2.5
			l0,0l-0.2-0.2l-0.2-0.2l0,0l-3.7-2.1l0,0l-0.2-0.2l-0.2-0.2l0,0l-4.1-1.7l0,0h-0.2l-0.2-0.2l0,0l-4.1-1.4l0,0h-0.2h-0.4l0,0
			l-4.1-1l0,0h-0.2h-0.2l0,0l-4.3-0.8l0,0h-0.2h-0.2l0,0l-4.3-0.4l0,0h-0.2h-0.2l0,0h-4.3l0,0h-0.2h-0.2l0,0l-5.2-0.2l0,0h-0.2h-0.2
			l0,0l-4.3,0.8l0,0h-0.2h-0.2l0,0l-4.6,1.4l0,0h-0.2h-0.2l0,0l-4.1,1.4l0,0l-0.2,0.2H156l0,0l-3.9,1.9l0,0l-0.2,0.2l-0.6,0.2l0,0
			l-3.7,2.3l0,0l-0.2,0.2l-0.2,0.2l0,0l-3.5,2.5l0,0l-0.2,0.2l-0.2,0.2l0,0l-3.5,2.7l0,0l-0.2,0.2l-0.2,0.2l0,0l-3.1,3.1l0,0
			l-0.2,0.2l-0.2,0.2l0,0l-2.9,3.3l0,0l-0.2,0.2l-0.2,0.2l0,0l-2.5,3.7l0,0l-0.2,0.2l-0.2,0.2l0,0l-2.3,3.7l0,0l-0.2,0.2l-0.2,0.2
			l0,0l-1.9,3.9l0,0v0.2l-0.2,0.2l0,0l-1.4,4.1l0,0v0.2v0.2l0,0l-1.2,4.3l0,0v0.2v0.2l0,0l-0.8,4.3l0,0v0.2v0.2l0,0l-0.2,5l0,0v0.2
			v0.2l0,0v4.3l0,0v0.2v0.2l0,0l0.4,4.3l0,0v0.2v0.2l0,0l0.8,4.3l0,0v0.2v0.2l0,0l1.2,4.3l0,0v0.2v0.2l0,0l1.4,4.1l0,0l0.2,0.2v0.2
			l0,0l1.9,3.9l0,0l0.2,0.2l0.2,0.2l0,0l2.3,3.9l0,0l0.2,0.2l0.2,0.2l0,0l2.5,3.5l0,0l0.2,0.2l0.2,0.2l0,0l2.9,3.3l0,0l0.2,0.2
			l0.2,0.2l0,0l3.1,3.1l0,0l0.2,0.2l0.2,0.2l0,0l3.3,2.9l0,0l0.2,0.2l0.2,0.2l0,0l3.5,2.5l0,0l0.2,0.2l0.2,0.2l0,0l3.9,2.3l0,0
			l0.2,0.2l0.2,0.2l0,0l3.9,1.9l0,0l0.2,0.2l0.2,0.2l0,0l4.1,1.4l0,0h0.2l0.2,0.2l0,0l4.3,1l0,0h0.2h0.2l0,0l4.3,0.8l0,0h0.2h0.2
			l0,0l4.3,0.4l0,0h0.2h0.2l0,0h4.3l0,0h0.2h0.2l0,0l4.3-0.4l0,0h0.2h0.2l0,0l4.3-0.8l0,0h0.2h0.2l0,0l4.1-1.2l0,0l0.2-0.2h0.2l0,0
			l4.1-1.4l0,0l0.2-0.2h0.2l0,0l3.9-1.9l0,0l0.2-0.2l0.2-0.2l0,0l3.9-2.3l0,0l0.2-0.2l0.2-0.2l0,0l3.5-2.5l0,0l0.2-0.2l0.2-0.2l0,0
			l3.3-2.9l0,0l0.2-0.2l0.2-0.2l0,0l3.1-3.1l0,0l0.2-0.2l0.2-0.2l0,0l2.9-3.3l0,0l0.2-0.2l0.2-0.2l0,0l2.5-3.5l0,0l0.2-0.2l0.2-0.2
			l0,0l2.3-3.9l0,0l0.2-0.2v-0.2l0,0l1.9-3.9l0,0v-0.2l0.2-0.2l0,0l1.4-4.1l0,0v-0.2v-0.2l0,0l1-4.1l0,0v-0.2v-0.2l0,0l0.8-4.3l0,0
			v-0.2v-0.2l0,0l0.4-4.3l0,0v-0.2v-0.2H234.7z M233.3,184.3l-11.4,1.4l10.8,3.3l-11.2,0.4l10.6,4.3l-11.4-0.6l10.1,5.2l-11.2-1.4
			l9.5,6l-11.2-2.5l9.1,6.8l-11-3.3l8.5,7.7l-10.6-4.3l7.7,8.3l-10.1-5.4l7,9.1l-9.5-6.2l6.2,9.5l-9.1-7l5.4,10.1l-8.3-7.7l4.3,10.6
			l-7.7-8.5l3.5,10.8l-6.8-9.1l2.5,11.2l-6-9.5l1.4,11.2l-5.2-9.9l0.6,11.4l-4.3-10.6l-0.4,11.4l-3.5-11l-1.4,11.4l-2.5-11.2
			l-2.5,11.2l-1.4-11.4l-3.3,11l-0.4-11.4l-4.3,10.6l0.6-11.4l-5.2,10.1l1.4-11.2l-6,9.5l2.5-11.2l-6.8,9.1l3.5-10.8l-7.7,8.5
			l4.3-10.6l-8.3,7.7l5.4-10.1l-8.9,7l6.2-9.5l-9.5,6.2l7-9.1l-10.1,5.4l7.7-8.3l-10.6,4.3l8.5-7.7l-10.8,3.5l9.1-6.8l-11.2,2.5
			l9.5-6l-11.2,1.4l10.1-5.2l-11.8,0.2l10.6-4.3l-11.4-0.4l11-3.3l-11.2-1.4l11.2-2.5l-11.2-2.5l11.2-1.4l-11-3.3l11.4-0.4
			l-10.6-4.1l11.4,0.6l-10.1-5.2l11.2,1.4l-9.5-6l11.2,2.5l-9.1-7l10.8,3.5l-8.3-7.7l10.6,4.3l-7.7-8.3l10.1,5.4l-7-8.9l9.5,6.2
			l-6.2-9.5l8.9,7l-5.4-10.1l8.3,7.7l-4.3-10.6l7.7,8.5l-3.5-10.8l6.8,9.1l-2.5-11.2l6,9.5l-1.4-11.2l5.2,10.1l-0.6-11.4l4.3,10.6
			l0.4-11.4l3.3,11l1.4-11.2l2.5,11.2l2.5-11.2l1.4,11.2l3.3-11l0.4,11.4l4.3-10.6l-0.6,11.4l5.2-10.1l-1.4,11.2l6-9.5l-2.1,10.8
			l6.8-9.1l-3.5,10.8l7.7-8.5l-4.3,10.6l8.3-7.7l-5.2,10.1l9.1-7l-6.2,9.5l9.5-6.2l-7,8.9l10.1-5.4l-7.7,8.3l10.6-4.3l-8.5,7.7
			l10.8-3.5l-9.1,6.8l11.2-2.5l-9.7,6.4l11.2-1.4l-10.1,5.2l11.4-0.6l-10.6,4.3l11.4,0.4l-10.8,3.3l11.4,1.4l-11.2,2.5L233.3,184.3z
			"/>
	</g>
</g>
<circle class="st0" cx="350.7" cy="182.6" r="4"/>
<circle class="st0" cx="5.7" cy="183.3" r="4.4"/>
<circle class="st0" cx="176.8" cy="5.4" r="4.4"/>
</svg>
`,
      },
    };
  },
  methods: {},
};
</script>
