<template>
  <nav
    class="bg-secondary py-3 lg:flex sticky top-0 left-0 hidden"
    style="z-index: 10"
  >
    <router-link to="/">
      <div class="text-primary custom-font leading-none font-bold">
        <div>Antal</div>
        <div>Judit</div>
      </div>
    </router-link>
    <div class="flex justify-center items-center flex-grow pr-10 text-sm">
      <router-link :to="item.route" v-for="(item, i) in items" :key="i"
        ><button
          :class="[
            $route.name === item.componentName
              ? 'bg-primary text-secondary'
              : '',
          ]"
          class="hover:bg-primary px-4 py-2 rounded-full uppercase font-bold mr-4 text-primary hover:text-secondary border-2 border-primary"
        >
          {{ item.title }}
        </button></router-link
      >
    </div>
    <div v-if="$root.isAdmin">
      <svg
        @click="logOut()"
        xmlns:svg="http://www.w3.org/2000/svg"
        id="svg18832"
        viewBox="0 0 212.6 212.6"
        version="1.0"
        class="w-7 top-1 relative cursor-pointer fill-current text-primary transform rotate-180"
      >
        <g id="layer1" transform="translate(-241.43 -409.02)">
          <path
            id="path15809"
            d="m295.02 409.02l-0.44 35.44h124.01v141.73h-124.01v35.43h159.45v-212.6h-159.01z"
            fill-rule="evenodd"
          />
          <path
            id="path15815"
            d="m400.88 533.04h-88.59l35.44 35.43h-53.15l-53.15-53.15 53.15-53.15h53.15l-35.44 35.44h88.59v35.43z"
          />
        </g>
      </svg>
    </div>
  </nav>
  <nav
    class="bg-secondary py-3 lg:hidden sticky top-0 left-0 flex justify-between px-5"
    style="z-index: 10"
  >
    <router-link to="/">
      <div class="text-primary custom-font leading-none font-bold">
        <div>Antal</div>
        <div>Judit</div>
      </div>
    </router-link>
    <svg
      @click="sidebarShow = true"
      height="32px"
      id="Layer_1"
      style="enable-background: new 0 0 32 32"
      version="1.1"
      viewBox="0 0 32 32"
      width="32px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
      />
    </svg>
    <div
      id="side-menu"
      :class="[sidebarShow ? 'left-0' : '-left-56']"
      class="fixed top-0 w-56 h-screen z-50 bg-primary py-5 flex flex-col text-white duration-300 uppercase font-bold gap-2"
    >
      <a
        href="javascript:void(0)"
        class="text-right text-4xl text-white pr-5"
        @click="sidebarShow = false"
        >&times;</a
      >
      <router-link
        :to="item.route"
        v-for="(item, i) in items"
        :key="'navxs' + i"
      >
        <div
          @click="sidebarShow = false"
          class="hover:text-amber-500 text-white py-1.5 mt-5"
          :class="[
            $route.name === item.componentName
              ? 'bg-secondary text-primary'
              : '',
          ]"
          to="/"
        >
          {{ item.title }}
        </div>
      </router-link>
    </div>
  </nav>
</template>
<script>
export default {
  name: "NavComponent",
  components: {},
  data() {
    return {
      sidebarShow: false,
      items: [
        { title: "Home", route: "/", componentName: "home" },
        {
          title: "Szolgáltatások",
          route: "/services",
          componentName: "Services",
        },
        { title: "Bejegyzések", route: "/blogs", componentName: "BlogsView" },
        { title: "Információk", route: "/gyik", componentName: "GyikView" },
        { title: "Feltételek", route: "/terms", componentName: "TermsView" },
        { title: "Kapcsolat", route: "/contact", componentName: "ContactView" },
      ],
    };
  },
  methods: {
    logOut() {
      this.$root.setCookie("jwt", null, 365);

      location.reload();
    },
  },
  mounted() {},
};
</script>
<style></style>
