<template>
  <div class="bg-primary-darkest overflow-hidden relative text-secondary">
    <div
      :style="{
        backgroundImage: `url(${require(`@/assets/triange_.svg`)})`,
      }"
      style="
        background-size: 100%;
        opacity: 0.05;
        width: calc(100% - 20px);
        left: 10px;
        height: calc(100% - 20px);
        top: 10px;
      "
      class="absolute"
    ></div>
    <card-corner borderColor="secondary" :showUp="false" />
    <div class="p-2 relative h-full flex flex-col">
      <div class="relative" style="padding-top: 60%">
        <img
          class="w-full h-full absolute object-cover top-0 left-0"
          :src="
            item.FileName
              ? `${$root.siteRoot}/backend/api/images/${item.FileName}`
              : require('@/assets/zodiac3.jpg')
          "
          style="padding: 3px"
        />
      </div>
      <div class="flex flex-col justify-between flex-grow px-4">
        <div>
          <div
            @click="$router.push('/blog/' + item.Id)"
            class="cursor-pointer uppercase font-bold custom-font text-xl mb-2 mt-8 text-secondary overflow-hidden overflow-ellipsis"
          >
            <span class="whitespace-nowrap">{{ item.Title }}</span>
          </div>
          <div
            class="gap-2 mb-6 opacity-90 text-center w-full flex justify-center"
          >
            <svg
              class="fill-current text-secondary w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"
              />
            </svg>
            <div class="text-sm text-center">
              {{ item.DateNum.split(" ")[0] }}
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-between flex-grow">
          <div
            class="text-md text-white text-opacity-80 mt-2 mb-4 px-8 pb-5"
            style=""
          >
            <div v-html="cutText()" class="text-white"></div>
          </div>
          <div>
            <button
              @click="$router.push('/blog/' + item.Id)"
              class="uppercase bg-secondary text-primary font-bold rounded-full px-6 py-2 mb-10 cursor-pointer"
            >
              Tovább
            </button>
          </div>
          <div v-if="$root.isAdmin">
            <button
              @click="editBlog()"
              class="uppercase bg-white text-primary font-bold rounded-full px-4 py-1 mb-5 cursor-pointer text-sm"
            >
              Szerkesztés
            </button>
          </div>
          <div v-if="$root.isAdmin">
            <button
              @click="removeBlog()"
              class="uppercase bg-red text-white font-bold rounded-full px-4 py-1 mb-10 cursor-pointer text-xs"
            >
              Törlés
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CardCorner from "../components/CardCorner.vue";
import { axiosInstance } from "@/main";

//import Icons from "../components/Icons.vue";
export default {
  name: "BlogView",
  components: { CardCorner },
  props: {
    item: {
      default: null,
    },
  },
  methods: {
    cutText() {
      let strippedString = this.item.Text.replace(/(<([^>]+)>)/gi, "");
      strippedString = strippedString.slice(0, 150) + "...";
      return strippedString;
    },
    editBlog() {
      this.$emit("editBlog", this.item);
    },
    removeBlog() {
      let o = { jwt: this.$root.getCookie("jwt"), id: this.item.Id };
      let this_ = this;
      let r;
      axiosInstance
        .post(
          `${this.$root.siteRoot}/backend/api/controller/talk/delete.php`,
          o
        )
        .then(function (response) {
          this_.$root.loading = false;
          //console.log('response get msg', response);
          r = response.data ? response.data : {};
          //                        console.log('r status', r)
          if (r.status === "OK") {
            this_.$emit("update", {});
          }
        })
        .catch(function () {
          this_.$root.loading = false;
        });
    },
  },
};
</script>
